import React, { useCallback, useContext } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import ModalContext from "../../../contexts/modal.context";
import SnackbarContext from "../../../contexts/snackbar.context";
import AddUserForm from "./addUser";
import { IInvitation } from "../../users.types";
import { EMAIL_REGEX } from "../../../constants/default.strings.constants";
import { MESSAGE } from "../../../designSystem/snackbar/snackbar.types";
import { ERROR } from "../../../constants/images";
import { rolesFirstUserMapper } from "../../users-list.mappers";
import { IAnyPropertyNameAndAnyValue } from "../../../constants/common.types";
import { useAppSelector } from "../../../redux/hooks";

export default function useAddUser() {
  const { showModal, closeModal, setAsyncLoading } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { userName } = useAppSelector((state: any) => state.session);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email")
      .matches(EMAIL_REGEX, "Invalid email"),
    role: Yup.string().required("Role is required")
  });

  const onSubmit = useCallback(
    (
      { email, role }: any,
      identityOrgId: string,
      reloadUsers: any,
      onSendInvitation: any
    ) => {
      try {
        const invitation: IInvitation = {
          invitee_email: email,
          identity_provider_organization_id: identityOrgId,
          role_id: role,
          inviter_name: userName
        };
        onSendInvitation(invitation, reloadUsers);
      } catch (error: any) {
        console.error(
          `Error sending invitation to ${email}. Status ${error.status}. ${error}`
        );
        setAsyncLoading(false);
        closeModal();
        showSnackbar({
          text: `Error sending invitation to ${email}`,
          type: MESSAGE.error,
          icon: ERROR
        });
      }
    },
    [closeModal, setAsyncLoading, showSnackbar, userName]
  );

  const showAddUserModal = useCallback(
    (
      orgId: string,
      roles: IAnyPropertyNameAndAnyValue,
      firstUser: boolean,
      reloadUsers: any,
      onSendInvitation: any
    ) =>
      showModal({
        title: "Add user",
        actionText: "Send invite",
        isActionDisabled: true,
        content: (
          <Formik
            initialValues={{ email: "", role: "" }}
            onSubmit={(values: any) =>
              onSubmit(values, orgId, reloadUsers, onSendInvitation)
            }
            validationSchema={validationSchema}
            validateOnMount
          >
            {(formik) => (
              <AddUserForm
                roles={rolesFirstUserMapper(roles, firstUser)}
                formik={formik}
              />
            )}
          </Formik>
        ),
        formId: "addUserForm"
      }),
    [showModal, validationSchema, onSubmit]
  );

  return showAddUserModal;
}
