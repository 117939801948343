import React from "react";
import "./loginErrorDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTH_ERROR } from "../onboarding/activation/azureAuthErrorCodes";
import ONBOARDING_STATUS from "../onboarding/onboardingStatus";
import Button from "../designSystem/button/button";
import { URL_LOGOUT, URL_OFFICE_365_ACTIVATION } from "../constants/appUrls";
import { WARNING_CIRCLE } from "../constants/images";
import { ACTIVATION_GENERIC_ERROR_MESSAGES } from "../onboarding/activation/activationErrors";

export default function LoginErrorDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { stage, error } = location.state;

  const getStageName = () => {
    switch (stage) {
      case ONBOARDING_STATUS.ACTIVATION:
        return "activation";
      case ONBOARDING_STATUS.SUBSCRIPTION:
        return "subscription";
      default:
        return "login";
    }
  };

  const getConsentDeclinedMessage = () => {
    switch (stage) {
      case ONBOARDING_STATUS.ACTIVATION:
        return "activate your account";
      case ONBOARDING_STATUS.SUBSCRIPTION:
        return "complete your subscription";
      default:
        return "login";
    }
  };

  const getHeader = () => {
    const stageName = getStageName();
    switch (error) {
      case AUTH_ERROR.CONSENT_DECLINED:
        return "Terms declined";
      case AUTH_ERROR.PERMISSIONS_ERROR:
        return `You don't have the required permissions to proceed`;
      case AUTH_ERROR.ACCESS_DENIED:
        return `Access denied during ${stageName}`;
      case AUTH_ERROR.FORBIDDEN:
        return `Access forbidden during ${stageName}`;
      default:
        return `There was an error during ${stageName}`;
    }
  };

  const getBody = () => {
    switch (error) {
      case AUTH_ERROR.CONSENT_DECLINED:
        return (
          <p>{`You need to accept the permissions requested by Inspect in order to ${getConsentDeclinedMessage()}.`}</p>
        );
      case AUTH_ERROR.PERMISSIONS_ERROR:
        return (
          <>
            <p>{`You need full company permissions to accept the ${getStageName()} terms${
              stage === ONBOARDING_STATUS.ACTIVATION ? " and finish setup" : ""
            }.`}</p>
            <p>
              You can also forward the verification email to someone with full
              permissions in your company to accept.
            </p>
          </>
        );
      case AUTH_ERROR.ACCESS_DENIED:
        return (
          <p>
            Please ask an administrator to provide approval to Soteria Inspect
          </p>
        );
      case AUTH_ERROR.FORBIDDEN:
        return (
          <p>
            Please ask an administrator to provide approval to Soteria Inspect
          </p>
        );
      case AUTH_ERROR.INVITATION_ERROR:
        return <p>{ACTIVATION_GENERIC_ERROR_MESSAGES.INVITATION_LINK_ERROR}</p>;
      default:
        return (
          <p>{`${location.state.message} Error code AADSTS${location.state.code}`}</p>
        );
    }
  };

  const headerText = getHeader();
  const bodyText = getBody();

  const onClickTryAgain = () => {
    if (stage === ONBOARDING_STATUS.ACTIVATION) {
      navigate(URL_OFFICE_365_ACTIVATION);
    } else {
      navigate(URL_LOGOUT);
    }
  };

  return (
    <section className="loginErrorDetails">
      <img src={WARNING_CIRCLE} alt="Warning" />
      <h3>{headerText}</h3>
      {bodyText}
      <Button onClick={onClickTryAgain}>Try again</Button>
    </section>
  );
}
