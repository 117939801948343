import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useParams } from "react-router-dom";
import useUserProfileModel from "./profile.model";
import { IProfile } from "./profile.types";
import { useAppSelector } from "../redux/hooks";
import OrganizationsContext from "../contexts/organizations.context";
import SnackbarContext from "../contexts/snackbar.context";
import { ERROR, INFORMATION, ORGANIZATIONS, USERS } from "../constants/images";
import Icon from "../designSystem/icon/icon";
import {
  URL_ORGANIZATIONS,
  URL_SCAN,
  URL_SCAN_MSP,
  URL_USERS,
  URL_USERS_MSP
} from "../constants/appUrls";
import Breadcrumb from "../designSystem/breadcrumb/breadcrumb";
import { buildUrl } from "../utils/string.utils";
import ModalContext from "../contexts/modal.context";

export default function useUserProfile() {
  const [profile, setProfile] = useState<IProfile>({} as IProfile);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);

  const roles = useAppSelector((state: any) => state.roles);
  const { userId } = useAppSelector((state: any) => state.session);
  const loggedInUserRole = useAppSelector((state: any) => state.session.role);

  const loadInProgress = useRef(false);

  const { changeRole, loadProfile } = useUserProfileModel();
  const urlParams = useParams();

  const { organizationsList, loading } = useContext(OrganizationsContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { showModal, closeModal, setAsyncLoading } = useContext(ModalContext);

  const urlOrgId = useMemo(() => urlParams.orgId, [urlParams.orgId]);
  const urlUserId = useMemo(() => urlParams.userId, [urlParams.userId]);

  const currentUserId = useMemo(() => urlUserId || userId, [urlUserId, userId]);

  const icon = useMemo(
    () => <Icon image={urlOrgId ? ORGANIZATIONS : USERS} alt="User Profile" />,
    [urlOrgId]
  );

  const breadcrumb = useMemo(() => {
    const usersUrl = urlOrgId ? buildUrl(URL_USERS_MSP, urlOrgId) : URL_USERS;
    const items: any = [
      {
        content: urlUserId ? "Users" : "User profile",
        link: urlUserId ? usersUrl : undefined
      }
    ];
    if (urlUserId)
      items.push({
        content: profile.name,
        menuItems: [
          {
            id: urlOrgId ? buildUrl(URL_SCAN_MSP, urlOrgId) : URL_SCAN,
            value: "Scan"
          },
          {
            id: usersUrl,
            value: "Users"
          }
        ],
        isLoadingItem: isLoadingProfile
      });
    return (
      <Breadcrumb
        items={
          urlOrgId
            ? [
                {
                  content: "Organizations",
                  link: URL_ORGANIZATIONS
                },
                {
                  content: `${organizationsList[urlOrgId]?.name}`
                },
                ...items
              ]
            : items
        }
      />
    );
  }, [isLoadingProfile, organizationsList, profile.name, urlOrgId, urlUserId]);

  const loadUserData = useCallback(async () => {
    try {
      loadInProgress.current = true;
      setIsLoadingProfile(true);
      const profileData = await loadProfile(currentUserId);
      setProfile({
        givenName: profileData.givenName,
        familyName: profileData.familyName,
        name: profileData.name,
        email: profileData.email,
        phoneNumber: profileData.phoneNumber,
        mobile: profileData.mobile,
        picture: profileData.picture,
        role: profileData.roles[0]
      });
    } catch (error) {
      showSnackbar({
        text: `Error getting profile information. ${error}`,
        type: "error",
        icon: ERROR
      });
    } finally {
      setIsLoadingProfile(false);
    }
  }, [currentUserId, loadProfile, showSnackbar]);

  const changeRoleAction = useCallback(
    async (newRoleId: string) => {
      try {
        setAsyncLoading(true);
        await changeRole(currentUserId, newRoleId, profile.role.id);
        setProfile((prev) => ({ ...prev, role: roles[newRoleId] }));
        closeModal();
        setAsyncLoading(false);
        showSnackbar({
          text: "Role changed successfully",
          type: "info",
          icon: INFORMATION
        });
      } catch (error) {
        showSnackbar({
          text: `Error changing the role. ${error}`,
          type: "error",
          icon: ERROR
        });
      }
    },
    [
      changeRole,
      closeModal,
      currentUserId,
      profile,
      roles,
      setAsyncLoading,
      showSnackbar
    ]
  );

  const onRoleChange = useCallback(
    (newRoleId: string) => {
      showModal({
        title: "Change user role",
        clickAction: () => changeRoleAction(newRoleId),
        actionText: "Yes",
        content: `Are you sure you want to change the role of ${profile.name} to ${roles[newRoleId].name}?`
      });
    },
    [changeRoleAction, profile.name, roles, showModal]
  );

  useEffect(() => {
    !loadInProgress.current &&
      Object.keys(organizationsList).length &&
      loadUserData();
  }, [organizationsList]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    profile,
    loading,
    isLoadingProfile,
    urlOrgId,
    urlUserId,
    icon,
    breadcrumb,
    loggedInUserRole,
    onRoleChange
  };
}
