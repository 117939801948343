import React from "react";
import LoginErrorContent from "./loginErrorContent";
import Onboarding from "../onboarding/onboarding";

export default function LoginError() {
  return (
    <Onboarding>
      <LoginErrorContent />
    </Onboarding>
  );
}
