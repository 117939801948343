export default {
  inspectors: "Inspectors",
  "paused inspectors": "Paused inspectors",
  "paused inspector": "Paused inspector",
  severity: "Severity",
  name: "Name",
  notes: "Notes",
  "start date": "Start date",
  "end date": "End date",
  "paused at": "Paused at",
  paused: "Paused",
  "resumed at": "Resumed at",
  "started at": "Started at",
  resumes: "Resumes",
  "error retrieving inspectors data": (error: string) =>
    `Error retrieving inspectors data. ${error}`,
  "pause inspector": "Pause inspector",
  "resume inspector": "Resume inspector",
  "inspector will be resumed in future scans.":
    "Inspector will be resumed in future scans.",
  "inspector will be paused in future scans.":
    "Inspector will be paused in future scans.",
  "click to resume inspector": "Click to resume inspector",
  "click to pause inspector": "Click to pause inspector",
  "click to resume": "Click to resume",
  pause: "Pause",
  resume: "Resume",
  "default date": "Never"
};
