/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from "react";
import "./inspectorManagment.scss";

import { Field } from "formik";
import ModalContext from "../../../contexts/modal.context";
import Select from "../../../designSystem/select/select";
import TextArea from "../../../designSystem/textArea/textArea";
import { MAX_CHARS_NOTES_PAUSE_INSPECTOR } from "./useInspectorManagment";

function pausePeriodIntervals() {
  return [
    { key: "30", value: "30 days", id: "30 days" },
    { key: "60", value: "60 days", id: "60 days" },
    { key: "90", value: "90 days", id: "90 days" },
    { key: "Permanently", value: "Permanently", id: "Permanently" }
  ];
}

export default function PauseInspectorForm({
  formik: { handleSubmit, values, errors, setFieldValue, isValid },
  name
}: any) {
  const { setActionDisabled } = useContext(ModalContext);
  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => {
    setActionDisabled(!isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  return (
    <form
      id="pauseInspectorForm"
      className="inspector-managment"
      onSubmit={handleSubmit}
    >
      <div className="inspector-managment__information">
        <p className="inspector-managment__information-text">
          Select the pause duration for the{" "}
          <span className="label--bolder">{name}</span> inspector.
        </p>

        <p className="inspector-managment__information-subtitle">
          This setting will only affect future scans.
        </p>
      </div>
      <div className="inspector-managment__period">
        <label className="label label--bold" htmlFor="period">
          Paused period
        </label>
        <Field
          name="period"
          value={values.period}
          onChange={(value: string) => setFieldValue("period", value)}
          items={pausePeriodIntervals()}
          component={Select}
          placeholder="Select"
        />
      </div>

      <div className="inspector-managment__notes">
        <div className="inspector-managment__notes-label">
          <label className="label label--bold">Notes</label>
          <span>
            {characterCount}/{MAX_CHARS_NOTES_PAUSE_INSPECTOR}
          </span>
        </div>
        <Field
          name="notes"
          value={values.notes}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setFieldValue("notes", value);
            setCharacterCount(value.length);
          }}
          isValid={!errors?.notes}
          width="Max"
          component={TextArea}
        />
      </div>
    </form>
  );
}
