import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ONBOARDING_STATUS from "../onboarding/onboardingStatus";
import SERVICE_PROVIDERS from "./serviceProviders";
import STATUS from "../designSystem/status";
import useAuth0Authentication from "../authentication/useAuth0Authentication";
import useLoginError from "./useLoginError";
import {
  Tenant,
  logIn,
  setAccessToken,
  setEmail,
  setServiceProvider,
  setTenant,
  setUserId,
  setUserImage,
  setUserName
} from "../redux/session.slice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import useOrganizationsAPI from "../api/useOrganizationsAPI";
import { URL_LOGOUT } from "../constants/appUrls";

export default function useLogin() {
  const { AUTH0 } = SERVICE_PROVIDERS;
  const { inProgress, warning } = STATUS;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [text, setText] = useState("");
  const [title, setTitle] = useState("Login in progress...");
  const [showErrorSection, setShowErrorSection] = useState(false);
  const [status, setStatus] = useState(inProgress);
  const getTenantInfoInProgress = useRef(false);
  const { accessToken, canStartLogin, error, login, user } =
    useAuth0Authentication();
  const handleLoginError = useLoginError();
  const { getTenantFromExternalServiceProvidersByEmail } =
    useOrganizationsAPI();

  const email = useAppSelector((state) => state.session.email);
  const token = useAppSelector((state) => state.session.accessToken);

  if (canStartLogin) {
    login();
  }

  const validateProfile = useCallback(
    (_user: any) => {
      if (_user?.email) {
        dispatch(setEmail(_user?.email));
        dispatch(setUserId(_user?.sub));
        dispatch(setUserName(_user?.name));
        dispatch(setUserImage(_user?.picture));
      } else {
        setStatus(warning);
        setTitle("Error validating subscription");
        setText(
          "The Email field for your user account in Entra ID is empty. This is a required field. Please go to your User profile in Azure and enter this field."
        );
        setShowErrorSection(true);
      }
    },
    [dispatch, warning]
  );

  useEffect(() => {
    if (error) {
      handleLoginError(error, ONBOARDING_STATUS.UNKNOWN);
    }
  }, [error, handleLoginError]);

  useEffect(() => {
    if (accessToken.length) {
      dispatch(setAccessToken(accessToken));
    }
  }, [accessToken, dispatch, user, warning]);

  useEffect(() => {
    if (user) {
      dispatch(setServiceProvider(AUTH0));
      validateProfile(user);
    }
  }, [AUTH0, dispatch, user, validateProfile]);

  const getTenantInformation = useCallback(
    async (_email: string) => {
      try {
        const tenant: Tenant =
          await getTenantFromExternalServiceProvidersByEmail(_email);
        dispatch(setTenant(tenant));
        dispatch(logIn());
      } catch (_error: any) {
        console.error(
          `Error loading the information of the tenant of ${_email}. ${_error.message}. Code ${_error.cause}`
        );
        setStatus(warning);
        setTitle("Error validating subscription");
        setText("The information of your Microsoft tenant could not be loaded");
        setShowErrorSection(true);
      }
    },
    [getTenantFromExternalServiceProvidersByEmail, dispatch, warning]
  );

  useEffect(() => {
    if (getTenantInfoInProgress.current) {
      return;
    }
    if (token.length && email.length) {
      setTitle("Login successful");
      setText("Loading profile information...");
      getTenantInfoInProgress.current = true;
      getTenantInformation(email);
    }
  }, [dispatch, email, getTenantInformation, token, warning]);

  const handleLogout = () => navigate(URL_LOGOUT);

  return {
    text,
    title,
    status,
    showErrorSection,
    handleLogout
  };
}
