import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { ColumnFiltersState } from "@tanstack/react-table";
import { useNavigate, useParams } from "react-router-dom";
import { IScan } from "./scan.types";
import useScanModel from "./scan.model";
import findingsTableFactoryColumns from "./components/scan.tableFactory";
import { FINDING_SEVERITY, IFindingsList } from "../finding/finding.types";
import { scanDataMapper } from "./scan.mappers";
import Breadcrumb from "../designSystem/breadcrumb/breadcrumb";
import {
  URL_FINDING,
  URL_FINDING_MSP,
  URL_ORGANIZATIONS,
  URL_SCAN,
  URL_SCAN_MSP,
  URL_USERS,
  URL_USERS_MSP,
  URL_INSPECTORS_MSP,
  URL_INSPECTORS,
  URL_SETTINGS_MSP,
  URL_SETTINGS
} from "../constants/appUrls";
import OrganizationsContext from "../contexts/organizations.context";
import Icon from "../designSystem/icon/icon";
import { ORGANIZATIONS, SCAN } from "../constants/images";
import { buildUrl } from "../utils/string.utils";
import { useAppSelector } from "../redux/hooks";
import usePermissions from "../permissions/usePermissions";
import PERMISSIONS_LIST from "../permissions/permissions";

export default function useScanViewModel() {
  const [scanData, setScanData] = useState(scanDataMapper());
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [searchText, setSearchText] = useState("");
  const [severityFilterValue, setSeverityFilterValue] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const loadingScanRef = useRef(false);

  const { tenant, organizationName } = useAppSelector(
    (state: any) => state.session
  );

  const { organizationsList, loading, dataLoaded } =
    useContext(OrganizationsContext);

  const { getLastScan } = useScanModel();
  const { orgId } = useParams();

  const navigate = useNavigate();

  const canI = usePermissions();

  const isLoading = useMemo(
    () => loading || loadingScan,
    [loading, loadingScan]
  );

  const breadcrumb = useMemo(() => {
    const currentItemId = orgId ? buildUrl(URL_SCAN_MSP, orgId) : URL_SCAN;

    const menuItems = [
      {
        id: currentItemId,
        value: "Scan"
      },
      {
        id: orgId ? buildUrl(URL_USERS_MSP, orgId) : URL_USERS,
        value: "Users"
      },
      {
        id: orgId ? buildUrl(URL_INSPECTORS_MSP, orgId) : URL_INSPECTORS,
        value: "Inspectors"
      }
    ];

    if (canI(PERMISSIONS_LIST.seeSettings)) {
      menuItems.push({
        id: orgId ? buildUrl(URL_SETTINGS_MSP, orgId) : URL_SETTINGS,
        value: "Settings"
      });
    }
    const items = [
      {
        content: "Scan",
        menuItems,
        currentItemId
      }
    ];

    return (
      <Breadcrumb
        items={
          orgId
            ? [
                {
                  content: "Organizations",
                  link: URL_ORGANIZATIONS
                },
                {
                  content: `${organizationsList[orgId]?.name}`
                },
                ...items
              ]
            : items
        }
      />
    );
  }, [orgId, organizationsList, canI]);

  const icon = useMemo(
    () => <Icon image={orgId ? ORGANIZATIONS : SCAN} alt="Findings" />,
    [orgId]
  );

  const orgName = useMemo(
    () => (orgId ? organizationsList[orgId!]?.name : organizationName),
    [orgId, organizationName, organizationsList]
  );

  const loadScan = useCallback(async () => {
    const scan: IScan | undefined = await getLastScan(
      orgId ? organizationsList[orgId!]?.externalProvider?.id : tenant.id
    );
    if (!scan) return;
    setScanData(scanDataMapper(scan));
  }, [getLastScan, orgId, organizationsList, tenant.id]);

  const loadData = useCallback(async () => {
    if (loadingScanRef.current) return;
    loadingScanRef.current = true;
    setLoadingScan(true);
    await loadScan();
    setLoadingScan(false);
  }, [loadScan]);

  const sortSeverityFilterValues = (severityItems: any) => {
    const keys = Object.keys(severityItems);

    keys.sort((a: any, b: any) => {
      const firstSeverity = parseInt(FINDING_SEVERITY[a], 10);
      const secondSeverity = parseInt(FINDING_SEVERITY[b], 10);

      return secondSeverity - firstSeverity;
    });

    return keys.map((severity: any) => ({
      id: severity,
      value: severity
    }));
  };

  const severityFilterItems = useMemo(() => {
    const severityCount = Object.values(
      scanData.findings as IFindingsList
    ).reduce((allFindings: any, finding: any) => {
      const count = allFindings[finding.severity] ?? 0;
      return {
        ...allFindings,
        [finding.severity]: count + 1
      };
    }, {});

    return sortSeverityFilterValues(severityCount);
  }, [scanData.findings]);

  const clearFilters = useCallback(() => {
    setSeverityFilterValue("All");
    setColumnFilters([]);
  }, []);

  const handleFiltersSelected = useCallback(() => {
    showFilters && clearFilters();
    setShowFilters((prevState: boolean) => !prevState);
  }, [clearFilters, showFilters]);

  const handleFindingSelected = useCallback(
    (findingId: string) =>
      navigate(
        orgId
          ? buildUrl(URL_FINDING_MSP, orgId, scanData.id, findingId)
          : buildUrl(URL_FINDING, scanData.id, findingId)
      ),
    [navigate, orgId, scanData.id]
  );

  const handleSearchChange = useCallback(
    (text: string) => setSearchText(text),
    []
  );

  const handleSeverityFilterSelected = useCallback(
    (id: string | null) => {
      if (id) {
        setSeverityFilterValue(id);
        setColumnFilters([
          {
            id: "severity",
            value: id
          }
        ]);
      } else {
        clearFilters();
      }
    },
    [clearFilters]
  );

  useEffect(() => {
    (!orgId || dataLoaded) && loadData();
  }, [dataLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  // async function onPauseInspectorFromScan(finding: IFinding) {
  //   await onPauseInspector(finding, async () => {
  //     setLoadingScan(true);
  //     clearFilters();
  //     setScanData(scanDataMapper());
  //     await loadScan();
  //     setLoadingScan(false);
  //   });
  // }

  // async function onResumeInspectorFromScan(finding: any) {
  //   await onResumeInspector(finding, async () => {
  //     setLoadingScan(true);
  //     clearFilters();
  //     setScanData(scanDataMapper());
  //     await loadScan();
  //     setLoadingScan(false);
  //   });
  // }

  return {
    scanData,
    columnFilters,
    columns: findingsTableFactoryColumns,
    loadingScan,
    searchText,
    severityFilterItems,
    severityFilterValue,
    showFilters,
    breadcrumb,
    orgId,
    loading,
    icon,
    isLoading,
    orgName,
    handleFindingSelected,
    handleSearchChange,
    handleFiltersSelected,
    handleSeverityFilterSelected
  };
}
