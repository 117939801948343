import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ColumnFiltersState } from "@tanstack/react-table";
import { buildUrl } from "../utils/string.utils";
import useAddUser from "./components/addUser/useAddUser";
import ModalContext from "../contexts/modal.context";
import { useAppSelector } from "../redux/hooks";
import useUsersListModel from "./users-list.model";
import {
  URL_INSPECTORS,
  URL_INSPECTORS_MSP,
  URL_ORGANIZATIONS,
  URL_PROFILE,
  URL_SCAN,
  URL_SCAN_MSP,
  URL_USERS,
  URL_USERS_DETAILS,
  URL_USERS_MSP,
  URL_USERS_MSP_DETAILS
} from "../constants/appUrls";
import UsersListTableFactory from "./components/users-list.tableFactory";
import InvitationsListTableFactory from "./components/invitations-list.tableFactory";
import OrganizationsContext from "../contexts/organizations.context";
import Breadcrumb from "../designSystem/breadcrumb/breadcrumb";

export default function useUsersList() {
  const [currentTab, setCurrentTab] = useState("tab-0");
  const [searchText, setSearchText] = useState("");
  const [usersColumnFilters] = useState<ColumnFiltersState>([]);
  const [invitationsColumnFilters] = useState<ColumnFiltersState>([]);

  const retrieveDataRef = useRef(false);

  const { userId, organizationId, identityOrgId } = useAppSelector(
    (state: any) => state.session
  );
  const roles = useAppSelector((state: any) => state.roles);

  const {
    usersList,
    invitationsList,
    isLoadingInvitations,
    isLoadingUsers,
    retrieveInvitations,
    retrieveUsers,
    onRevokeInvitation,
    onDeleteUser,
    onSendInvitation,
    setUsersList,
    setInvitationsList
  } = useUsersListModel();

  const { showModal } = useContext(ModalContext);
  const { organizationsList, loading } = useContext(OrganizationsContext);

  const showAddUserModal = useAddUser();
  const navigate = useNavigate();
  const urlParams = useParams();

  const urlOrgId = useMemo(() => urlParams.orgId, [urlParams.orgId]);

  const currentOrgId: string = useMemo(() => {
    retrieveDataRef.current = false;
    setUsersList([]);
    setInvitationsList([]);
    return urlOrgId || organizationId;
  }, [organizationId, setInvitationsList, setUsersList, urlOrgId]);

  const currentIdentityOrg = useMemo(
    () =>
      urlOrgId
        ? organizationsList[currentOrgId].identityProvider.organizationId
        : identityOrgId,
    [identityOrgId, organizationsList, urlOrgId, currentOrgId]
  );

  const userTableColumns = useMemo(
    () => UsersListTableFactory.getColumns(!!urlOrgId, userId, currentOrgId),
    [currentOrgId, urlOrgId, userId]
  );

  const invitationsTableColumns = useMemo(
    () => InvitationsListTableFactory.getColumns(),
    []
  );

  const breadcrumb = useMemo(() => {
    const currentItemId = urlOrgId
      ? buildUrl(URL_USERS_MSP, urlOrgId)
      : URL_USERS;
    const items = [
      {
        content: "Users",
        menuItems: [
          {
            id: urlOrgId ? buildUrl(URL_SCAN_MSP, urlOrgId) : URL_SCAN,
            value: "Scan"
          },
          {
            id: currentItemId,
            value: "Users"
          },
          {
            id: urlOrgId
              ? buildUrl(URL_INSPECTORS_MSP, urlOrgId)
              : URL_INSPECTORS,
            value: "Inspectors"
          }
        ],
        currentItemId
      }
    ];
    return (
      <Breadcrumb
        items={
          urlOrgId
            ? [
                {
                  content: "Organizations",
                  link: URL_ORGANIZATIONS
                },
                {
                  content: `${organizationsList[urlOrgId]?.name}`
                },
                ...items
              ]
            : items
        }
      />
    );
  }, [organizationsList, urlOrgId]);

  const onShowAddUserModal = useCallback(
    (firstUser = false) =>
      showAddUserModal(
        currentIdentityOrg,
        roles,
        firstUser,
        () => {
          setCurrentTab("tab-1");
          retrieveInvitations(currentIdentityOrg);
        },
        onSendInvitation
      ),
    [
      currentIdentityOrg,
      onSendInvitation,
      retrieveInvitations,
      roles,
      showAddUserModal
    ]
  );
  const showDeleteUserModal = useCallback(
    (id: string, name: string) =>
      showModal({
        title: "Delete user",
        clickAction: () => onDeleteUser(id, name, currentIdentityOrg),
        actionText: "Yes, delete",
        content: <p>Are you sure you want to delete the user {name}?</p>
      }),
    [showModal, onDeleteUser, currentIdentityOrg]
  );

  const showRevokeInvitationModal = useCallback(
    (invitationId: string, invitee: string) =>
      showModal({
        title: "Revoke invitation",
        clickAction: () =>
          onRevokeInvitation(currentIdentityOrg, invitationId, invitee),
        actionText: "Yes, revoke",
        content: (
          <p>Are you sure you want to revoke the invitation for {invitee}?</p>
        )
      }),
    [onRevokeInvitation, showModal, currentIdentityOrg]
  );

  const onSearchChange = (event: any) => setSearchText(event.target.value);

  const onClickCell = (rowId: string) => {
    let url = "";
    const params = [];
    if (rowId === userId) {
      url = URL_PROFILE;
    } else {
      url = urlOrgId ? URL_USERS_MSP_DETAILS : URL_USERS_DETAILS;
      !!urlOrgId && params.push(urlOrgId);
      params.push(rowId);
    }

    navigate(buildUrl(url, ...params));
  };

  useEffect(() => {
    if (!retrieveDataRef.current) {
      retrieveDataRef.current = true;
      retrieveUsers(currentIdentityOrg);
      retrieveInvitations(currentIdentityOrg);
    }
    // NOTE: disabling deps may cause unintended behavior
    // be aware of which dependecy is going to be used here
  }, [organizationsList, currentIdentityOrg]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    orgName: organizationsList[currentOrgId]?.name,
    breadcrumb,
    searchText,
    usersList,
    invitationsList,
    currentTab,
    isLoadingUsers,
    isLoadingInvitations,
    userTableColumns,
    invitationsTableColumns,
    urlOrgId,
    loading,
    usersColumnFilters,
    invitationsColumnFilters,
    onSearchChange,
    onClickCell,
    onShowAddUserModal,
    showDeleteUserModal,
    showRevokeInvitationModal,
    onSendInvitation
  };
}
