import React from "react";
import { Navigate } from "react-router-dom";
import { URL_PROFILE } from "../../constants/appUrls";

export const permissionsGuard = (
  canGet: boolean,
  component: React.JSX.Element
) => (canGet ? component : <Navigate to={URL_PROFILE} replace />);

export default permissionsGuard;
