import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { URL_SCAN } from "../../constants/appUrls";

export const useLastScanGuard = () => {
  const { orgId } = useParams();
  const { id } = useAppSelector((state: any) => state.session.tenant);

  return orgId === id ? <Navigate to={URL_SCAN} replace /> : false;
};

export default useLastScanGuard;
