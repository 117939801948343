import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUsersAPI from "../api/useUsersAPI";
import { IInvitation, IUserData } from "./users.types";
import useIsMounted from "../hooks/useIsMounted";
import SnackbarContext from "../contexts/snackbar.context";
import { DELETE, ERROR, USERS } from "../constants/images";
import { buildUrl } from "../utils/string.utils";
import { URL_PROFILE } from "../constants/appUrls";
import { MESSAGE } from "../designSystem/snackbar/snackbar.types";
import ModalContext from "../contexts/modal.context";
import { usersListMapper } from "./users-list.mappers";
import useInvitationsAPI from "../api/useInvitationsAPI";

export default function useUsersListModel() {
  const { getUsersList, deleteUser } = useUsersAPI();
  const { getInvitationsList, revokeInvitation, sendInvitation } =
    useInvitationsAPI();
  const isMounted = useIsMounted();
  const { showSnackbar } = useContext(SnackbarContext);
  const { closeModal, setAsyncLoading } = useContext(ModalContext);
  const navigate = useNavigate();

  const [usersList, setUsersList] = useState<IUserData[]>([]);
  const [invitationsList, setInvitationsList] = useState<IInvitation[]>([]);

  const [isLoadingInvitations, setIsLoadingInvitations] =
    useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);

  const retrieveUsers = useCallback(
    async (orgId: string) => {
      setIsLoadingUsers(true);
      setUsersList([]);
      try {
        if (!isMounted.current) return;
        const response: IUserData[] = await getUsersList(orgId);
        if (!response.length) {
          console.log(`The organization ${orgId} doesn't have users on it`);
        }
        setIsLoadingUsers(false);
        setUsersList(usersListMapper(response));
      } catch (error: any) {
        console.error(`Error getting users. Status ${error.status}. ${error}`);
        setIsLoadingUsers(false);
        showSnackbar({
          text: `Unable to retrieve users list. ${error}`,
          type: "error",
          icon: ERROR
        });
        navigate(buildUrl(URL_PROFILE));
      }
    },
    [getUsersList, isMounted, navigate, showSnackbar]
  );

  const retrieveInvitations = useCallback(
    async (orgId: string) => {
      setIsLoadingInvitations(true);
      setInvitationsList([]);
      try {
        const response: IInvitation[] = await getInvitationsList(orgId);
        if (!isMounted.current) return;
        if (!response) {
          console.log(
            `The organization ${orgId} doesn't have invitations on it`
          );
          setIsLoadingInvitations(false);
          return;
        }
        setIsLoadingInvitations(false);
        setInvitationsList(response);
      } catch (error: any) {
        console.error(
          `Error getting invitations. Status ${error.status}. ${error}`
        );
        setIsLoadingInvitations(false);
      }
    },
    [getInvitationsList, isMounted]
  );

  const onRevokeInvitation = useCallback(
    (orgId: string, invitationId: string, invitee: string) => {
      setAsyncLoading(true);
      revokeInvitation(invitationId, orgId)
        .then(() => {
          console.log(`Invitation for ${invitee} revoked`);
          showSnackbar({
            text: `Invitation for ${invitee} revoked`,
            type: MESSAGE.info,
            icon: DELETE
          });
          setAsyncLoading(false);
          closeModal();
          retrieveInvitations(orgId);
        })
        .catch((error) => {
          console.error(
            `Error revoking invitation of ${invitee}. id: ${invitationId}. Status ${error.status}. ${error}`
          );
          showSnackbar({
            text: `Error revoking invitation of ${invitee}. ${error}`,
            type: MESSAGE.error,
            icon: ERROR
          });
          setAsyncLoading(false);
          closeModal();
        });
    },
    [
      revokeInvitation,
      showSnackbar,
      setAsyncLoading,
      closeModal,
      retrieveInvitations
    ]
  );

  const onDeleteUser = (id: string, name: string, orgId: string) => {
    setAsyncLoading(true);

    deleteUser(id)
      .then(() => {
        console.log(`User ${name} deleted`);
        showSnackbar({
          text: `User ${name} deleted`,
          type: MESSAGE.info,
          icon: DELETE
        });
        setAsyncLoading(false);
        closeModal();
        retrieveUsers(orgId);
      })
      .catch((error: any) => {
        console.error(
          `Error deleting user ${name}. id: ${id}. Status ${error.status}. ${error}`
        );
        showSnackbar({
          text: `Error deleting user ${name}`,
          type: MESSAGE.error,
          icon: ERROR
        });
        setAsyncLoading(false);
        closeModal();
      });
  };

  const onSendInvitation = (
    invitation: IInvitation,
    reloadUsers: any = null
  ) => {
    setAsyncLoading(true);

    sendInvitation(invitation)
      .then(() => {
        setAsyncLoading(false);
        closeModal();
        showSnackbar({
          text: `Invitation sent to ${invitation.invitee_email}`,
          type: MESSAGE.info,
          icon: USERS
        });
        if (reloadUsers) {
          reloadUsers();
        }
      })
      .catch((error: any) => {
        const {
          cause: { friendlyMessage }
        } = error;
        console.error(friendlyMessage);
        showSnackbar({
          text: `${friendlyMessage}.`,
          type: MESSAGE.error,
          icon: ERROR
        });
        setAsyncLoading(false);
        // closeModal();
      });
  };

  return {
    isLoadingInvitations,
    isLoadingUsers,
    usersList,
    invitationsList,
    retrieveInvitations,
    retrieveUsers,
    onRevokeInvitation,
    onDeleteUser,
    onSendInvitation,
    setUsersList,
    setInvitationsList
  };
}
