import React, { useCallback, useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import ModalContext from "../../../contexts/modal.context";
import PauseInspectorForm from "./pause-inspectors-form";
import ResumeInspectorForm from "./resume-inspectors-form";
import useInspectorsAPI from "../../../api/useInspectorsAPI";
import { useAppSelector } from "../../../redux/hooks";
import {
  FINDING_SEVERITY,
  IFinding,
  IInspector
} from "../../../finding/finding.types";
import formatDate, {
  addDays,
  getEpoch,
  substractMinutes
} from "../../../utils/formatDate";
import { IFunction } from "../../../constants/common.types";
import SnackbarContext from "../../../contexts/snackbar.context";
import { MESSAGE } from "../../../designSystem/snackbar/snackbar.types";
import { ERROR, INFORMATION } from "../../../constants/images";
import INSPECTORS_CONSTANTS from "../../../inspectors/inspector.constants";
import {
  URL_USERS_MSP,
  URL_ORGANIZATIONS,
  URL_SCAN_MSP,
  URL_SCAN,
  URL_INSPECTORS,
  URL_INSPECTORS_MSP
} from "../../../constants/appUrls";
import { buildUrl } from "../../../utils/string.utils";
import Breadcrumb from "../../../designSystem/breadcrumb/breadcrumb";
import OrganizationsContext from "../../../contexts/organizations.context";

export const MAX_CHARS_NOTES_PAUSE_INSPECTOR = 1000;

const INSPECTOR_MANAGMENT_CONST = {
  max_error: `Must be lower than ${MAX_CHARS_NOTES_PAUSE_INSPECTOR}`
};

type IPauseInspectorFormValues = {
  notes?: string;
  period: string;
};

export type IPauseInspectorsRequestParams = {
  activity: {
    CreatedBy: string;
    Note: string;
  }[];
  findingName: string;
  impact: FINDING_SEVERITY;
  inspectorId: string;
  inspectorName: string;
  isActive: boolean;
  startedAt?: number;
  expiredAt?: number;
  subscriptionLevel?: [];
  version?: 0;
};

function periodPauseMapper(value: string | number): any {
  if (value === "Permanently") {
    return {
      expiredAt: 0,
      startedAt: 0,
      isActive: false
    };
  }
  let numericValue = 0;
  switch (value) {
    case "60 days":
      numericValue = 60;
      break;
    case "30 days":
      numericValue = 30;
      break;

    case "90 days":
      numericValue = 90;
      break;
    default:
  }
  return {
    expiredAt: getEpoch(substractMinutes(new Date(), 1)),
    startedAt: getEpoch(addDays(new Date(), numericValue))
  };
}

function pauseInspectorRequestParamsMapper(
  { findingName, impact, inspectorID, inspectorName }: any,
  formValues: IPauseInspectorFormValues,
  email: string
): IPauseInspectorsRequestParams {
  const { notes = "", period } = formValues;
  return {
    findingName,
    impact,
    inspectorId: inspectorID,
    inspectorName,
    isActive: true,
    ...periodPauseMapper(period),
    activity: [
      {
        CreatedBy: email,
        Note: notes
      }
    ]
  };
}

function ResumeInspectorRequestMapper(
  { findingName, impact, inspectorID, inspectorId, inspectorName }: any,
  email: string
): IPauseInspectorsRequestParams {
  const startedAtEpoch = Number((new Date().getTime() / 1000).toFixed(0));
  const date = formatDate(startedAtEpoch);
  return {
    findingName,
    impact,
    inspectorId: inspectorID || inspectorId,
    inspectorName,
    isActive: true,
    startedAt: startedAtEpoch,
    expiredAt: 0,
    activity: [
      {
        CreatedBy: email,
        Note: `Resumed on ${date}`
      }
    ]
  };
}

export default function useInspectorManagment() {
  const { showModal, closeModal, setAsyncLoading } = useContext(ModalContext);
  const {
    pauseInspectorByOrganization,
    resumeInspectorByOrganization,
    getInspectorsByOrganizationIdAndActiveStatus
  } = useInspectorsAPI();
  const { email, organizationId } = useAppSelector(
    (state: any) => state.session
  );
  const { orgId } = useParams();
  const { showSnackbar } = useContext(SnackbarContext);
  const { organizationsList } = useContext(OrganizationsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [inspectorsData, setInspectorsData] = useState<IInspector[]>([]);

  const validationSchema = Yup.object().shape({
    period: Yup.string().required("Period is required"),
    notes: Yup.string().max(
      MAX_CHARS_NOTES_PAUSE_INSPECTOR,
      INSPECTOR_MANAGMENT_CONST.max_error
    )
  });

  const urlOrgId = useMemo(() => orgId, [orgId]);

  const currentOrgId = useMemo(
    () => urlOrgId || organizationId,
    [organizationId, urlOrgId]
  );

  const onSubmitPause = useCallback(
    async (
      pauseInspectorsParams: IPauseInspectorsRequestParams,
      onSuccess: IFunction
    ) => {
      try {
        setAsyncLoading(true);
        const response = await pauseInspectorByOrganization(
          pauseInspectorsParams,
          urlOrgId || organizationId
        );
        await onSuccess(response);
        closeModal();
        showSnackbar({
          text: `Inspector succesfully paused`,
          type: MESSAGE.info,
          icon: INFORMATION
        });
      } catch (error: any) {
        console.error(`Error pausing an Inspector`);
        setAsyncLoading(false);
        showSnackbar({
          text: `Error pausing an Inspector`,
          type: MESSAGE.error,
          icon: ERROR
        });
      }
    },
    [
      pauseInspectorByOrganization,
      organizationId,
      urlOrgId,
      closeModal,
      setAsyncLoading,
      showSnackbar
    ]
  );

  const onSubmitResume = useCallback(
    async (
      pauseInspectorsParams: IPauseInspectorsRequestParams,
      onSuccess: IFunction
    ) => {
      try {
        setAsyncLoading(true);
        const response = await resumeInspectorByOrganization(
          pauseInspectorsParams,
          orgId || organizationId
        );
        closeModal();
        showSnackbar({
          text: `Inspector succesfully resumed`,
          type: MESSAGE.info,
          icon: INFORMATION
        });
        await onSuccess(response);
      } catch (error: any) {
        console.error(`Error resuming an Inspector`);
        setAsyncLoading(false);
        showSnackbar({
          text: `Error resuming an Inspector`,
          type: MESSAGE.error,
          icon: ERROR
        });
      }
    },
    [
      resumeInspectorByOrganization,
      organizationId,
      closeModal,
      setAsyncLoading,
      showSnackbar,
      orgId
    ]
  );

  const showPauseInspectorModal = useCallback(
    (finding: IFinding, onSuccess: IFunction) =>
      showModal({
        title: INSPECTORS_CONSTANTS["pause inspector"],
        actionText: "Pause",
        content: (
          <Formik
            initialValues={
              { period: "", notes: "" } as IPauseInspectorFormValues
            }
            onSubmit={(values: IPauseInspectorFormValues) => {
              onSubmitPause(
                pauseInspectorRequestParamsMapper(finding, values, email),
                onSuccess
              );
            }}
            validationSchema={validationSchema}
            validateOnMount
          >
            {(formik) => (
              <PauseInspectorForm formik={formik} name={finding.findingName} />
            )}
          </Formik>
        ),
        formId: "pauseInspectorForm"
      }),
    [showModal, validationSchema, email, onSubmitPause]
  );

  const showResumeInspectorModal = useCallback(
    (finding: IFinding, onSuccess: IFunction) =>
      showModal({
        title: INSPECTORS_CONSTANTS["resume inspector"],
        actionText: "Resume",
        content: (
          <Formik
            initialValues={{}}
            onSubmit={() => {
              onSubmitResume(
                ResumeInspectorRequestMapper(finding, email),
                onSuccess
              );
            }}
          >
            {(formik) => (
              <ResumeInspectorForm formik={formik} name={finding.findingName} />
            )}
          </Formik>
        ),
        formId: "resumeInspectorForm"
      }),
    [showModal, email, onSubmitResume]
  );
  const onPauseInspector = useCallback(
    (finding: any, onSuccess: IFunction) => {
      showPauseInspectorModal(finding, onSuccess);
    },
    [showPauseInspectorModal]
  );

  const onResumeInspector = useCallback(
    (finding: any, onSucess: IFunction) => {
      showResumeInspectorModal(finding, onSucess);
    },
    [showResumeInspectorModal]
  );

  const fetchInspectorData = useCallback(
    async (currentOrg: any) => {
      try {
        setIsLoading(true);
        const response = await getInspectorsByOrganizationIdAndActiveStatus(
          currentOrg,
          false
        );
        // migth be a http- 204 which means no data, nor empty, nor null, just no content in which case we fallback to empty
        setInspectorsData(response || []);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showSnackbar({
          text: INSPECTORS_CONSTANTS["error retrieving inspectors data"](
            error as string
          ),
          type: MESSAGE.error,
          icon: ERROR
        });
      }
    },
    [getInspectorsByOrganizationIdAndActiveStatus, showSnackbar]
  );

  async function resumeInspector(finding: IFinding) {
    await onResumeInspector(finding, async () => {
      setIsLoading(true);
      await fetchInspectorData(currentOrgId);
      setIsLoading(false);
    });
  }

  const breadcrumb = useMemo(() => {
    const currentItemId = urlOrgId
      ? buildUrl(URL_INSPECTORS_MSP, urlOrgId)
      : URL_INSPECTORS;
    const items = [
      {
        content: "Inspectors",
        menuItems: [
          {
            id: urlOrgId ? buildUrl(URL_SCAN_MSP, urlOrgId) : URL_SCAN,
            value: "Scans"
          },
          {
            id: urlOrgId ? buildUrl(URL_USERS_MSP, urlOrgId) : URL_SCAN,
            value: "Users"
          },
          {
            id: currentItemId,
            value: "Inspectors"
          }
        ],
        currentItemId
      }
    ];
    return (
      <Breadcrumb
        items={
          urlOrgId
            ? [
                {
                  content: "Organizations",
                  link: URL_ORGANIZATIONS
                },
                {
                  content: `${organizationsList[urlOrgId]?.name}`
                },
                ...items
              ]
            : items
        }
      />
    );
  }, [organizationsList, urlOrgId]);

  return {
    onPauseInspector, // function main function to show the pause modal and the executes the onSucess callback
    resumeInspector, // alias function to call inspectors data when finishing, applies only to inspectors table
    onResumeInspector, // main function to show the resume modal and then executes the onSucess callback
    inspectorsData, // data for inspectors table
    isLoading, // whenever is loading inspectors data
    breadcrumb,
    currentOrgId,
    fetchInspectorData
  };
}
