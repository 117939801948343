import ONBOARDING_STATUS from "../onboardingStatus";
import useOrganizationsAPI from "../../api/useOrganizationsAPI";
import useUsersAPI from "../../api/useUsersAPI";
import useOnboardingWorkflow from "../useOnboardingWorkflow";
import { useAppDispatch } from "../../redux/hooks";
import { setIdentityOrgId, setOrganizationId } from "../../redux/session.slice";
import { IOrganization } from "../onBoarding.types";

export default function useActivationWorkflow() {
  const dispatch = useAppDispatch();
  const { getProfileFromExternalServiceProviderByOrganization } = useUsersAPI();
  const { getOrganizationFromExternalServiceProviderByTenantId } =
    useOrganizationsAPI();
  const { updateOnboardingStatus } = useOnboardingWorkflow();
  const getOrganizationDetailsByTenantId = async (tenantId: string) => {
    try {
      const organization =
        await getOrganizationFromExternalServiceProviderByTenantId(tenantId);
      return organization;
    } catch (error: any) {
      console.error(
        `The details of the organization ${tenantId} could not be loaded. ${error.message}`
      );
      throw Error("The details of your organization could not be loaded");
    }
  };

  const activateOrganization = async (
    organizationIpId: string,
    organizationDbId: string
  ) => {
    try {
      const userProfile =
        await getProfileFromExternalServiceProviderByOrganization(
          organizationIpId
        );
      if (userProfile.length) {
        await updateOnboardingStatus(
          organizationDbId,
          userProfile[0].email,
          ONBOARDING_STATUS.COMPLETED
        );
      } else {
        throw Error("Your profile information was not returned by Azure");
      }
    } catch (error: any) {
      console.error(
        `Error activating the organization ${organizationIpId}. ${error.message}`
      );
      throw Error(error.message);
    }
  };

  const runActivationWorkflow = async (tenantId: string) => {
    const {
      identityProvider: { organizationId: orgId },
      id,
      activeStatus
    }: IOrganization = await getOrganizationDetailsByTenantId(tenantId);
    if (!id) return ONBOARDING_STATUS.NEW;
    if (activeStatus === ONBOARDING_STATUS.ACTIVATION) {
      dispatch(setOrganizationId(id));
      dispatch(setIdentityOrgId(orgId));
      await activateOrganization(orgId, id);
      return ONBOARDING_STATUS.ACTIVATED;
    }
    return activeStatus;
  };

  return {
    runActivationWorkflow
  };
}
