// eslint-disable-next-line no-shadow
enum SKELETON_VARIANT {
  shortText = "shortText",
  text = "text",
  avatar = "avatar",
  chip = "chip",
  check = "check",
  action = "action",
  input = "input",
  textArea = "textArea",
  select = "select",
  title = "title",
  event = "event",
  checkWithLabel = "checkWithLabel"
}

export default SKELETON_VARIANT;
