import React, { ReactNode } from "react";
import "./navBar.scss";

type NavBarProps = {
  logo?: ReactNode;
  children: ReactNode;
};
export default function NavBar({ logo, children }: NavBarProps) {
  return (
    <nav className="navBarContainer">
      {logo}
      <div className="navBarContainer__actions">{children}</div>
    </nav>
  );
}
