import React from "react";
import "./notFound.scss";
import { useNavigate } from "react-router-dom";
import { URL_ORGANIZATIONS } from "../constants/appUrls";
import { INFORMATION } from "../constants/images";
import Button from "../designSystem/button/button";
import { buildUrl } from "../utils/string.utils";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="not-found-container">
      <div className="not-found-info">
        <img className="not-found-info__image" src={INFORMATION} alt="" />
        <h5 className="not-found-info__title">Error 404</h5>
        <p className="not-found-info__description">
          We cannot find the page you are looking for.
        </p>
        <Button onClick={() => navigate(buildUrl(URL_ORGANIZATIONS))}>
          Go Home
        </Button>
      </div>
    </div>
  );
}
