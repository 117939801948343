import React from "react";
import "./findingSummary.scss";
import { SHAPES } from "../../../designSystem/chip/chip.constants";
import Chip from "../../../designSystem/chip/chip";
import { FindingSummaryUI } from "../../finding.types";
import SkeletonLoader from "../../../designSystem/skeleton/skeleton-loader";

type FindingSummaryProps = {
  summary: FindingSummaryUI;
  totalAffectedObjects: number;
  orgName: string;
  loadingOrganizations: boolean;
  onStatusSelected: (index: number) => void;
};

export default function FindingSummary({
  summary: { severity, dates, history },
  totalAffectedObjects,
  onStatusSelected,
  orgName,
  loadingOrganizations
}: FindingSummaryProps) {
  const summaryClass = "findingSummary";
  const statusHistoryClass = `${summaryClass}__statusHistory`;

  const severityBarClass = `${summaryClass}__severity__bar ${summaryClass}__severity__bar--${severity
    .toString()
    .toLowerCase()}`;

  const onHistoryItemClick = (index: number) => onStatusSelected(index);

  return (
    <div className={summaryClass}>
      <div className="scan__summary__header">
        <h3>Summary</h3>
        <SkeletonLoader loadingVar={loadingOrganizations}>
          <h6>{orgName}</h6>
        </SkeletonLoader>
      </div>
      <div className={`${summaryClass}__affectedSeverity`}>
        <div className={`${summaryClass}__affected`}>
          <label>Affected objects</label>
          <h2>{totalAffectedObjects}</h2>
        </div>
        <div className={`${summaryClass}__severity`}>
          <label>Severity</label>
          <p>{severity}</p>
          <div className={severityBarClass} />
        </div>
      </div>
      <div className={`${summaryClass}__firstSeenLastSeen`}>
        <div className={`${summaryClass}__firstSeen`}>
          <label>First seen</label>
          <p>{dates.firstSeen}</p>
        </div>
        <div className={`${summaryClass}__lastSeen`}>
          <label>Last seen</label>
          <p>{dates.lastSeen}</p>
        </div>
      </div>
      <div className={statusHistoryClass}>
        <label>Status</label>
        <div className={`${statusHistoryClass}__list`}>
          {history.map((item: any, index: number) => {
            let topDividerClass = `${statusHistoryClass}__divider`;
            topDividerClass +=
              index === 0 ? ` ${statusHistoryClass}__divider--hidden` : "";
            let bottomDividerClass = `${statusHistoryClass}__divider`;
            bottomDividerClass +=
              index === History.length - 1
                ? ` ${statusHistoryClass}__divider--hidden`
                : "";

            let bulletClass = `${statusHistoryClass}__bullet`;
            bulletClass += item.active
              ? ` ${statusHistoryClass}__bullet--active`
              : "";

            let dateClass = `${statusHistoryClass}__date`;
            dateClass += item.active
              ? ` ${statusHistoryClass}__date--active`
              : "";

            return (
              <div
                key={index}
                className={`${statusHistoryClass}__item`}
                onClick={() => onHistoryItemClick(index)}
              >
                <div className={`${statusHistoryClass}__timeline`}>
                  <div className={topDividerClass} />
                  <div className={bulletClass} />
                  <div className={bottomDividerClass} />
                </div>
                <Chip
                  label={<label>{item.status}</label>}
                  status={item.status.toLowerCase()}
                  shape={SHAPES.OVAL}
                />
                <div className={dateClass}>{item.date}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
