import "./onboardingInvalid.scss";
import React from "react";
import useUrlQuery from "../query/urlQuery";
import useAuth0Authentication from "../authentication/useAuth0Authentication";
import Onboarding from "./onboarding";
import OnboardingContent from "./onboardingContent";
import OnboardingFooter from "./onboardingFooter";
import OnboardingStageResult from "./onboardingStageResult";
import STATUS from "../designSystem/status";
import Button from "../designSystem/button/button";

export default function OnboardingInvalid() {
  const { getParameter } = useUrlQuery();
  const stage = getParameter("stage");

  const { login } = useAuth0Authentication();

  const onClickLogin = () => login();

  return (
    <Onboarding>
      <OnboardingContent detailsCentered footer={<OnboardingFooter />}>
        <OnboardingStageResult
          title="Error validating subscription"
          status={STATUS.warning}
        >
          <p>Your organization has the invalid subscription status {stage}</p>
          <div className="onboardingInvalid">
            <Button onClick={onClickLogin}>Log in</Button>
          </div>
        </OnboardingStageResult>
      </OnboardingContent>
    </Onboarding>
  );
}
