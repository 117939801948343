import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  setIdentityOrgId,
  setOnboardingCompleted,
  setOrganizationId,
  setOrganizationName,
  setOrganizationType
} from "../redux/session.slice";
import ONBOARDING_STATUS from "./onboardingStatus";
import useOrganizationsAPI from "../api/useOrganizationsAPI";
import useUsersAPI from "../api/useUsersAPI";
import API_CODES from "../api/apiCodes";
import { AUTH0_APP_KEYS } from "../login/auth0AppKeys";
import SERVICE_PROVIDERS from "../login/serviceProviders";
import { IOrganization } from "./onBoarding.types";
import {
  URL_ACTIVATION_PENDING,
  URL_INVALID_ONBOARDING_STAGE,
  URL_LOGOUT,
  URL_SUBSCRIPTION,
  URL_SUBSCRIPTION_PENDING,
  URL_UNAUTHORIZED
} from "../constants/appUrls";

import useLandingPage from "../hooks/useLandingPage";

export default function useOnboardingWorkflow() {
  const serviceProvider = useAppSelector(
    (state: any) => state.session.serviceProvider
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { addUserToOrganization } = useUsersAPI();

  useLandingPage();

  const {
    getOrganizationByEmail,
    setOrganizationStatus,
    setOrganizationStatusFromExternalProvider
  } = useOrganizationsAPI();

  const continueOnboarding = async (
    email: string,
    stage: ONBOARDING_STATUS
  ) => {
    switch (stage) {
      case ONBOARDING_STATUS.NEW:
        navigate(URL_SUBSCRIPTION);
        return;
      case ONBOARDING_STATUS.SUBSCRIPTION:
        navigate(URL_SUBSCRIPTION_PENDING);
        return;
      case ONBOARDING_STATUS.ACTIVATION:
        navigate(URL_LOGOUT, {
          state: {
            redirectURL: `${AUTH0_APP_KEYS.CALLBACK_URL}${URL_ACTIVATION_PENDING}`
          }
        });
        return;
      default:
        navigate(URL_LOGOUT, {
          state: {
            redirectURL: `${AUTH0_APP_KEYS.CALLBACK_URL}${URL_INVALID_ONBOARDING_STAGE}?stage=${stage}`
          }
        });
    }
  };

  const handleOnboardingComplete = async (email: string, orgId: string) => {
    try {
      await addUserToOrganization(email, orgId);
      dispatch(setOnboardingCompleted());
    } catch (error: any) {
      console.log(
        `The user ${email} could not be added to the organization ${orgId}. ${error}`
      );
      throw Error(`Your user email could not be added to the organization`, {
        cause: error
      });
    }
  };
  const getOrganizationDetailsByEmail = async (email: string) => {
    try {
      const organization = await getOrganizationByEmail(email);
      return organization;
    } catch (error: any) {
      console.error(
        `Error loading the organization of ${email}. ${error.message}. Code ${error.cause}`
      );
      throw Error("The status of your organization could not be loaded", {
        cause: error
      });
    }
  };

  const runOnboardingWorkflow = async (email: string) => {
    try {
      const organization: IOrganization = await getOrganizationDetailsByEmail(
        email
      );
      const identityOrgId =
        organization?.identityProvider?.organizationId || "N/A";
      const stage = organization?.activeStatus || ONBOARDING_STATUS.NEW;
      dispatch(setIdentityOrgId(identityOrgId));
      dispatch(setOrganizationId(organization?.id));
      dispatch(setOrganizationName(organization?.name));
      dispatch(setOrganizationType(organization?.organizationType));
      if (stage === ONBOARDING_STATUS.COMPLETED) {
        await handleOnboardingComplete(email, identityOrgId);
      } else {
        await continueOnboarding(email, stage);
      }
    } catch (error: any) {
      console.log(error);
      const originalError = error.cause;

      if (originalError.cause === API_CODES.UNAUTHORIZED) {
        navigate(URL_LOGOUT, {
          state: {
            redirectURL: `${AUTH0_APP_KEYS.CALLBACK_URL}${URL_UNAUTHORIZED}`
          }
        });
      } else {
        throw Error(error);
      }
    }
  };

  const updateOnboardingStatus = async (
    organizationId: string,
    email: string,
    status: string
  ) => {
    const statusBody = {
      identity_user_email: email,
      name: status,
      organization_id: organizationId
    };
    let statusResponse: any;
    try {
      if (serviceProvider === SERVICE_PROVIDERS.AUTH0) {
        statusResponse = await setOrganizationStatus(statusBody);
      }
      if (serviceProvider === SERVICE_PROVIDERS.AZURE) {
        statusResponse = await setOrganizationStatusFromExternalProvider(
          statusBody
        );
      }
      if (statusResponse?.activeStatus === status) {
        return true;
      }
      console.log(
        `Onboarding status could not be set to ${status}. Response: ${statusResponse}`
      );
      throw Error(`Onboarding status could not be set to ${status}.`, {
        cause: "Invalid status response"
      });
    } catch (error: any) {
      if (!error.cause) {
        console.log(
          `Onboarding status could not be set to ${status}. ${error.message}`
        );
      }
      throw Error(error.message);
    }
  };

  return {
    runOnboardingWorkflow,
    updateOnboardingStatus
  };
}
