/* eslint-disable consistent-return */
import { useCallback } from "react";
import useUsersAPI from "../api/useUsersAPI";

export default function useUserProfileModel() {
  const { getUserData, assignRole } = useUsersAPI();

  const loadProfile = useCallback(
    async (userId: string) => {
      try {
        const info = await getUserData(userId);
        return info;
      } catch (error: any) {
        console.error(
          `Error getting profile information for user ${userId}. ${error}`
        );
      }
    },
    [getUserData]
  );

  const changeRole = useCallback(
    async (userId: string, roleId: string, oldRoleId: string) => {
      try {
        await assignRole(userId, roleId, oldRoleId);
      } catch (error) {
        console.log(`Error changing the role. ${error}`);
      }
    },
    [assignRole]
  );

  return { loadProfile, changeRole };
}
