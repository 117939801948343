import React, { useContext } from "react";
import "../users-list.scss";
import { IInvitationActionsProps } from "../users.types";
import { UsersListContext } from "../users-list";
import VARIANT from "../../designSystem/variant";
import Button from "../../designSystem/button/button";
import { DELETE } from "../../constants/images";
import Tooltip, { tooltipTypes } from "../../designSystem/tooltip/tooltip";

export default function InvitationsActions(props: IInvitationActionsProps) {
  const {
    row: {
      original: {
        id,
        invitee: { email }
      }
    }
  } = props;

  const { showRevokeInvitationModal } = useContext(UsersListContext);

  return (
    <div className="usersActionsContainer">
      <div className="has-tooltip usersActionsContainer__delete">
        <Button
          variant={VARIANT.link}
          onClick={() => showRevokeInvitationModal(id, email)}
          iconColor
        >
          <img src={DELETE} alt="Delete" />
        </Button>
        <Tooltip type={tooltipTypes.info} text="Revoke invitation" right />
      </div>
    </div>
  );
}
