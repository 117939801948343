import React from "react";
import "./subscriptionPending.scss";
import { useNavigate } from "react-router-dom";
import STATUS from "../../designSystem/status";
import Button from "../../designSystem/button/button";
import Onboarding from "../onboarding";
import OnboardingContent from "../onboardingContent";
import OnboardingStageResult from "../onboardingStageResult";
import { URL_LOGOUT } from "../../constants/appUrls";

export default function SubscriptionPending() {
  const navigate = useNavigate();
  const onLogout = () => navigate(URL_LOGOUT);

  return (
    <Onboarding>
      <OnboardingContent detailsCentered>
        <OnboardingStageResult
          title="Subscribe organization"
          status={STATUS.warning}
          step="Step 1 pending"
        >
          <section className="subscriptionPending">
            <p>There was an error during your subscription process</p>
            <p>
              Please <a href="mailto:inspect@soteria.io">contact us</a> to
              complete your subscription
            </p>
            <Button onClick={onLogout}>Try again</Button>
          </section>
        </OnboardingStageResult>
      </OnboardingContent>
    </Onboarding>
  );
}
