import React from "react";
import "./avatar.scss";
import WRONG_DATA_INITIALS from "../../constants/default.strings.constants";

type IProps = {
  active?: boolean;
  disabled?: boolean;
  text?: string;
  onClick?: any;
  onMouseOver?: any;
  onMouseOut?: any;
  secondary?: boolean;
  image?: string;
};

export default function Avatar(props: IProps) {
  const {
    active,
    disabled,
    text,
    onClick,
    onMouseOver,
    onMouseOut,
    secondary,
    image
  } = props;

  const containerClassRoot = "avatarContainer";
  let containerClassName = containerClassRoot;
  containerClassName += disabled
    ? " avatarContainer--disabled"
    : " avatarContainer--enabled";
  // eslint-disable-next-line no-nested-ternary
  containerClassName += text
    ? active
      ? " avatarContainer--active"
      : ` avatarContainer--default${secondary ? "-secondary" : ""}`
    : "";

  const onClickContainer = () => !disabled && onClick && onClick();

  const onMouseOverContainer = () => !disabled && onMouseOver && onMouseOver();

  const onMouseOutContainer = () => !disabled && onMouseOut && onMouseOut();

  return (
    <div
      className={containerClassName}
      onClick={onClickContainer}
      onMouseOver={onMouseOverContainer}
      onMouseOut={onMouseOutContainer}
    >
      {text ? (
        <p>{text}</p>
      ) : image ? (
        <img className="avatar__image" src={image} alt="" />
      ) : (
        <p>{WRONG_DATA_INITIALS}</p>
      )}
    </div>
  );
}
