import React, { useContext } from "react";
import "../users-list.scss";
import { DELETE, DIVIDER, EDIT } from "../../constants/images";
import Button from "../../designSystem/button/button";
import Tooltip, { tooltipTypes } from "../../designSystem/tooltip/tooltip";
import { IUsersActionsProps } from "../users.types";
import VARIANT from "../../designSystem/variant";
import { UsersListContext } from "../users-list";
import { useAppSelector } from "../../redux/hooks";

export default function UsersActions(props: IUsersActionsProps) {
  const {
    row: {
      original: { userId, name }
    }
  } = props;

  const { onClickCell, showDeleteUserModal } = useContext(UsersListContext);
  const loggedUserId = useAppSelector((state: any) => state.session.userId);

  return (
    <div className="usersActionsContainer">
      <div className="has-tooltip usersActionsContainer__edit">
        <Button variant={VARIANT.link} onClick={() => onClickCell(userId)}>
          <img src={EDIT} alt="Edit" />
        </Button>
        <Tooltip type={tooltipTypes.info} text="Edit" right />
      </div>
      {loggedUserId !== userId && (
        <>
          <img
            className="usersActionsContainer__divider"
            src={DIVIDER}
            alt=""
          />
          <div className="has-tooltip usersActionsContainer__delete">
            <Button
              variant={VARIANT.link}
              onClick={() => showDeleteUserModal(userId, name)}
              iconColor
            >
              <img src={DELETE} alt="Delete" />
            </Button>
            <Tooltip type={tooltipTypes.info} text="Delete" right />
          </div>
        </>
      )}
    </div>
  );
}
