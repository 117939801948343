import { ACTIVATION_GENERIC_ERROR } from "../onboarding/activation/activationErrors";
import {
  AUTH_ERROR,
  AZURE_AUTH_ERROR_CODES,
  AZURE_AUTH_GENERIC_ERROR_MESSAGES
} from "../onboarding/activation/azureAuthErrorCodes";
import ONBOARDING_STATUS from "../onboarding/onboardingStatus";

export type ErrorDetails = {
  code: number;
  error: number;
  message: string;
  stage: string;
};

export const getGenericErrorDetails = (
  error: Error,
  stage: ONBOARDING_STATUS
): ErrorDetails => {
  const errorMessage = error?.message.toLowerCase() || "";
  const details: ErrorDetails = {
    code: AZURE_AUTH_ERROR_CODES.UNKNOWN,
    error: AUTH_ERROR.UNKNOWN_ERROR,
    message: "Unknown error",
    stage
  };

  if (errorMessage === AZURE_AUTH_GENERIC_ERROR_MESSAGES.ACCESS_DENIED) {
    details.code = AZURE_AUTH_ERROR_CODES.ACCESS_DENIED;
    details.error = AUTH_ERROR.ACCESS_DENIED;
    details.message = error.message;
  }

  if (errorMessage === AZURE_AUTH_GENERIC_ERROR_MESSAGES.FORBIDDEN) {
    details.code = AZURE_AUTH_ERROR_CODES.FORBIDDEN;
    details.error = AUTH_ERROR.FORBIDDEN;
    details.message = error.message;
  }
  if (errorMessage === ACTIVATION_GENERIC_ERROR.INVITATION_LINK_ERROR) {
    details.code = 0;
    details.error = AUTH_ERROR.INVITATION_ERROR;
    details.message = error.message;
  }

  return details;
};
