import { findingListMapper } from "../finding/finding.mappers";
import { IFinding } from "../finding/finding.types";
import formatDate from "../utils/formatDate";
import { IScan, IScanSummary } from "./scan.types";

export const summaryMapper = (summary?: IScanSummary): IScanSummary => ({
  findings: summary?.findings || 0,
  inspectors: summary?.inspectors || 0,
  totalIgnoredInspectors: summary?.totalIgnoredInspectors || 0,
  severity: {
    critical: summary?.severity?.critical || 0,
    high: summary?.severity?.high || 0,
    informational: summary?.severity?.informational || 0,
    medium: summary?.severity?.medium || 0,
    low: summary?.severity?.low || 0
  },
  status: {
    new: summary?.status?.new || 0,
    unchanged: summary?.status?.unchanged || 0,
    modified: summary?.status?.modified || 0,
    remediated: summary?.status?.remediated || 0
  }
});

export const scanDataMapper = (data?: IScan): IScan => ({
  createdAt: formatDate((data?.createdAt as number) || 0),
  id: data?.id || "",
  summary: summaryMapper(data?.summary),
  tenantId: data?.tenantId || "",
  findings: findingListMapper(data?.findings as IFinding[], data?.id)
});

export default summaryMapper;
