import React, { useState } from "react";
import "./office365Activation.scss";
import OnboardingContent from "../../onboardingContent";
import Onboarding from "../../onboarding";
import Button from "../../../designSystem/button/button";
import useMSAuthentication from "../../../authentication/useMSAuthentication";
import VARIANT from "../../../designSystem/variant";
import Office365Permissions from "./office365Permissions";
import { LOADING } from "../../../constants/images";

function Office365ActivationFooter() {
  const { logIn } = useMSAuthentication();
  const [loginInProgress, setLoginInProgress] = useState(false);

  const onClickAgree = () => {
    setLoginInProgress(true);
    logIn();
  };

  return (
    <div className="office365Activation__footer">
      <div className="office365Activation__status">
        {loginInProgress && (
          <>
            <img src={LOADING} alt="" />
            <p>Login in progress...</p>
          </>
        )}
      </div>
      <Button onClick={onClickAgree} disabled={loginInProgress} autoFocus>
        Activate
      </Button>
    </div>
  );
}

export default function Office365Activation() {
  const adminPermissionsLink =
    "https://learn.microsoft.com/en-us/azure/active-directory/develop/application-consent-experience#app-requires-a-permission-that-the-user-has-the-right-to-grant";

  const [showPermissions, setShowPermission] = useState(false);

  const onClickPermissions = () => setShowPermission(true);

  const onClosePermission = () => setShowPermission(false);

  return (
    <Onboarding>
      <OnboardingContent
        header={<h3>Soteria Inspect activation</h3>}
        footer={<Office365ActivationFooter />}
      >
        <section className="office365Activation">
          <p>
            <strong>Step 1: Completed</strong>
          </p>
          <p>
            <strong>Subscription Confirmed</strong>
          </p>
          <br />
          <p>
            <strong>Step 2: Action Required</strong>
          </p>
          <p>
            <strong>Subscription Activation</strong>
          </p>
          <br />
          <p>
            To activate your subscription, please have a Microsoft 365
            administrator grant the required <span> </span>
            <Button onClick={onClickPermissions} variant={VARIANT.link}>
              permissions
            </Button>
            <span> </span>
            by clicking the consent instructions provided by{" "}
            <span>
              {" "}
              <a href={adminPermissionsLink} target="_blank" rel="noreferrer">
                Microsoft
              </a>
            </span>
            .
          </p>
          <br />
          <p>
            If you do not have the required administrator permissions, please
            contact an administrator in your organization to complete this
            subscription activation step.
          </p>
          <br />
          <p>
            This action is required before Soteria Inspect for Microsoft 365 can
            begin scanning your Microsoft 365 tenant.
          </p>
          {showPermissions && (
            <Office365Permissions onClose={onClosePermission} />
          )}
        </section>
      </OnboardingContent>
    </Onboarding>
  );
}
