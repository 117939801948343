import { useNavigate } from "react-router-dom";
import LogRocket from "logrocket";
import {
  AZURE_AUTH_ERROR_CODES,
  AUTH_ERROR,
  ERROR_CODE_REGEX
} from "../onboarding/activation/azureAuthErrorCodes";
import ONBOARDING_STATUS from "../onboarding/onboardingStatus";
import { URL_LOGIN_ERROR } from "../constants/appUrls";
import { ErrorDetails, getGenericErrorDetails } from "./getGenericErrorDetails";

export default function useLoginError() {
  const navigate = useNavigate();

  const getErrorFromCode = (code: number) => {
    if (code === AZURE_AUTH_ERROR_CODES.CONSENT_DECLINED) {
      return AUTH_ERROR.CONSENT_DECLINED;
    }
    if (AZURE_AUTH_ERROR_CODES.PERMISSIONS_ERROR.includes(code)) {
      return AUTH_ERROR.PERMISSIONS_ERROR;
    }
    return AUTH_ERROR.UNKNOWN_ERROR;
  };

  const routeToErrorPage = (error: ErrorDetails) => {
    navigate(URL_LOGIN_ERROR, {
      state: {
        ...error
      }
    });
  };

  const getAzureADErrorDetails = (
    error: ErrorDetails,
    stage: ONBOARDING_STATUS
  ): ErrorDetails => {
    const details: ErrorDetails = { code: 0, error: 0, message: "", stage: "" };
    details.code = Number(error.code);
    details.error = getErrorFromCode(Number(error.code));
    details.message = error.message;
    details.stage = stage;
    return details;
  };

  const getErrorDetails = (error: Error, stage: ONBOARDING_STATUS) => {
    let details: ErrorDetails;

    const errorMatch = error.message.match(ERROR_CODE_REGEX);
    if (errorMatch) {
      details = getAzureADErrorDetails(errorMatch.groups as any, stage);
    } else {
      details = getGenericErrorDetails(error, stage);
    }

    return details;
  };

  const handle = (error: Error, stage: ONBOARDING_STATUS) => {
    LogRocket.captureMessage(`error on login: ${error.toString()}`, {
      tags: {
        subscription: "Azure login"
      },
      extra: {
        stage
      }
    });
    const errorDetails = getErrorDetails(error, stage);
    routeToErrorPage(errorDetails);
  };

  return handle;
}
