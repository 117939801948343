import React from "react";
import { permissionsGuard } from "./functions/permissions.guard";
import Guard from "./guards.types";
import usePermissions from "../permissions/usePermissions";
import PERMISSIONS_LIST from "../permissions/permissions";
import useLastScanGuard from "./functions/lastScan.guard";
import useProfileGuard from "./functions/profile.guard";

const useGuard = (guards: Guard[], component: React.JSX.Element) => {
  const canI = usePermissions();
  const lastScanGuard = useLastScanGuard();
  const profileGuard = useProfileGuard();

  const guardsList: any = {
    MSPPages: () =>
      permissionsGuard(canI(PERMISSIONS_LIST.seeMSPPages), component),
    users: () => permissionsGuard(canI(PERMISSIONS_LIST.seeUsers), component),
    lastScan: () => permissionsGuard(true, lastScanGuard || component),
    profile: () => permissionsGuard(profileGuard, component),
    settings: () =>
      permissionsGuard(canI(PERMISSIONS_LIST.seeSettings), component),
    inspectors: () =>
      permissionsGuard(canI(PERMISSIONS_LIST.seeInspectors), component)
  };

  let finalComponent: React.JSX.Element = component;

  guards.forEach((guard: Guard) => {
    finalComponent = guardsList[guard]();
  });

  return finalComponent;
};

export default useGuard;
