import React from "react";
import {
  URL_PROFILE,
  URL_USERS_DETAILS,
  URL_USERS_MSP_DETAILS
} from "../../constants/appUrls";
import { buildUrl } from "../../utils/string.utils";
import { TableColumnProps } from "../../designSystem/table/tableTypes";
import UsersActions from "./usersActions";
import Avatar from "../../designSystem/avatar/avatar";

export default class UsersListTableFactory {
  public static onClickCell = (
    isMSP: boolean,
    rowId: string,
    userId: string,
    orgId: string
  ) => {
    let url = "";
    const params = [];

    if (rowId === userId) {
      url = URL_PROFILE;
    } else {
      url = isMSP ? URL_USERS_MSP_DETAILS : URL_USERS_DETAILS;
      isMSP && params.push(orgId);
      params.push(rowId);
    }

    return buildUrl(url, ...params);
  };

  public static buildCell(
    row: any,
    value: any,
    isMSP: boolean,
    userId: string,
    orgId: string
  ) {
    return (
      <a
        onClick={(e) => e.preventDefault()}
        target="_blank"
        href={this.onClickCell(isMSP, row.original.id, userId, orgId)}
        rel="noreferrer"
        className="no-decoration"
      >
        {value}
      </a>
    );
  }

  public static getColumns(
    isMSP: boolean,
    userId: string,
    orgId: string
  ): TableColumnProps[] {
    return [
      {
        header: "Name",
        accessorKey: "name",
        cell: ({ row, getValue }: any) =>
          this.buildCell(
            row,
            <div className="userNameColumn">
              <Avatar image={row.original.picture} />
              <span>{getValue()}</span>
            </div>,
            isMSP,
            userId,
            orgId
          )
      },
      {
        header: "Email",
        accessorKey: "email",
        cell: ({ row, getValue }) =>
          this.buildCell(row, getValue(), isMSP, userId, orgId)
      },
      {
        header: "Role",
        accessorKey: "roles",
        cell: ({ row, getValue }) =>
          this.buildCell(row, getValue()[0]?.name || "", isMSP, userId, orgId)
      },
      {
        header: "",
        accessorKey: "actions",
        cell: ({ row }) => <UsersActions row={row} />
      }
    ];
  }
}
