import React from "react";
import { Row, RowData, HeaderGroup } from "@tanstack/react-table";
import TableRow from "./tableRow";
import { tableAdditionalClassNames } from "./table";

type TableBodyProps = {
  tableRows: (Row<any> | HeaderGroup<RowData>)[];
  additionalClassnames?: tableAdditionalClassNames;
};

export default function TableBody({
  tableRows,
  additionalClassnames = {}
}: TableBodyProps) {
  return (
    <tbody>
      {tableRows.map((row) => (
        <TableRow
          key={row.id}
          row={row}
          additionalClassnames={additionalClassnames}
        />
      ))}
    </tbody>
  );
}
