import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import "./subscription.scss";
import { useNavigate } from "react-router-dom";
import { HEIGHT } from "../../constants/dimensions";
import { AUTH0_APP_KEYS } from "../../login/auth0AppKeys";
import Button from "../../designSystem/button/button";
import VARIANT from "../../designSystem/variant";
import { useAppSelector } from "../../redux/hooks";
import Onboarding from "../onboarding";
import OnboardingContent from "../onboardingContent";
import useSubscription from "./useSubscription";
import useSubscriptionWorkflow from "./useSubscriptionWorkflow";
import { LOADING, WARNING_CIRCLE } from "../../constants/images";
import {
  URL_LOGOUT,
  URL_SUBSCRIPTION_CONFIRMED
} from "../../constants/appUrls";

import type { IProfile } from "../../profile/profile.types";

export interface ISubscriptionContext {
  profileLoaded: boolean;
  onProfileLoaded?: () => void;
}

export const SubscriptionContext = createContext<ISubscriptionContext | null>(
  null
);

function SubscriptionHeader() {
  return (
    <>
      <h3>Confirm subscription</h3>
      <p>Please confirm the following information</p>
    </>
  );
}

function getPhoneNumber({ mobilePhone, businessPhones }: IProfile): string {
  return mobilePhone || (businessPhones && businessPhones[0]) || "";
}

function SubscriptionDetails() {
  const { tenant, profile } = useSubscription();
  const phone = getPhoneNumber(profile || {});

  return (
    <section className="subscriptionDetails">
      <section className="subscriptionDetails__tenant">
        <h5>Tenant information</h5>
        <div className="subscriptionDetails__tenant__details">
          <div className="subscriptionDetails__tenant__id">
            <label>Tenant id</label>
            <p>{tenant?.id}</p>
          </div>
          <div className="subscriptionDetails__tenant__organization">
            <label>Organization</label>
            <p>{tenant?.displayName}</p>
          </div>
        </div>
      </section>
      <section className="subscriptionDetails__user">
        <h5 className="subscriptionDetails__user__title">
          Personal information
        </h5>
        <section className="subscriptionDetails__user__information">
          <section className="subscriptionDetails__user__firstName">
            <label>First name</label>
            <p>{profile?.givenName}</p>
          </section>
          <section className="subscriptionDetails__user__lastName">
            <label>Last name</label>
            <p>{profile?.surname}</p>
          </section>
          <section className="subscriptionDetails__user__phoneNumber">
            <label>Phone number</label>
            <p>
              <a className="noDecoration" href={`tel:${phone}`}>
                {phone}
              </a>
            </p>
          </section>
          <section className="subscriptionDetails__user__email">
            <label>Email</label>
            <p>
              <a className="noDecoration" href={`mailto:${profile?.mail}`}>
                {profile?.mail}
              </a>
            </p>
          </section>
        </section>
      </section>
    </section>
  );
}

function SubscriptionFooter() {
  const navigate = useNavigate();
  const subscribeOrganization = useSubscriptionWorkflow();
  const { profileLoaded } = useContext(
    SubscriptionContext
  ) as ISubscriptionContext;
  const [statusImage, setStatusImage] = useState<string>(LOADING);
  const [statusText, setStatusText] = useState("Loading information...");
  const statusImageClassRoot = "subscriptionFooter__status__image";
  const [statusImageClassName, setStatusImageClassName] = useState(
    `${statusImageClassRoot} ${statusImageClassRoot}--inProgress`
  );
  const [subscriptionInProgress, setSubscriptionInProgress] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const email = useAppSelector((state: any) => state.session.email);
  const tenant = useAppSelector((state: any) => state.session.tenant);

  useEffect(() => {
    if (profileLoaded) {
      setDisableConfirm(false);
      setSubscriptionInProgress(false);
      setStatusText("");
    }
  }, [profileLoaded]);
  const onConfirm = async () => {
    try {
      setSubscriptionInProgress(true);
      setDisableConfirm(true);
      setStatusImageClassName(
        `${statusImageClassRoot} ${statusImageClassRoot}--inProgress`
      );
      setStatusImage(LOADING);
      setStatusText("Subscription in progress...");
      await subscribeOrganization(tenant, email);
      navigate(URL_LOGOUT, {
        state: {
          redirectURL: `${AUTH0_APP_KEYS.CALLBACK_URL}${URL_SUBSCRIPTION_CONFIRMED}?email=${email}`
        }
      });
    } catch (error: any) {
      setStatusImageClassName(`${statusImageClassRoot}`);
      setStatusImage(WARNING_CIRCLE);
      setStatusText(error.message);
      setDisableConfirm(false);
      console.error(
        `Error subscribing organization ${tenant?.id}. ${error.message}`
      );
    }
  };

  const onCancel = () => navigate(URL_LOGOUT);

  return (
    <div className="subscriptionFooter">
      {subscriptionInProgress && (
        <div className="subscriptionFooter__status">
          <img className={statusImageClassName} src={statusImage} alt="" />
          <p>{statusText}</p>
        </div>
      )}
      <div className="subscriptionFooter__cta">
        <Button
          onClick={onConfirm}
          disabled={disableConfirm}
          variant={VARIANT.primary}
          autoFocus
        >
          Confirm
        </Button>
        <Button variant={VARIANT.secondary} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default function Subscription() {
  const [profileLoaded, setProfileLoaded] = useState(false);
  const onProfileLoaded = useCallback(() => setProfileLoaded(true), []);

  const returnedValue = useMemo(
    () => ({ profileLoaded, onProfileLoaded }),
    [onProfileLoaded, profileLoaded]
  );

  return (
    <SubscriptionContext.Provider value={returnedValue}>
      <Onboarding>
        <OnboardingContent
          header={<SubscriptionHeader />}
          detailsHeight={HEIGHT.MAX}
          footer={<SubscriptionFooter />}
        >
          <SubscriptionDetails />
        </OnboardingContent>
      </Onboarding>
    </SubscriptionContext.Provider>
  );
}
