/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import "./inspectorManagment.scss";

export default function ResumeInspectorForm({
  formik: { handleSubmit },
  name
}: any) {
  return (
    <form
      id="resumeInspectorForm"
      className="inspector-managment inspector-managment-resume"
      onSubmit={handleSubmit}
    >
      <div className="inspector-managment__information">
        <p className="inspector-managment__information-text">
          Are you sure you want to resume{" "}
          <span className="label--bolder">{name}</span> inspector?
        </p>
        <p className="inspector-managment__information-subtitle">
          This setting will only affect future scans.
        </p>
      </div>
    </form>
  );
}
