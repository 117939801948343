// eslint-disable-next-line no-shadow
enum Guard {
  MSPPages = "MSPPages",
  lastScan = "lastScan",
  users = "users",
  profile = "profile",
  settings = "settings",
  inspectors = "inspectors"
}

export default Guard;
