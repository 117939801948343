import React from "react";
import "./skeleton.scss";
import Skeleton from "./skeleton";
import SKELETON_VARIANT from "./skeleton.constants";

type ISkeletonLoaderProps = {
  loadingVar: boolean;
  variant?: SKELETON_VARIANT;
  children?: any;
  indent?: boolean;
  marginTop?: boolean;
};

export default function SkeletonLoader({
  loadingVar = false,
  variant = SKELETON_VARIANT.shortText,
  indent = false,
  marginTop = false,
  children
}: ISkeletonLoaderProps) {
  return loadingVar ? (
    <div
      className={`skeleton-${variant}${indent ? " indent" : ""}${
        marginTop ? " margin-top" : ""
      }`}
    >
      <Skeleton variant={variant} />
    </div>
  ) : (
    children
  );
}
