import ONBOARDING_STATUS from "../onboardingStatus";
import useUsersAPI from "../../api/useUsersAPI";
import useOrganizationsAPI from "../../api/useOrganizationsAPI";
import { setIdentityOrgId, setOrganizationId } from "../../redux/session.slice";
import { useAppDispatch } from "../../redux/hooks";
import useOnboardingWorkflow from "../useOnboardingWorkflow";

export default function useSubscriptionWorkflow() {
  const dispatch = useAppDispatch();

  const { addUserToOrganization } = useUsersAPI();
  const { addOrganizationByExternalProvider } = useOrganizationsAPI();

  const { updateOnboardingStatus } = useOnboardingWorkflow();

  const subscribeOrganization = async (tenant: any, email: string) => {
    const organization = {
      id: tenant.id,
      displayName: tenant.displayName,
      identity_user_email: email
    };
    let orgSPId = "";
    let organizationResponse: any;

    try {
      organizationResponse = await addOrganizationByExternalProvider(
        organization
      );
      if (!organizationResponse) {
        throw Error("No organization data was returned");
      }
    } catch (error: any) {
      console.log(
        `Error creating the organization ${tenant.id} - ${tenant.displayName}. ${error.message}`
      );
      throw Error(
        `The organization ${tenant.displayName} could not be created during the subscription step`
      );
    }

    if (organizationResponse) {
      try {
        orgSPId = organizationResponse.identityProvider.organizationId;
        dispatch(setOrganizationId(organizationResponse.id));
        dispatch(setIdentityOrgId(orgSPId));
        await updateOnboardingStatus(
          organizationResponse.id,
          email,
          ONBOARDING_STATUS.ACTIVATION
        );
      } catch (error: any) {
        console.log(
          `Error starting the subscription of the organization with user ${email}. ${error.message}`
        );
        throw Error(
          `The subscription of your organization could not be started`
        );
      }
      try {
        await addUserToOrganization(email, orgSPId);
      } catch (error: any) {
        console.log(
          `Error adding the user ${email} to the organization ${orgSPId}`
        );
        throw Error(
          `Error adding your user to the organization ${tenant.displayName}`
        );
      }
    }
  };

  return subscribeOrganization;
}
