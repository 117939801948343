import { useMemo } from "react";
import exportToCSV from "../../../utils/exportToCSV";
import {
  FINDING_STATUS,
  AffectedObject,
  AffectedObjectUI
} from "../../finding.types";

export default function useFindingDetailsViewModel(
  affectedObjects: AffectedObject[],
  status: FINDING_STATUS
) {
  const affectedList: AffectedObjectUI[] = useMemo(
    () =>
      affectedObjects.map((item) => {
        if (status === FINDING_STATUS.modified) {
          return item;
        }
        return {
          name: item.name
        };
      }),
    [affectedObjects, status]
  );

  const affectedSummary = useMemo(
    () =>
      affectedObjects.reduce((allObjects: any, object: any) => {
        const count = allObjects[object.status] ?? 0;
        return {
          ...allObjects,
          [object.status]: count + 1
        };
      }, {}),
    [affectedObjects]
  );

  const getAffectedSummary = (_affectedObjects: AffectedObject[]) => {
    const summary = _affectedObjects.reduce((allObjects: any, object: any) => {
      const count = allObjects[object.Status] ?? 0;
      return {
        ...allObjects,
        [object.Status]: count + 1
      };
    }, {});
    return summary;
  };

  const getAffectedList = (_affectedObjects: AffectedObject[]) => {
    const list = _affectedObjects.map((item) => {
      if (status === FINDING_STATUS.modified) {
        return item;
      }
      return {
        Name: item.name
      };
    });
    return list;
  };

  const handleDownloadAffectedObjects = () => {
    let downloadData: any[];

    if (status === FINDING_STATUS.modified) {
      const details = affectedObjects.map((object) => [
        object.name,
        object.status
      ]);
      downloadData = [["Name", "Status"]].concat(details);
    } else {
      const details = affectedObjects.map((object) => [object.name]);
      downloadData = [["Name"]].concat(details);
    }

    exportToCSV(downloadData, "affectedObjects.csv");
  };

  return {
    affectedSummary,
    affectedList,
    handleDownloadAffectedObjects,
    getAffectedSummary,
    getAffectedList
  };
}
