import React from "react";
import { TableColumnProps } from "../../designSystem/table/tableTypes";
import formatDate, { dateFormats } from "../../utils/formatDate";
import InvitationsActions from "./invitationsActions";

export const INVITATION_STATUS: any = Object.freeze({
  pending: "Pending",
  expired: "Expired"
});

export default class InvitationsListTableFactory {
  public static getColumns(): TableColumnProps[] {
    return [
      {
        header: "Email",
        accessorKey: "invitee.email",
        cell: ({ getValue }: any) => getValue()
      },
      {
        header: "Created by",
        accessorKey: "inviter.name",
        cell: ({ getValue }) => getValue()
      },
      {
        header: "Created",
        accessorKey: "createdAt",
        cell: (data) =>
          data.getValue() ? formatDate(data.getValue(), dateFormats.iso) : ""
      },
      {
        header: "Expires",
        accessorKey: "expiresAt",
        cell: (data) =>
          data.getValue() ? formatDate(data.getValue(), dateFormats.iso) : ""
      },
      {
        header: "",
        accessorKey: "actions",
        cell: ({ row }) => <InvitationsActions row={row} />
      }
    ];
  }
}
