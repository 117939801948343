import React from "react";
import "./authorize.scss";
import { useNavigate } from "react-router-dom";
import Onboarding from "../onboarding/onboarding";
import OnboardingContent from "../onboarding/onboardingContent";
import OnboardingStageResult from "../onboarding/onboardingStageResult";
import Button from "../designSystem/button/button";
import useAuthorize from "./authorize.view.model";

export default function Authorize() {
  const navigate = useNavigate();
  const { status, message, showLoginButton } = useAuthorize();
  return (
    <Onboarding>
      <OnboardingContent detailsCentered>
        <OnboardingStageResult title="Invitation validation" status={status}>
          <div className="authorize__container">
            <p>{message}</p>
            {showLoginButton && (
              <Button onClick={() => navigate("/")}>Login</Button>
            )}
          </div>
        </OnboardingStageResult>
      </OnboardingContent>
    </Onboarding>
  );
}
