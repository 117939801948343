import React, { ReactNode } from "react";
import "./button.scss";
import VARIANT from "../variant";
import Loader from "../loader/loader";

type ButtonProps = {
  autoFocus?: boolean;
  variant?: VARIANT;
  formId?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  children?: ReactNode;
  active?: boolean;
  iconColor?: boolean;
};

export default function Button(props: ButtonProps) {
  const { primary, secondary } = VARIANT;

  const {
    autoFocus,
    variant = primary,
    formId,
    onClick,
    disabled,
    loading,
    children,
    active,
    iconColor
  } = props;

  const classNameRoot = "button";

  let className = `${classNameRoot} ${classNameRoot}--${variant}${
    iconColor ? ` ${classNameRoot}--iconColor` : ""
  }`;

  className +=
    variant === primary || variant === secondary
      ? ` ${classNameRoot}--default`
      : "";
  className += active ? ` active` : "";
  className += loading ? ` loading` : "";

  const type = formId ? "submit" : classNameRoot;

  return (
    <button
      className={className}
      type={type}
      form={formId}
      onClick={onClick}
      disabled={disabled || loading}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    >
      {children}
      {loading && <Loader />}
    </button>
  );
}
