/* eslint-disable no-shadow */
// Azure Auth codes taken from https://learn.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
export const AZURE_AUTH_ERROR_CODES = Object.freeze({
  CONSENT_DECLINED: 65004,
  PERMISSIONS_ERROR: [50105, 65001, 90094],
  // FORBIDDEN and ACCESS_DENIED are returned by Azure AD when the user tries to log in with an account that requires admin approval
  // and returns to the application without granting consent. Reported in INE-263.
  // FORBIDDEN is returned when login with Auth0, ACCESS_DENIED
  // when login with MSAL
  ACCESS_DENIED: 0,
  FORBIDDEN: 1,
  UNKNOWN: 2
});
export const enum AUTH_ERROR {
  ACCESS_DENIED,
  FORBIDDEN,
  UNKNOWN_ERROR,
  CONSENT_DECLINED,
  PERMISSIONS_ERROR,
  INVITATION_ERROR
}

export const enum AZURE_AUTH_GENERIC_ERROR_MESSAGES {
  ACCESS_DENIED = "access_denied",
  FORBIDDEN = "forbidden"
}

export const ERROR_CODE_REGEX = /AADSTS(?<code>[0-9]+): (?<message>.+)/;
