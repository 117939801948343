import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { URL_ORGANIZATIONS, URL_PROFILE, URL_SCAN } from "../constants/appUrls";
import { ORG_TYPES, ROLES } from "../constants/roles";

/**
 * @description This hook will be in charge of redirect user to default landing page depending on bussiness logic
 * once role, user, and organization info is ready in redux state
 * @returns {null}
 */
export default function useLandingPage() {
  const navigate = useNavigate();
  const { role, onboardingCompleted, organizationType, organizationsList } =
    useAppSelector((state) => state.session);
  useEffect(() => {
    if (role && onboardingCompleted && organizationType) {
      const hasChildOrganization =
        Object.keys(organizationsList || {}).length > 0;
      // default to profile
      let landinPageUrl = URL_PROFILE;
      // user is admin, organization is provider
      if (role === ROLES.Admin && organizationType === ORG_TYPES.provider) {
        landinPageUrl = hasChildOrganization ? URL_ORGANIZATIONS : URL_SCAN;
      } else if (
        // user is admin and org is client
        role === ROLES.Admin &&
        organizationType === ORG_TYPES.client
      ) {
        landinPageUrl = URL_SCAN;
      } else if (role === ROLES.User) {
        // user is User
        landinPageUrl = URL_PROFILE;
      }
      navigate(landinPageUrl);
    }
  }, [
    role,
    onboardingCompleted,
    organizationType,
    organizationsList,
    navigate
  ]);
}
