import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import STATUS from "../designSystem/status";
import { INVITATION_ROLE_FLAG } from "../constants/localStorage";

export default function useAuthorize() {
  const [result, setResult] = useState({
    status: STATUS.inProgress,
    message: "Validating the invitation link...",
    showLoginButton: false
  });

  const query = new URLSearchParams(useLocation().search);

  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const invitation = query.get("invitation");
    const organization = query.get("organization");
    if (invitation && organization) {
      localStorage.setItem(INVITATION_ROLE_FLAG, "true");
      loginWithRedirect({
        authorizationParams: {
          invitation,
          organization
        }
      });
    } else {
      setResult({
        status: STATUS.warning,
        message: "Invalid invitation link",
        showLoginButton: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return result;
}
