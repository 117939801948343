import { useCallback, useEffect, useRef, useState } from "react";
import {
  logIn,
  setAccessToken,
  setServiceProvider,
  setTenant
} from "../../redux/session.slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SERVICE_PROVIDERS from "../../login/serviceProviders";
import ONBOARDING_STATUS from "../onboardingStatus";
import useMSAuthentication from "../../authentication/useMSAuthentication";
import useLoginError from "../../login/useLoginError";
import useActivationWorkflow from "./useActivationWorkflow";

// eslint-disable-next-line no-shadow
export enum ACTIVATION_STATUS {
  new,
  subscription,
  activation,
  activated,
  completed,
  error
}

type ActivationStatus = {
  status: ACTIVATION_STATUS;
  details: string;
};

export default function useActivationModel() {
  const { AZURE } = SERVICE_PROVIDERS;
  const { runActivationWorkflow } = useActivationWorkflow();
  const handleError = useLoginError();
  const { getTokens, isAuthenticated, tenantId } =
    useMSAuthentication(handleError);
  const loggedIn = useAppSelector((state: any) => state.session.loggedIn);
  const dispatch = useAppDispatch();

  const [activationStatus, setActivationStatus] = useState<ActivationStatus>({
    status: ACTIVATION_STATUS.activation,
    details: ""
  });

  const [errorLoadingState, setErrorLoadingState] = useState<boolean>(false);
  const loadingTokenInProgress = useRef<boolean>(false);
  const workflowRunning = useRef<boolean>(false);

  useEffect(() => {
    if (loadingTokenInProgress.current) {
      return;
    }
    if (loggedIn) {
      return;
    }
    if (isAuthenticated && tenantId) {
      loadingTokenInProgress.current = true;
      dispatch(setServiceProvider(AZURE));
      getTokens()
        .then((token) => {
          const tenant = {
            id: tenantId
          };
          dispatch(setAccessToken(token));
          dispatch(setTenant(tenant));
          loadingTokenInProgress.current = false;
          dispatch(logIn());
        })
        .catch((error) => {
          setErrorLoadingState(true);
          loadingTokenInProgress.current = false;
          console.error(
            `Error getting tokens from MS Azure during activation. ${error}`
          );
        });
    }
  }, [AZURE, dispatch, getTokens, loggedIn, isAuthenticated, tenantId]);

  const handleOnboardingStatus = useCallback((status: ONBOARDING_STATUS) => {
    switch (status) {
      case ONBOARDING_STATUS.NEW:
        setActivationStatus({
          status: ACTIVATION_STATUS.new,
          details: ""
        });
        return;
      case ONBOARDING_STATUS.ACTIVATED:
        setActivationStatus({
          status: ACTIVATION_STATUS.activated,
          details: ""
        });
        return;
      case ONBOARDING_STATUS.COMPLETED:
        setActivationStatus({
          status: ACTIVATION_STATUS.completed,
          details: ""
        });
        return;
      default:
        setActivationStatus({
          status: ACTIVATION_STATUS.error,
          details: `There is an error in your current subscription. Please contact Soteria to validate your subscription. Error ${status}`
        });
    }
  }, []);

  const runWorkflow = useCallback(async () => {
    workflowRunning.current = true;
    try {
      const status = await runActivationWorkflow(tenantId!);
      handleOnboardingStatus(status);
    } catch (error: any) {
      setActivationStatus({
        status: ACTIVATION_STATUS.error,
        details: error.message
      });
    }
  }, [handleOnboardingStatus, runActivationWorkflow, tenantId]);

  useEffect(() => {
    if (activationStatus.status === ACTIVATION_STATUS.new) {
      return;
    }
    if (activationStatus.status === ACTIVATION_STATUS.completed) {
      return;
    }
    if (activationStatus.status === ACTIVATION_STATUS.activated) {
      return;
    }
    if (workflowRunning.current) {
      return;
    }
    if (loggedIn) {
      runWorkflow();
    }
  }, [activationStatus.status, loggedIn, runWorkflow]);

  return {
    activationStatus,
    errorLoadingState
  };
}
