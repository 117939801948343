import React, { useMemo } from "react";
import "./tableRow.scss";
import TableCell from "./tableCell";
import { tableAdditionalClassNames } from "./table";

type TableRowProps = {
  row: any;
  additionalClassnames?: tableAdditionalClassNames;
};

export default function TableRow({ row, additionalClassnames }: TableRowProps) {
  const isSkeletonRow = useMemo(() => Object.hasOwn(row, "headers"), [row]);
  const cells = useMemo(
    () => (isSkeletonRow ? row.headers : row.getVisibleCells()),
    [isSkeletonRow, row]
  );
  const rowClassName = additionalClassnames?.row || "";
  const cellClassName = additionalClassnames?.cell || "";

  return (
    <tr className={`tableRow ${rowClassName}`} key={row.id}>
      {cells.map((cell: any) => (
        <TableCell
          key={cell.id}
          cell={cell}
          rowId={row?.original?.id || row.id}
          isSkeletonCell={isSkeletonRow}
          row={row}
          additionalClasses={cellClassName}
        />
      ))}
    </tr>
  );
}
