import React, { useEffect } from "react";
import Table from "../../../designSystem/table/table";
import InspectorsColumns from "./inspectors-table-factory";
import INSPECTORS_CONSTANTS from "../../inspector.constants";
import "./inspectors-table.scss";
import useInspectorManagment from "../../../scan/components/inspector-managment/useInspectorManagment";

export default function InspectorsTable() {
  const {
    resumeInspector,
    inspectorsData,
    isLoading,
    currentOrgId,
    fetchInspectorData
  } = useInspectorManagment();

  useEffect(() => {
    fetchInspectorData(currentOrgId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrgId]);

  const onClickRow = (__: any, _: any, cell: any) => {
    if (cell?.row?.original) {
      resumeInspector(cell?.row?.original);
    }
  };

  return (
    <section className="inspectors">
      <h5>
        {`${INSPECTORS_CONSTANTS["paused inspectors"]} `}
        {!isLoading &&
          inspectorsData?.length > 0 &&
          (`| ${inspectorsData?.length}` || "")}
      </h5>
      <Table
        isLoading={isLoading}
        columnFilters={[]}
        columns={InspectorsColumns}
        rows={isLoading ? [] : inspectorsData}
        onClickCell={onClickRow}
        additionalClasses={{
          row: "has-tooltip"
        }}
      />
    </section>
  );
}
