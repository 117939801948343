import React from "react";
import "./tenantInformation.scss";
import { useAppSelector } from "../../../redux/hooks";
import SkeletonLoader from "../../../designSystem/skeleton/skeleton-loader";
import SKELETON_VARIANT from "../../../designSystem/skeleton/skeleton.constants";

type ITenantInformationProps = {
  orgName?: string;
  tenantId?: string;
  isLoading?: boolean;
  additionalClassName?: string;
};

export default function TenantInformation({
  orgName,
  tenantId,
  isLoading = false,
  additionalClassName = ""
}: ITenantInformationProps) {
  const tenant = useAppSelector((state: any) => state.session.tenant);

  return (
    <section className={`tenantInformation ${additionalClassName}`}>
      <h4>Microsoft 365</h4>
      <div className="tenantInformation__details">
        <div className="tenantInformation__id">
          <label>Tenant ID</label>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.text}
          >
            <p>{tenantId || tenant?.id}</p>
          </SkeletonLoader>
        </div>
        <div className="tenantInformation__organization">
          <label>Organization</label>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.text}
          >
            <p>{orgName || tenant?.displayName}</p>
          </SkeletonLoader>
        </div>
      </div>
    </section>
  );
}
