import { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AUTH0_APP_KEYS } from "../login/auth0AppKeys";
import { useAppSelector } from "../redux/hooks";

export default function useAuth0Authentication() {
  const {
    error,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user
  } = useAuth0();

  const authenticationComplete = isAuthenticated && !isLoading;
  const tokenInRedux = useAppSelector((state) => state.session.accessToken);
  const [accessToken, setAccessToken] = useState(
    tokenInRedux.length ? tokenInRedux : ""
  );

  const getTokens = async () => {
    try {
      const tokensResponse = await getAccessTokenSilently({
        authorizationParams: {
          redirect_uri: AUTH0_APP_KEYS.CALLBACK_URL
        },
        detailedResponse: true
      });
      return tokensResponse;
    } catch (_error) {
      console.log(`Error getting auth0 tokens. ${_error}`);
      return { access_token: "", id_token: "" };
    }
  };

  const login = () => loginWithRedirect();

  const logOut = (url?: string) => {
    logout({
      logoutParams: {
        returnTo: url || AUTH0_APP_KEYS.CALLBACK_URL
      }
    });
  };

  const tokensLoadingInProgress = useRef(false);

  if (
    authenticationComplete &&
    !accessToken.length &&
    !tokensLoadingInProgress.current
  ) {
    tokensLoadingInProgress.current = true;
    getTokens()
      .then((tokens) => {
        setAccessToken(tokens.access_token);
        tokensLoadingInProgress.current = false;
      })
      .catch((_error) => {
        console.log(`Error getting the tokens from Auth0. ${_error}`);
        tokensLoadingInProgress.current = false;
      });
  }

  return {
    error,
    canStartLogin: !error && !isAuthenticated && !isLoading,
    user,
    accessToken,
    login,
    logOut
  };
}
