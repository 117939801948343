export default function exportToCSV(data: Array<any>, fileName: string) {
  let csvContent = "";

  data.forEach((row) => {
    csvContent += `${row.join(",")}\r\n`;
  });

  const blob = new Blob([csvContent], {
    type: "text/csv;charset=utf-8;"
  });

  const link = document.createElement("a");

  const url = window.URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  link.click();
  window.URL.revokeObjectURL(url);
}
