/* eslint-disable react/no-unstable-nested-components */
import React, { createContext, useMemo } from "react";
import "./users-list.scss";
import Table from "../designSystem/table/table";
import Header from "../designSystem/header/header";
import ActionBar from "../designSystem/actionBar/actionBar";
import Button from "../designSystem/button/button";
import Input from "../designSystem/input/input";
import Icon from "../designSystem/icon/icon";
import { Tabs, Tab } from "../designSystem/tabs/tabs";
import {
  ADD,
  DIVIDER,
  ORGANIZATIONS,
  SEARCH,
  USERS
} from "../constants/images";
import useUsersList from "./users-list.view.model";
import VARIANT from "../designSystem/variant";

export interface IUsersListContext {
  showDeleteUserModal: (id: string, name: string) => void;
  showRevokeInvitationModal: (invitationId: string, invitee: string) => void;
  onClickCell: (rowId: string) => void;
}

export const UsersListContext = createContext<IUsersListContext>(
  {} as IUsersListContext
);

export default function UsersList() {
  const {
    orgName,
    breadcrumb,
    searchText,
    usersList,
    invitationsList,
    currentTab,
    userTableColumns,
    isLoadingUsers,
    invitationsTableColumns,
    isLoadingInvitations,
    urlOrgId,
    loading,
    usersColumnFilters,
    invitationsColumnFilters,
    onSearchChange,
    onShowAddUserModal,
    onClickCell,
    showDeleteUserModal,
    showRevokeInvitationModal
  } = useUsersList();

  const contextValues: IUsersListContext = useMemo(
    () => ({
      showDeleteUserModal,
      showRevokeInvitationModal,
      onClickCell
    }),
    [onClickCell, showDeleteUserModal, showRevokeInvitationModal]
  );

  return (
    <UsersListContext.Provider value={contextValues}>
      <div className="usersContainer">
        <Header
          icon={<Icon image={urlOrgId ? ORGANIZATIONS : USERS} alt="Users" />}
          title={breadcrumb}
          isLoading={!!urlOrgId && loading}
          subTitle={urlOrgId ? "" : orgName}
          isLoadingSubtitle={!urlOrgId && loading}
        />
        <section className="usersContainer__actionBar">
          <ActionBar>
            <>
              <Input
                icon={SEARCH}
                placeholder="Search"
                onChange={onSearchChange}
                value={searchText}
              />
              <img
                className="usersContainer__actionBarContainer__divider"
                src={DIVIDER}
                alt=""
              />
              <Button
                disabled={loading || isLoadingUsers}
                onClick={() =>
                  onShowAddUserModal(
                    !usersList.length && !invitationsList.length
                  )
                }
                variant={VARIANT.primary}
              >
                <img src={ADD} alt="Create" />
              </Button>
            </>
          </ActionBar>
        </section>
        <div className="usersInfo">
          <section className="usersList">
            <Tabs currentTab={currentTab}>
              <Tab title="Users">
                <Table
                  columns={userTableColumns}
                  columnFilters={usersColumnFilters}
                  rows={usersList}
                  onClickCell={onClickCell}
                  isLoading={isLoadingUsers || loading}
                  globalFilter={searchText}
                />
              </Tab>
              <Tab title={`Invitations (${invitationsList.length || 0})`}>
                <Table
                  columns={invitationsTableColumns}
                  columnFilters={invitationsColumnFilters}
                  rows={invitationsList}
                  isLoading={isLoadingInvitations || loading}
                  globalFilter={searchText}
                />
              </Tab>
            </Tabs>
          </section>
        </div>
      </div>
    </UsersListContext.Provider>
  );
}
