import React from "react";
import "./activation.scss";
import Onboarding from "../onboarding";
import OnboardingContent from "../onboardingContent";
import OnboardingFooter from "../onboardingFooter";
import Button from "../../designSystem/button/button";
import OnboardingStageResult from "../onboardingStageResult";
import useActivationViewModel from "./useActivationViewModel";

export default function Activation() {
  const {
    buttonText,
    text,
    showButton,
    status,
    step,
    title,
    handleClickButton
  } = useActivationViewModel();
  const onClickButton = () => handleClickButton();

  return (
    <Onboarding>
      <OnboardingContent detailsCentered footer={<OnboardingFooter />}>
        <OnboardingStageResult step={step} title={title} status={status}>
          <div className="activation">
            {text.map((_text: string, index: number) => (
              <p key={index}>{_text}</p>
            ))}
            {showButton && (
              <Button onClick={onClickButton} autoFocus>
                {buttonText}
              </Button>
            )}
          </div>
        </OnboardingStageResult>
      </OnboardingContent>
    </Onboarding>
  );
}
