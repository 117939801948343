import React, { ReactNode } from "react";
import "./onboardingStageResult.scss";
import STATUS from "../designSystem/status";
import { CHECK, LOADING, WARNING_CIRCLE } from "../constants/images";

type OnboardingStageResultProps = {
  children?: ReactNode;
  title?: string;
  status: STATUS; // The status of the org within the current stage of the onboarding workflow
  step?: string; // A text above the title that indicates what step the org is within the onboarding workflow
};

export default function OnboardingStageResult(
  props: OnboardingStageResultProps
) {
  const { children, title, status, step } = props;

  const getIcon = () => {
    const icon: { [index: string]: any } = {
      [STATUS.success]: CHECK,
      [STATUS.warning]: WARNING_CIRCLE,
      [STATUS.inProgress]: LOADING
    };
    return icon[status];
  };

  const getAltText = () => {
    const alt: { [index: string]: string } = {
      [STATUS.success]: "Success",
      [STATUS.warning]: "Warning",
      [STATUS.inProgress]: ""
    };
    return alt[status];
  };

  const image = getIcon();
  const altText = getAltText();
  let iconClassName = `onboardingStage__icon`;
  iconClassName +=
    status === STATUS.inProgress ? ` onboardingStage__icon--${status}` : "";

  const stepClassName = `onboardingStage__step onboardingStage__step--${status}`;
  const titleClassName = `onboardingStage__title onboardingStage__title--${status}`;

  return (
    <section className="onboardingStage">
      {status && <img className={iconClassName} src={image} alt={altText} />}
      {step && <h3 className={stepClassName}>{step}</h3>}
      {title && <h2 className={titleClassName}>{title}</h2>}
      {children && (
        <section className="onboardingStage__info">{children}</section>
      )}
    </section>
  );
}
