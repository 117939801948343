import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import { INSPECTORS, ORGANIZATIONS } from "../constants/images";
import Header from "../designSystem/header/header";
import Icon from "../designSystem/icon/icon";
import useInspectorManagment from "../scan/components/inspector-managment/useInspectorManagment";

import InspectorsTable from "./components/inspectorsTable/inspectors-table";
import { IFinding } from "../finding/finding.types";

export interface IInspectorsTableContext {
  pauseInspector?: (finding: IFinding) => void;
  resumeInspector?: (finding: IFinding) => void;
}

export const InspectorsTableContext = createContext<IInspectorsTableContext>(
  {} as IInspectorsTableContext
);

export default function Inspectors() {
  const { breadcrumb } = useInspectorManagment();
  const { orgId } = useParams();
  return (
    <>
      <Header
        icon={<Icon image={orgId ? ORGANIZATIONS : INSPECTORS} alt="Users" />}
        title={breadcrumb}
      />
      <InspectorsTable />
    </>
  );
}
