import React, { useContext, useEffect } from "react";
import "./office365Permissions.scss";
import ModalContext from "../../../contexts/modal.context";

type Office365PermissionsProps = {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
};

export default function Office365Permissions({
  onClose
}: Office365PermissionsProps) {
  const { showModal } = useContext(ModalContext);
  const permissions = [
    "Read all devices",
    "Read tabs in Microsoft Teams.",
    "Read Delegated Admin relationships with customers",
    "Read all users' authentication methods",
    "Read all users' teamwork activity feed",
    "Read all Azure AD synchronization data.",
    "Read all authentication context information",
    "Read printers",
    "Read organizational contacts",
    "Read installed Teams apps for all chats",
    "Read consent and permission grant policies",
    "Read online meeting artifacts",
    "Read SharePoint and OneDrive tenant settings",
    "Read the names, descriptions, and settings of all channels",
    "Read your organization’s security events",
    "Manage all delegated permission grants",
    "Read all recordings of online meetings.",
    "Read all identity risky service principal information",
    "Read all shared cross-tenant user profiles and export their data",
    "Read calendars in all mailboxes",
    "Read basic mail in all mailboxes",
    "Read privileged access to Azure AD roles",
    "Read all directory RBAC settings",
    "Read the names and descriptions of all channels",
    "Read all users' relevant people lists",
    "Read all security alerts",
    "Read all groups",
    "Read all administrative units",
    "Read all custom authentication extensions",
    "Read all user mailbox settings",
    "Read cross-tenant basic information",
    "Read all of the organization's threat submissions",
    "Read all lifecycle workflows resources",
    "Read Education app settings",
    "Read items in all site collections",
    "Read print jobs",
    "Read Microsoft Intune configuration",
    "Read service messages",
    "Read tenant-wide print settings",
    "Read all Azure AD recommendations",
    "Read all OneNote notebooks",
    "Read all entitlement management resources",
    "Read all call records",
    "Read all identity user flows",
    "Read all chat messages",
    "Read directory data",
    "Read all admin report settings",
    "Read all consent requests",
    "Read role management data for all RBAC providers",
    "Read PSTN and direct routing call log data",
    "Read privileged access to Azure resources",
    "Read all users' full profiles",
    "Read domains",
    "Read all class assignments without grades",
    "Read a limited subset of the organization's roster",
    "Read all terms of use agreements",
    "Read all transcripts of online meetings.",
    "Read Records Management configuration, labels and policies",
    "Read the members of all channels",
    "Read all alert data for your company's directory",
    "Read all schedule items",
    "Read all security incidents",
    "Read all group memberships",
    "Read Microsoft Intune RBAC settings",
    "Read Cloud PC RBAC settings",
    "Read files in all site collections",
    "Read all configurations for protecting organizational data applicable to users",
    "Read custom security attribute assignments",
    "Read your organization's search configuration",
    "Read Microsoft Intune device configuration and policies",
    "Get a list of all teams",
    "Read API connectors for authentication flows",
    "Read mail in all mailboxes",
    "Read all chat messages",
    "Read all external items",
    "Read all channel messages",
    "Read all class assignments with grades",
    "Read your organization's security actions",
    "Read threat assessment requests",
    "Read identity providers",
    "Read all teams' settings",
    "Read all identity risky user information",
    "Read all access reviews",
    "Manage all license assignments"
  ];

  useEffect(
    () =>
      showModal({
        title: "Office365 permissions",
        clickAction: onClose,
        closeAction: onClose,
        actionText: "Close",
        content: (
          <div className="office365Permissions">
            <p>
              Below is a list of permissions required for Soteria Inspect for
              Microsoft 365 to operate. All permissions are read only except
              where a read only permission does not exist.
            </p>
            <p />
            <ul>
              {permissions.map((permission, index) => (
                <li key={index}>{permission}</li>
              ))}
            </ul>
          </div>
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return null;
}
