import React from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../../designSystem/menu/menu";
import Tooltip from "../../designSystem/tooltip/tooltip";
import {
  URL_INSPECTORS,
  URL_INSPECTORS_MSP,
  URL_SCAN,
  URL_SCAN_MSP,
  URL_USERS,
  URL_USERS_MSP,
  URL_SETTINGS,
  URL_SETTINGS_MSP
} from "../../constants/appUrls";
import { MORE } from "../../constants/images";
import { buildUrl } from "../../utils/string.utils";

type OrganizationListActionMenuProps = {
  orgId: string;
  row: any;
};

export default function OrganizationListActionMenu({
  orgId,
  row: { id, lastScan }
}: OrganizationListActionMenuProps) {
  const navigate = useNavigate();
  const menuItems = [
    {
      id: orgId === id ? URL_SCAN : URL_SCAN_MSP,
      value: "Scan",
      hide: !lastScan?.id
    },
    {
      id: orgId === id ? URL_USERS : URL_USERS_MSP,
      value: "Users"
    },
    {
      id: orgId === id ? URL_INSPECTORS : URL_INSPECTORS_MSP,
      value: "Inspectors"
    },
    {
      id: orgId === id ? URL_SETTINGS : URL_SETTINGS_MSP,
      value: "Settings"
    }
  ];
  return (
    <div className="organizationsContextMenuContainer">
      <Menu
        items={menuItems}
        clickAction={(url: string) => navigate(buildUrl(url, id))}
        left
      >
        <div className="organizationsContextMenuContainer__menu has-tooltip">
          <img
            className="organizationsContextMenuContainer__icon"
            src={MORE}
            alt="Menu"
          />
          <Tooltip text="More" />
        </div>
      </Menu>
    </div>
  );
}
