import React, { ReactNode } from "react";
import "./onboardingContent.scss";

type OnboardingContentProps = {
  children?: ReactNode;
  detailsCentered?: boolean;
  detailsHeight?: string;
  footer?: ReactNode;
  header?: ReactNode;
};

export default function OnboardingContent(props: OnboardingContentProps) {
  const { children, detailsCentered, detailsHeight, footer, header } = props;
  const contentClassRoot = "onboardingContent";
  const contentClassName = `${contentClassRoot} ${
    detailsCentered ? `${contentClassRoot}--center` : ""
  }`;

  const detailsClassRoot = "onboardingContent__details";
  let detailsClassName = detailsClassRoot;
  detailsClassName += detailsCentered
    ? ` ${detailsClassRoot}--center`
    : ` ${detailsClassRoot}--top`;
  detailsClassName += detailsHeight
    ? ` ${detailsClassRoot}--height${detailsHeight}`
    : "";

  return (
    <section className={contentClassName}>
      {header}
      <section className={detailsClassName}>{children}</section>
      <section className="onboardingContent__footer">{footer}</section>
    </section>
  );
}
