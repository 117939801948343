// eslint-disable-next-line no-shadow
export enum dateFormats {
  epoch = "epoch",
  iso = "iso"
}

export default function formatDate(
  inputDate?: number,
  inputFormat: dateFormats = dateFormats.epoch
) {
  const formatsDefinitions = {
    epoch: new Date(inputDate ? inputDate * 1000 : ""),
    iso: new Date(inputDate!)
  };

  const date = formatsDefinitions[inputFormat];

  const day = new Intl.NumberFormat("en-US", {
    minimumIntegerDigits: 2,
    maximumSignificantDigits: 2
  }).format(date.getUTCDate());

  const month = new Intl.NumberFormat("en-US", {
    minimumIntegerDigits: 2,
    maximumSignificantDigits: 2
  }).format(date.getUTCMonth() + 1);

  const year = date.getUTCFullYear();

  return `${year}-${month}-${day}`;
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function substractDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export function substractMinutes(date: Date, minutes = 1): Date {
  return new Date(date.getTime() - 1000 * (minutes * 60));
}

export function getEpoch(date: Date): number {
  return Number((date.getTime() / 1000).toFixed(0));
}
