import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { ISubscriptionContext, SubscriptionContext } from "./subscription";
import useUsersAPI from "../../api/useUsersAPI";
import { IProfile } from "../../profile/profile.types";

export default function useSubscription() {
  const [profile, setProfile] = useState({} as IProfile);
  const profileRef = useRef(false);

  const email = useAppSelector((state: any) => state.session.email);
  const tenant = useAppSelector((state: any) => state.session.tenant);

  const { getProfileFromExternalServiceProviderByEmail } = useUsersAPI();

  const { onProfileLoaded } = useContext(
    SubscriptionContext
  ) as ISubscriptionContext;

  const getProfile = useCallback(async () => {
    const pfl = await getProfileFromExternalServiceProviderByEmail(email);
    setProfile(pfl);
    onProfileLoaded && onProfileLoaded();
  }, [email, getProfileFromExternalServiceProviderByEmail, onProfileLoaded]);

  useEffect(() => {
    if (onProfileLoaded && profile?.email) {
      onProfileLoaded();
    } else if (!profile?.mail && !profileRef.current) {
      profileRef.current = true;
      getProfile();
    }
  }, [getProfile, onProfileLoaded, profile]);

  return {
    profile,
    tenant
  };
}
