import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import STATUS from "../../designSystem/status";
import { useAppSelector } from "../../redux/hooks";
import useActivationModel, { ACTIVATION_STATUS } from "./useActivationModel";
import { URL_LOGOUT, URL_OFFICE_365_ROLES } from "../../constants/appUrls";

export default function useActivationViewModel() {
  const navigate = useNavigate();
  const loggedIn = useAppSelector((state: any) => state.session.loggedIn);

  const { activationStatus, errorLoadingState } = useActivationModel();

  const [step, setStep] = useState<string>("Step 2");
  const [text, setText] = useState<string[]>(
    loggedIn ? ["Please wait..."] : ["Validating your subscription..."]
  );
  const [title, setTitle] = useState<string>(
    loggedIn ? "Activation in progress" : "Activating account"
  );
  const [status, setStatus] = useState<STATUS>(STATUS.inProgress);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>("Grant required roles");

  useEffect(() => {
    if (activationStatus.status === ACTIVATION_STATUS.new) {
      setTitle("Subscription required");
      setText(["Please log out to start your subscription"]);
      setStatus(STATUS.warning);
      setButtonText("Log out");
      setShowButton(true);
    }
    if (activationStatus.status === ACTIVATION_STATUS.activated) {
      setStep("Step 2 completed");
      setTitle("Account activated");
      setText([
        "Soteria account activated",
        "Grant the required roles to finish setup"
      ]);
      setStatus(STATUS.success);
      setShowButton(true);
    }
    if (activationStatus.status === ACTIVATION_STATUS.completed) {
      setStep("Step 2 completed");
      setTitle("Account activated");
      setText(["Your subscription was already activated"]);
      setStatus(STATUS.success);
      setButtonText("Go to my account");
      setShowButton(true);
    }
    if (activationStatus.status === ACTIVATION_STATUS.error) {
      setTitle("Error activating your organization");
      setText([activationStatus.details]);
      setStatus(STATUS.warning);
      setButtonText("Log out");
      setShowButton(true);
    }
  }, [activationStatus.status, activationStatus.details]);

  useEffect(() => {
    if (errorLoadingState) {
      setTitle("Activation failed");
      setText(["Error loading authentication information from Azure"]);
      setStatus(STATUS.warning);
      setButtonText("Log out");
      setShowButton(true);
    }
  }, [errorLoadingState]);

  const handleClickButton = () => {
    if (activationStatus.status === ACTIVATION_STATUS.activated) {
      navigate(URL_OFFICE_365_ROLES);
    } else {
      navigate(URL_LOGOUT);
    }
  };

  return {
    buttonText,
    handleClickButton,
    text,
    showButton,
    status,
    step,
    title
  };
}
