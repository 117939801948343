/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from "react";
import "./addUser.scss";
import { Field } from "formik";
import ModalContext from "../../../contexts/modal.context";
import Input from "../../../designSystem/input/input";
import Select from "../../../designSystem/select/select";
import rolesSelectMapper from "../../users-list.mappers";
import { ROLES } from "../../../constants/roles";

export default function AddUserForm({
  formik: {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    isValid,
    touched
  },
  roles
}: any) {
  const { setActionDisabled } = useContext(ModalContext);
  // temporary dropdown hide, will be restored after
  const [disableRoleDropdown] = useState(true);

  useEffect(() => {
    setActionDisabled(!isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  // temporary dropdown hide, will be restored after
  // set value directly
  useEffect(() => {
    const adminId =
      Object.keys(roles).filter((elmId) => {
        const { name } = roles[elmId];
        return name === ROLES.Admin;
      })[0] || "";
    adminId && setFieldValue("role", adminId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form id="addUserForm" className="addUserForm" onSubmit={handleSubmit}>
      <p>Add new user email to send invite</p>
      <label className="addUserEmailLabel">Email</label>
      <Field
        name="email"
        type="email"
        error={errors.email}
        onChange={handleChange}
        value={values.email}
        touched={touched.email}
        component={Input}
      />
      {!disableRoleDropdown && (
        <>
          <label className="addUserRoleLabel">Role</label>
          <Field
            name="role"
            value={roles[values.role]?.name || ""}
            onChange={(value: string) => setFieldValue("role", value)}
            disabled={!!errors.email}
            items={rolesSelectMapper(roles)}
            component={Select}
          />
        </>
      )}
    </form>
  );
}
