import React from "react";
import "./profile.scss";
import Header from "../designSystem/header/header";
import PersonalInformation from "./components/personalInformation/personalInformation";
import useUserProfile from "./profile.view.model";

export default function Profile() {
  const {
    profile,
    isLoadingProfile,
    loading,
    urlOrgId,
    urlUserId,
    icon,
    breadcrumb,
    loggedInUserRole,
    onRoleChange
  } = useUserProfile();
  return (
    <>
      <Header
        icon={icon}
        title={breadcrumb}
        isLoading={!!urlOrgId && loading}
      />
      <PersonalInformation
        profile={profile}
        isLoadingProfile={isLoadingProfile}
        isLoadingOrganizations={loading}
        loggedInUserRole={loggedInUserRole}
        onRoleChange={onRoleChange}
        isUserProfile={!urlOrgId && !urlUserId}
      />
    </>
  );
}
