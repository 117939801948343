import React, { useContext } from "react";
import "./tableCell.scss";
import { flexRender } from "@tanstack/react-table";
import { ITableContext, TableContext } from "./table";
import SkeletonLoader from "../skeleton/skeleton-loader";

function TdCell({ isSkeletonCell, skeleton, cell }: any) {
  return (
    <SkeletonLoader loadingVar={isSkeletonCell} variant={skeleton}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </SkeletonLoader>
  );
}

export default function TableCell({
  cell,
  rowId,
  isSkeletonCell,
  additionalClasses = ""
}: any) {
  const { onClickCell } = useContext(TableContext) as ITableContext;
  const onClickDefault = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!isSkeletonCell && onClickCell && cell.column.id !== "actions") {
      onClickCell(rowId, cell?.column?.id, cell);
    }
  };
  const {
    onClick,
    CellWrapper,
    skeleton,
    className = ""
  } = cell?.column?.columnDef || {};

  return (
    (CellWrapper && (
      <CellWrapper
        className={`tableCell ${additionalClasses} ${className}`}
        key={cell.id}
        onClick={onClick || onClickDefault}
        cell={cell}
      >
        <TdCell
          isSkeletonCell={isSkeletonCell}
          skeleton={skeleton}
          cell={cell}
        />
      </CellWrapper>
    )) || (
      <td
        aria-label="tableCell"
        className={`tableCell ${additionalClasses} ${className}`}
        key={cell.id}
        onClick={onClick || onClickDefault}
      >
        <TdCell
          isSkeletonCell={isSkeletonCell}
          skeleton={skeleton}
          cell={cell}
        />
      </td>
    )
  );
}
