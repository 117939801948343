import { useEffect } from "react";
import LogRocket from "logrocket";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { loginRequest, logoutRequest } from "./msAuthenticationConfig";
import ONBOARDING_STATUS from "../onboarding/onboardingStatus";

export default function useMSAuthentication(
  errorCallback?: (error: Error, stage: ONBOARDING_STATUS) => void
) {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  let tenantId: string | null = null;

  if (isAuthenticated && accounts.length) {
    tenantId = accounts[0].tenantId;
  }

  useEffect(() => {
    let callbackId: string | null = "";

    if (errorCallback) {
      callbackId = instance.addEventCallback((event: any) => {
        if (event.eventType === EventType.LOGIN_FAILURE) {
          errorCallback(event.error, ONBOARDING_STATUS.ACTIVATION);
        }
      });
    }

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const logIn = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
      LogRocket.captureException(e);
    });
  };

  const logOut = () => instance.logoutRedirect(logoutRequest);

  const getTokens = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      LogRocket.captureException(error as Error);
      const response = await instance.acquireTokenPopup(request);
      return response.accessToken;
    }
  };

  return {
    isAuthenticated,
    tenantId,
    getTokens,
    logIn,
    logOut
  };
}
