import React, { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  SCAN_FREQUENCIES,
  SCAN_FREQUENCIES_CONSTANTS
} from "./components/scan-frequency/scans-frequency.constants";

import FeatureFlagContext from "../contexts/feature-flags.provider";
import ModalContext from "../contexts/modal.context";
import { useAppSelector } from "../redux/hooks";
import OrganizationsContext from "../contexts/organizations.context";

import ChangeScanFrequencyModalInfo from "./components/scan-frequency/change-scan-frecuency-modal-info";

export default function UseSettings() {
  const { feature_scan_Frequency } = useContext(FeatureFlagContext);
  const { showModal, closeModal } = useContext(ModalContext);

  const { organizationsList, loading } = useContext(OrganizationsContext);

  const { orgId } = useParams();

  const { organizationName, organizationId } = useAppSelector(
    (state: any) => state.session
  );

  const orgName = useMemo(
    () => (orgId ? organizationsList[orgId!]?.name : organizationName),
    [orgId, organizationName, organizationsList]
  );

  const tenantId = useMemo(
    () => orgId && organizationsList[orgId]?.externalProvider?.id,
    [orgId, organizationsList]
  );
  const currentOrganizationId = useMemo(
    () => orgId || organizationId,
    [orgId, organizationId]
  );

  const showChangeFrequencyModal = useCallback(
    (frequencyOption: any) =>
      showModal({
        title: `${SCAN_FREQUENCIES_CONSTANTS["change scan frequency"]}`,
        actionText: "Confirm",
        clickAction: () => {
          closeModal();
        },
        content: (
          <ChangeScanFrequencyModalInfo
            frequencyOption={frequencyOption}
            orgName={orgName}
          />
        )
      }),
    [showModal, orgName, closeModal]
  );

  return {
    feature_scan_Frequency,
    scanFrecuenciesOptions: SCAN_FREQUENCIES,
    orgId: currentOrganizationId,
    tenantId,
    orgName,
    loading,
    showChangeFrequencyModal
  };
}
