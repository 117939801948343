import { IInvitation } from "../users/users.types";
import apiResponseFormatter from "../utils/object.utils";
import { apiRequest, useOptions } from "./api";
import API_METHODS from "./apiMethods";

export default function useInvitationsAPI() {
  const { getOptions, setMethod, setBody } = useOptions();
  const { setEndpoint, run } = apiRequest();
  const apiUrl: string = process.env.REACT_APP_API_INVITATIONS!;
  const apiVersion = "";
  const apiPrefix = `${apiUrl}${apiVersion}/invitations`;

  const getInvitationsList = async (orgId: string) => {
    setEndpoint(`${apiPrefix}/organization/${orgId}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const usersList = await run(options);
    return apiResponseFormatter(usersList);
  };

  const revokeInvitation = async (invitationId: string, orgId: string) => {
    setEndpoint(
      `${apiPrefix}/organization/${orgId}/invitation/${invitationId}`
    );
    setMethod(API_METHODS.DELETE);
    const options = getOptions();
    const response = await run(options);
    return apiResponseFormatter(response);
  };

  const sendInvitation = async (invitation: IInvitation) => {
    setEndpoint(apiPrefix);
    setMethod(API_METHODS.POST);
    setBody(invitation);
    const options = getOptions();
    const usersList = await run(options);
    return apiResponseFormatter(usersList);
  };

  return {
    getInvitationsList,
    revokeInvitation,
    sendInvitation
  };
}
