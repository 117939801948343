import React from "react";
import SkeletonLoader from "../../../designSystem/skeleton/skeleton-loader";
import SKELETON_VARIANT from "../../../designSystem/skeleton/skeleton.constants";
import { IscanFrecuencies, FREQUENCY_ENUM } from "./scans-frequency.types";
import { SCAN_FREQUENCIES_CONSTANTS } from "./scans-frequency.constants";

export default function ScansFrequency({
  scanFrecuencies,
  isLoading,
  onSelectedFrequency
}: {
  scanFrecuencies: IscanFrecuencies;
  isLoading: boolean;
  onSelectedFrequency: (key: FREQUENCY_ENUM) => void;
}) {
  return (
    <section className="settings__scan-frequency">
      <h4>{SCAN_FREQUENCIES_CONSTANTS["scan scheduling"]}</h4>
      <div className="settings__scan-frequency_options-holder">
        <div className="settings__scan-frequency__holders">
          <label>{SCAN_FREQUENCIES_CONSTANTS["modify frequency"]}</label>
          {scanFrecuencies.map(({ label, key }, index) => (
            <div className="" key={index}>
              <SkeletonLoader
                loadingVar={isLoading}
                variant={SKELETON_VARIANT.checkWithLabel}
              >
                <input
                  key={`${label}-${index}`}
                  className="settings__scan-frequency__radio"
                  type="radio"
                  name="frequency"
                  value={key}
                  id={key}
                  onChange={() => {
                    onSelectedFrequency(key);
                  }}
                />
                <label
                  className="label settings__scan-frequency__label"
                  htmlFor={key}
                >
                  {label}
                </label>
              </SkeletonLoader>
            </div>
          ))}
        </div>

        <div className="settings__scan-frequency__holders">
          <label>{SCAN_FREQUENCIES_CONSTANTS["scan schedule"]}</label>
          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.text}
          >
            <div className="settings__scan-frequency__holders_information">
              <p>
                <strong>{SCAN_FREQUENCIES_CONSTANTS["last scan"]}</strong>:
                10/15/2024
              </p>
            </div>
          </SkeletonLoader>

          <SkeletonLoader
            loadingVar={isLoading}
            variant={SKELETON_VARIANT.text}
          >
            <div className="settings__scan-frequency__holders_information">
              <p>
                <strong>
                  {SCAN_FREQUENCIES_CONSTANTS["next scan scheduled"]}
                </strong>
                :10/15/2024
              </p>
            </div>
          </SkeletonLoader>
        </div>
      </div>
    </section>
  );
}
