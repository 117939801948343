/* eslint-disable consistent-return */
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import API_CODES from "../api/apiCodes";
import useFindingsAPI from "../api/useFindingsAPI";
import { IScan } from "./scan.types";
import SnackbarContext from "../contexts/snackbar.context";
import { MESSAGE } from "../designSystem/snackbar/snackbar.types";
import { ERROR, WARNING_CIRCLE } from "../constants/images";
import { IOrganization } from "../onboarding/onBoarding.types";
import useOrganizationsAPI from "../api/useOrganizationsAPI";
import { URL_LOGOUT, URL_ORGANIZATIONS } from "../constants/appUrls";

export default function useScanModel() {
  const { showSnackbar } = useContext(SnackbarContext);
  const { FORBIDDEN, UNAUTHORIZED } = API_CODES;
  const navigate = useNavigate();

  const { getLastScanByOrganizationId } = useFindingsAPI();
  const { getOrganizationDataFromExternalServiceProviderByTenantId } =
    useOrganizationsAPI();

  const getLastScan = async (tenantId: string) => {
    try {
      const scan: IScan = await getLastScanByOrganizationId(tenantId);
      if (!scan) {
        showSnackbar({
          text: `No scan data for this Organization.`,
          type: MESSAGE.error,
          icon: WARNING_CIRCLE
        });
        navigate(URL_ORGANIZATIONS);
      }
      return scan;
    } catch (error: any) {
      console.error(
        `Error getting scan data for ${tenantId}. ${error}. Cause ${error.cause}`
      );
      if (error.cause === FORBIDDEN || error.cause === UNAUTHORIZED) {
        navigate(URL_LOGOUT);
      } else {
        showSnackbar({
          text: `Error retrieving scan data. ${error}`,
          type: MESSAGE.error,
          icon: ERROR
        });
      }
      navigate(URL_ORGANIZATIONS);
    }
  };

  const getOrganizationData = async (tenantId: string) => {
    try {
      const organization: IOrganization =
        await getOrganizationDataFromExternalServiceProviderByTenantId(
          tenantId
        );
      return organization;
    } catch (error: any) {
      console.error(
        `Error getting organization data for ${tenantId}. ${error}.`
      );
      showSnackbar({
        text: `Error getting organization data. ${error}`,
        type: MESSAGE.error,
        icon: ERROR
      });
    }
  };

  return {
    getLastScan,
    getOrganizationData
  };
}
