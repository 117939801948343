/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import "./settings.scss";
import { SETTINGS } from "../constants/images";
import Header from "../designSystem/header/header";
import Icon from "../designSystem/icon/icon";
import TenantInformation from "../profile/components/tenantInformation/tenantInformation";
import ScansFrequency from "./components/scan-frequency/scans-frequency";
import useSettings from "./useSettings";
import { SCAN_FREQUENCIES_CONSTANTS } from "./components/scan-frequency/scans-frequency.constants";
import Button from "../designSystem/button/button";
import SkeletonLoader from "../designSystem/skeleton/skeleton-loader";
import SKELETON_VARIANT from "../designSystem/skeleton/skeleton.constants";

export default function Settings() {
  const {
    scanFrecuenciesOptions,
    feature_scan_Frequency,
    loading,
    showChangeFrequencyModal,
    orgId,
    tenantId,
    orgName
  } = useSettings();

  const clickFrequencyHandler = (frequencyOption: any) => {
    console.log(`changes will be applied to ${orgId} - ${orgName}`);
    showChangeFrequencyModal(frequencyOption);
  };
  const clickRequestScan = () => {};

  return (
    <>
      <Header
        icon={<Icon image={SETTINGS} alt="Settings" />}
        title="Settings"
      />
      <TenantInformation
        tenantId={tenantId}
        orgName={orgName}
        additionalClassName="settings__tenant"
      />
      {feature_scan_Frequency && (
        <>
          <ScansFrequency
            scanFrecuencies={scanFrecuenciesOptions}
            onSelectedFrequency={clickFrequencyHandler}
            isLoading={loading}
          />
          <section className="settings__scan-request">
            <h4>{SCAN_FREQUENCIES_CONSTANTS["actions"]}</h4>
            <div className="settings__scan-request-button-holder">
              <label>{SCAN_FREQUENCIES_CONSTANTS["request a scan"]}</label>

              <SkeletonLoader
                loadingVar={loading}
                variant={SKELETON_VARIANT.select}
              >
                <Button onClick={clickRequestScan}>
                  {SCAN_FREQUENCIES_CONSTANTS["request"]}
                </Button>
              </SkeletonLoader>
            </div>
          </section>
        </>
      )}
    </>
  );
}
