import React from "react";
import { Routes, useLocation, Route } from "react-router-dom";
import { REDIRECT_SESSION_STORAGE } from "../constants/localStorage";
import { useAppSelector } from "../redux/hooks";
import { IState } from "../constants/common.types";
import NavigationBar from "../navigationBar/navigationBar";
import {
  NO_NAV_BAR_URLS,
  URL_ACTIVATION,
  URL_ACTIVATION_PENDING,
  URL_AUTHORIZE,
  URL_INSPECTORS,
  URL_FINDING,
  URL_FINDING_MSP,
  URL_INVALID_ONBOARDING_STAGE,
  URL_LOGIN_ERROR,
  URL_LOGOUT,
  URL_OFFICE_365_ACTIVATION,
  URL_OFFICE_365_ROLES,
  URL_ORGANIZATIONS,
  URL_PROFILE,
  URL_ROOT,
  URL_SCAN,
  URL_SCAN_MSP,
  URL_SETTINGS,
  URL_SUBSCRIPTION,
  URL_SUBSCRIPTION_CONFIRMED,
  URL_SUBSCRIPTION_PENDING,
  URL_UNAUTHORIZED,
  URL_USERS,
  URL_USERS_DETAILS,
  URL_USERS_MSP,
  URL_USERS_MSP_DETAILS,
  URL_INSPECTORS_MSP,
  URL_SETTINGS_MSP
} from "../constants/appUrls";
import Activation from "../onboarding/activation/activation";
import Finding from "../finding/finding";
import Logout from "../logout/logout";
import Office365Roles from "../onboarding/activation/office365/office365Roles";
import OnboardingWorkflow from "../onboarding/onboardingWorkflow";
import Subscription from "../onboarding/subscription/subscription";
import SubscriptionPending from "../onboarding/subscription/subscriptionPending";
import Scan from "../scan/scan";
import OrganizationList from "../organization-list/organizations-list";
import Office365Activation from "../onboarding/activation/office365/office365Activation";
import Login from "../login/login";
import ActivationPending from "../onboarding/activation/activationPending";
import LoginError from "../login/loginError";
import SubscriptionConfirmation from "../onboarding/subscription/subscriptionConfirmation";
import Unauthorized from "../unauthorized/unauthorized";
import OnboardingInvalid from "../onboarding/onboardingInvalid";
import Guard from "./guards.types";
import ProtectedUrl from "./ProtectedUrl";
import UsersList from "../users/users-list";
import Profile from "../profile/profile";
import Authorize from "../authorize/authorize";
import NotFound from "../notFound/notFound";
import Settings from "../settings/settings";
import Inspectors from "../inspectors/inspectors";

export default function InspectRouting() {
  const { pathname } = useLocation();

  const { loggedIn } = useAppSelector(({ session }: IState) => session);
  const pendingUrl: string | null = sessionStorage.getItem(
    REDIRECT_SESSION_STORAGE
  );

  const showNavBar = !NO_NAV_BAR_URLS.includes(pathname);

  if (loggedIn && pendingUrl === pathname)
    sessionStorage.removeItem(REDIRECT_SESSION_STORAGE);

  return loggedIn ? (
    <>
      {showNavBar && <NavigationBar />}
      <Routes>
        <Route path={URL_FINDING} element={<Finding />} key="0" />
        <Route
          path={URL_FINDING_MSP}
          element={
            <ProtectedUrl guards={[Guard.MSPPages]}>
              <Finding />
            </ProtectedUrl>
          }
          key="1"
        />
        <Route path={URL_SCAN} element={<Scan />} key="0" />
        <Route
          key="1"
          path={URL_SCAN_MSP}
          element={
            <ProtectedUrl guards={[Guard.lastScan, Guard.MSPPages]}>
              <Scan />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_ORGANIZATIONS}
          element={
            <ProtectedUrl guards={[Guard.MSPPages]}>
              <OrganizationList />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_USERS}
          element={
            <ProtectedUrl guards={[Guard.users]}>
              <UsersList />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_USERS_MSP}
          element={
            <ProtectedUrl guards={[Guard.MSPPages]}>
              <UsersList />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_USERS_DETAILS}
          element={
            <ProtectedUrl guards={[Guard.users, Guard.profile]}>
              <Profile />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_USERS_MSP_DETAILS}
          element={
            <ProtectedUrl guards={[Guard.MSPPages, Guard.profile]}>
              <Profile />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_PROFILE}
          element={
            <ProtectedUrl guards={[Guard.profile]}>
              <Profile />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_SETTINGS}
          element={
            <ProtectedUrl guards={[Guard.settings]}>
              <Settings />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_SETTINGS_MSP}
          element={
            <ProtectedUrl guards={[Guard.settings]}>
              <Settings />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_INSPECTORS}
          element={
            <ProtectedUrl guards={[Guard.inspectors]}>
              <Inspectors />
            </ProtectedUrl>
          }
        />
        <Route
          path={URL_INSPECTORS_MSP}
          element={
            <ProtectedUrl guards={[Guard.inspectors]}>
              <Inspectors />
            </ProtectedUrl>
          }
        />
        <Route path={URL_ROOT} element={<OnboardingWorkflow />} />
        <Route path={URL_ACTIVATION} element={<Activation />} key="1" />
        <Route path={URL_LOGOUT} element={<Logout />} />
        <Route path={URL_OFFICE_365_ROLES} element={<Office365Roles />} />
        <Route path={URL_SUBSCRIPTION} element={<Subscription />} />
        <Route
          path={URL_SUBSCRIPTION_PENDING}
          element={<SubscriptionPending />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  ) : (
    <Routes>
      <Route path={URL_AUTHORIZE} element={<Authorize />} />
      <Route
        path={URL_OFFICE_365_ACTIVATION}
        element={<Office365Activation />}
      />
      <Route path={URL_ROOT} element={<Login />} />
      <Route path={URL_ACTIVATION} element={<Activation />} key="0" />
      <Route path={URL_ACTIVATION_PENDING} element={<ActivationPending />} />
      <Route path={URL_LOGIN_ERROR} element={<LoginError />} />
      <Route path={URL_LOGOUT} element={<Logout />} />
      <Route
        path={URL_SUBSCRIPTION_CONFIRMED}
        element={<SubscriptionConfirmation />}
      />
      <Route path={URL_UNAUTHORIZED} element={<Unauthorized />} />
      <Route
        path={URL_INVALID_ONBOARDING_STAGE}
        element={<OnboardingInvalid />}
      />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}
