import React from "react";
import OnboardingContent from "../onboarding/onboardingContent";
import LoginErrorDetails from "./loginErrorDetails";
import OnboardingFooter from "../onboarding/onboardingFooter";

export default function LoginErrorContent() {
  return (
    <OnboardingContent detailsCentered footer={<OnboardingFooter />}>
      <LoginErrorDetails />
    </OnboardingContent>
  );
}
