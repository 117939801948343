import React, { createContext, useEffect, useMemo, useState } from "react";
import "./table.scss";
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import { INFORMATION } from "../../constants/images";
import { IFinding } from "../../finding/finding.types";

export interface tableAdditionalClassNames {
  [key: string]: string;
}

type TableProps<T> = {
  columns: Array<T>;
  columnFilters?: ColumnFiltersState;
  globalFilter?: string;
  rows: Array<T>;
  isLoading: boolean;
  persistedSorting?: SortingState;
  setPersistedSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
  onClickCell?: (rowId: string, columnId?: string, row?: any) => void;
  additionalClasses?: tableAdditionalClassNames;
};

export interface ITableContext {
  onClickCell?: (rowId: string, columnId?: string, finding?: IFinding) => void;
}

export const TableContext = createContext<ITableContext | null>(null);
export default function Table(props: TableProps<any>) {
  const {
    columnFilters,
    columns,
    globalFilter,
    rows,
    isLoading,
    persistedSorting,
    setPersistedSorting,
    onClickCell,
    additionalClasses
  } = props;

  const [sorting, setSorting] = useState<SortingState>(persistedSorting || []);

  const columnsToHide = useMemo(
    () =>
      columns.reduce(
        (allColumns: any, column: any) => ({
          ...allColumns,
          ...(column.hide && column.hide === true
            ? { [column.accessorKey]: false }
            : {})
        }),
        {}
      ),
    [columns]
  );

  useEffect(() => {
    if (!persistedSorting || !persistedSorting.length) {
      const newSorting: SortingState = [];
      columns.forEach((column: any) => {
        if (Reflect.has(column, "sortDescFirst")) {
          newSorting.push({
            id: column.accessorKey,
            desc: column.sortDescFirst
          });
        }
      });
      setSorting(newSorting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    setPersistedSorting && setPersistedSorting(sorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const table = useReactTable({
    columns,
    data: rows,
    enableColumnFilters: true,
    enableFilters: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnFilters,
      columnVisibility: columnsToHide,
      globalFilter,
      sorting
    },
    enableGlobalFilter: true,
    globalFilterFn: "includesString",
    onSortingChange: setSorting,
    enableSorting: true,
    enableSortingRemoval: false
  });

  const getHeaderGroups = () => {
    const hg = table.getHeaderGroups();
    hg[0].id = "header";
    return hg;
  };

  const headerGroups = getHeaderGroups();
  const tableRows = table.getRowModel().rows;
  return (
    <div className="tableContainer">
      <table className="table">
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <TableContext.Provider value={{ onClickCell }}>
          <TableHeader headerGroups={headerGroups} />
          <TableBody
            tableRows={isLoading ? [...tableRows, ...headerGroups] : tableRows}
            additionalClassnames={additionalClasses}
          />
        </TableContext.Provider>
      </table>
      {!isLoading && !tableRows.length && (
        <div className="no-data-container">
          <img className="no-data-container__image" src={INFORMATION} alt="" />
          <h5 className="no-data-container__title">No data available</h5>
          <p className="no-data-container__description">
            There is no data that matches the selected time range or filters.
          </p>
        </div>
      )}
    </div>
  );
}
