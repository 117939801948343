import React from "react";
import "./personalInformation.scss";
import { IPersonalInformationProps } from "../../profile.types";
import SkeletonLoader from "../../../designSystem/skeleton/skeleton-loader";
import SKELETON_VARIANT from "../../../designSystem/skeleton/skeleton.constants";
import Select from "../../../designSystem/select/select";
import { useAppSelector } from "../../../redux/hooks";
import rolesSelectMapper from "../../../users/users-list.mappers";
import Divider from "../../../designSystem/divider/divider";
import { formatPhoneNumber } from "../../../utils/string.utils";
import { ROLES } from "../../../constants/roles";

export default function PersonalInformation({
  profile: { givenName, familyName, email, phoneNumber, role, mobile },
  isLoadingOrganizations,
  isLoadingProfile,
  loggedInUserRole,
  isUserProfile,
  onRoleChange
}: IPersonalInformationProps) {
  const roles = useAppSelector((state: any) => state.roles);
  return (
    <section className="personalInformation">
      <h4>Personal information</h4>
      <div className="personalInformation__details">
        <div className="personalInformation__firstName">
          <label>First name</label>
          <SkeletonLoader
            loadingVar={isLoadingOrganizations || isLoadingProfile}
            variant={SKELETON_VARIANT.shortText}
            indent
          >
            <p>{givenName}</p>
          </SkeletonLoader>
        </div>
        <div className="personalInformation__lastName">
          <label>Last name</label>
          <SkeletonLoader
            loadingVar={isLoadingOrganizations || isLoadingProfile}
            variant={SKELETON_VARIANT.shortText}
            indent
          >
            <p>{familyName}</p>
          </SkeletonLoader>
        </div>
        <div className="personalInformation__phoneNumber">
          <label>Phone number</label>
          <SkeletonLoader
            loadingVar={isLoadingOrganizations || isLoadingProfile}
            variant={SKELETON_VARIANT.shortText}
            indent
          >
            <p>{phoneNumber}</p>
          </SkeletonLoader>
        </div>
        <div className="personalInformation__mobile">
          <label>Mobile</label>
          <SkeletonLoader
            loadingVar={isLoadingOrganizations || isLoadingProfile}
            variant={SKELETON_VARIANT.shortText}
            indent
          >
            <p>{formatPhoneNumber(mobile)}</p>
          </SkeletonLoader>
        </div>
        <div className="personalInformation__email">
          <label>Email</label>
          <SkeletonLoader
            loadingVar={isLoadingOrganizations || isLoadingProfile}
            variant={SKELETON_VARIANT.shortText}
            indent
          >
            <p>{email}</p>
          </SkeletonLoader>
        </div>
        <div className="personalInformation__divider">
          <Divider />
        </div>
        <div className="personalInformation__role">
          <label>Role</label>
          <SkeletonLoader
            loadingVar={isLoadingOrganizations || isLoadingProfile}
            variant={SKELETON_VARIANT.select}
            indent
          >
            {loggedInUserRole === ROLES.Admin && !isUserProfile ? (
              <Select
                value={role?.name || ""}
                onChange={onRoleChange}
                items={rolesSelectMapper(roles)}
              />
            ) : (
              <p>{role?.name}</p>
            )}
          </SkeletonLoader>
        </div>
      </div>
    </section>
  );
}
