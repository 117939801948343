import React, { ReactNode } from "react";
import "./chip.scss";
import { SHAPES } from "./chip.constants";

type ChipProps = {
  label?: string | ReactNode;
  shape?: string;
  status?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export default function Chip({
  label,
  shape = SHAPES.OVAL,
  status = "informational",
  disabled,
  onClick
}: ChipProps) {
  const classRoot = "chip";

  let className = classRoot;
  className += shape ? ` ${classRoot}--${shape}` : "";
  className += status ? ` ${classRoot}--${status}` : "";
  className += disabled ? ` ${classRoot}--disabled` : "";

  return (
    <div className={className} onClick={onClick}>
      {label}
    </div>
  );
}
