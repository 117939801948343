import React from "react";
import "./onboardingFooter.scss";

export default function OnboardingFooter() {
  return (
    <div className="onboardingFooter">
      <p>
        Need help? <a href="mailto:inspect@soteria.io">Contact us</a>
      </p>
    </div>
  );
}
