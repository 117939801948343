import { useLocation } from "react-router-dom";

export default function useUrlQuery() {
  const instance = new URLSearchParams(useLocation().search);
  const getParameter = (name: string) => instance.get(name);

  return {
    getParameter
  };
}
