import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./select.scss";
import Icon from "../icon/icon";
import Menu, { IMenuItem } from "../menu/menu";
import SkeletonLoader from "../skeleton/skeleton-loader";
import SELECT_VALUES from "./select.constants";
import SKELETON_VARIANT from "../skeleton/skeleton.constants";
import { CHEVRON_DOWN } from "../../constants/images";

type IProps = {
  items: IMenuItem[];
  onChange: any;
  value: string;
  disabled?: boolean;
  iconColor?: boolean;
  isValid?: boolean;
  noBackground?: boolean;
  withSearch?: boolean;
  readOnly?: boolean;
  clearOption?: boolean;
  clearOptionCaption?: string;
  isLoading?: boolean;
  placeholder?: string;
};

export default function Select({
  items,
  onChange,
  value,
  disabled,
  iconColor,
  isValid = true,
  noBackground,
  withSearch,
  readOnly,
  isLoading = false,
  clearOption,
  clearOptionCaption = SELECT_VALUES.CLEAR_VALUE,
  placeholder
}: IProps) {
  const [searchText, setSearchText] = useState(value);

  const handleTextChange: (e: any) => void = useCallback(
    ({ target }) => setSearchText(target.value),
    []
  );

  const onBlur: () => void = useCallback(() => {
    if (withSearch) {
      setSearchText(clearOption ? clearOptionCaption : value);
      if (clearOption) onChange(null);
    }
  }, [clearOption, clearOptionCaption, onChange, value, withSearch]);

  const onFocus: () => void = useCallback(() => setSearchText(""), []);

  const classNames: string = useMemo(
    () =>
      `select-container${
        disabled || !items.length ? " select-container--disabled" : ""
      }${isValid ? "" : " select-container--error"}${
        noBackground ? " select-container--noBackground" : ""
      }`,
    [disabled, isValid, noBackground, items]
  );

  const filteredItems: any = useCallback(
    (itemList: []) =>
      searchText &&
      ((clearOption && searchText !== clearOptionCaption) ||
        (!clearOption && searchText !== SELECT_VALUES.DEFAULT_VALUE))
        ? itemList.filter((item: any) =>
            item.value
              .toString()
              .toUpperCase()
              .includes(searchText.toString().toUpperCase())
          )
        : itemList,
    [searchText, clearOption, clearOptionCaption]
  );

  const getItems: any = useMemo(() => {
    const resultItems: any = [...(withSearch ? filteredItems(items) : items)];
    if (clearOption && resultItems.length)
      resultItems.unshift({
        id: null,
        value: clearOptionCaption
      });
    return resultItems;
  }, [clearOption, clearOptionCaption, filteredItems, items, withSearch]);

  useEffect(() => setSearchText(value), [value]);

  return (
    <SkeletonLoader loadingVar={isLoading} variant={SKELETON_VARIANT.select}>
      {readOnly ? (
        <p>{value}</p>
      ) : (
        <Menu
          items={getItems}
          clickAction={onChange}
          iconColor={iconColor}
          disabled={disabled}
          onClickOutside={onBlur}
          select
          disableTogglerClose={withSearch}
          currentValue={value}
        >
          <section className={classNames}>
            <p className="select-container__selected-option">
              {withSearch ? (
                <input
                  className="select-container__selected-option__search"
                  value={searchText || ""}
                  onChange={handleTextChange}
                  onFocus={onFocus}
                  disabled={disabled}
                />
              ) : !value ? (
                placeholder || ""
              ) : (
                value
              )}
            </p>
            <div className="select-container__chevron">
              <Icon image={CHEVRON_DOWN} alt="v" />
            </div>
          </section>
        </Menu>
      )}
    </SkeletonLoader>
  );
}
