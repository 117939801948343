import React, { createContext } from "react";
import "./scan.scss";
import Header from "../designSystem/header/header";
import Table from "../designSystem/table/table";
import Input from "../designSystem/input/input";
import ActionBar from "../designSystem/actionBar/actionBar";
import useScanViewModel from "./scan.view.model";
import Select from "../designSystem/select/select";
import Button from "../designSystem/button/button";
import VARIANT from "../designSystem/variant";
import { FILTER, SEARCH } from "../constants/images";
import Summary from "./components/scanSummary";
import { IFindingsList } from "../finding/finding.types";
import Tooltip from "../designSystem/tooltip/tooltip";

export interface IScanListContext {
  pauseInspector?: (inspectorProps: any) => void;
  resumeInspector?: (inspectorProps: any) => void;
}

export const ScanListContext = createContext<IScanListContext>(
  {} as IScanListContext
);

export default function Scan() {
  const {
    scanData,
    columnFilters,
    columns,
    searchText,
    severityFilterItems,
    severityFilterValue,
    showFilters,
    loadingScan,
    breadcrumb,
    orgId,
    loading,
    icon,
    isLoading,
    orgName,
    handleFindingSelected,
    handleSearchChange,
    handleFiltersSelected,
    handleSeverityFilterSelected
  } = useScanViewModel();

  const scanDateFormatted = scanData.createdAt;

  return (
    <section className="scan">
      <Header
        icon={icon}
        title={breadcrumb}
        isLoading={!!orgId && loading}
        subTitle={orgId ? "" : (scanDateFormatted as string)}
        isLoadingSubtitle={!orgId && loadingScan}
      />
      <div className="scan__info">
        <Summary
          summary={scanData.summary}
          isLoading={isLoading}
          loadingOrganizations={loading}
          orgName={orgName}
          orgId={orgId}
          date={scanDateFormatted}
        />
        <div className="scan__findings">
          <h5>Findings</h5>
          <div className="scan__actions">
            {showFilters && (
              <div className="scan__filters">
                <label>Severity</label>
                <Select
                  clearOption
                  value={severityFilterValue}
                  items={severityFilterItems}
                  onChange={(id: string | null) =>
                    handleSeverityFilterSelected(id)
                  }
                />
              </div>
            )}
            <ActionBar>
              <Input
                alt="Search"
                icon={SEARCH}
                placeholder="Search"
                value={searchText}
                onChange={(e: any) => handleSearchChange(e.target.value)}
              />
              <div className="has-tooltip">
                <Button
                  onClick={() => handleFiltersSelected()}
                  variant={VARIANT.secondary}
                  active={showFilters}
                >
                  <img src={FILTER} alt="Filters" />
                </Button>
                <Tooltip text="Filters" />
              </div>
            </ActionBar>
          </div>
          <div className="scan__table">
            <Table
              isLoading={isLoading}
              columnFilters={columnFilters}
              columns={columns}
              rows={Object.values(scanData.findings as IFindingsList)}
              globalFilter={searchText}
              onClickCell={handleFindingSelected}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
