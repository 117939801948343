/* eslint-disable react/no-unused-prop-types */
import React, { Fragment } from "react";
import "./breadcrumb.scss";
import { NavLink, useNavigate } from "react-router-dom";
import Menu, { IMenuItem } from "../menu/menu";
import { CHEVRON_DOWN } from "../../constants/images";
import Icon from "../icon/icon";
import SKELETON_VARIANT from "../skeleton/skeleton.constants";
import SkeletonLoader from "../skeleton/skeleton-loader";

type IBreadcrumbItem = {
  content: string | React.JSX.Element;
  link?: string;
  menuItems?: IMenuItem[];
  currentItemId?: string;
  isLoadingItem?: boolean;
};

type IProps = {
  items: IBreadcrumbItem[];
};

export default function Breadcrumb({ items }: IProps) {
  const navigate = useNavigate();

  return (
    <div className="breadcrumb">
      {items.map(
        (
          {
            content,
            link,
            menuItems,
            currentItemId,
            isLoadingItem
          }: IBreadcrumbItem,
          index: number
        ) => {
          const isLastItem = index === items.length - 1;
          return (
            <Fragment key={`holder-${index}`}>
              <div
                className={`breadcrumb__item ${
                  isLastItem && "breadcrumb__item-last"
                }`}
                key={index}
              >
                <NavLink
                  to={link || ""}
                  className={`navLink navLink--${
                    isLastItem ? "active" : "inactive"
                  } ${isLoadingItem ? "navLink--loading" : ""}`}
                >
                  <SkeletonLoader
                    loadingVar={!!isLoadingItem}
                    variant={SKELETON_VARIANT.text}
                  >
                    {content}
                  </SkeletonLoader>
                </NavLink>

                {!isLastItem && (
                  <span className="navLink__separator">{">"}</span>
                )}
              </div>
              {isLastItem && menuItems?.length && (
                <Menu
                  items={menuItems}
                  clickAction={(selectedOption: string) =>
                    navigate(selectedOption)
                  }
                  fontSize="15px"
                  key="menu"
                  currentValue={currentItemId}
                >
                  <div className="navLink__menu">
                    <Icon image={CHEVRON_DOWN} alt="v" />
                  </div>
                </Menu>
              )}
            </Fragment>
          );
        }
      )}
    </div>
  );
}
