import React from "react";
import "./office365Roles.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../../designSystem/button/button";
import Onboarding from "../../onboarding";
import OnboardingContent from "../../onboardingContent";
import { URL_LOGOUT } from "../../../constants/appUrls";

export default function Office365Roles() {
  const navigate = useNavigate();

  const onClickFinish = () => navigate(URL_LOGOUT);

  return (
    <Onboarding>
      <OnboardingContent
        header={<h3>Granting Required Application Permissions</h3>}
        detailsCentered={false}
        footer={
          <Button onClick={onClickFinish} autoFocus>
            Finish
          </Button>
        }
      >
        <section className="office365AgreementDetails">
          <p />
          <h5>Required Roles</h5>
          <p />
          <ul>
            <li>
              <strong>Global Administrator</strong>
            </li>
            <li>
              <strong>SharePoint Administrator</strong>
            </li>
            <li>
              <strong>Exchange Administrator</strong>
            </li>
            <li>
              <strong>Teams Administrator</strong>
            </li>
          </ul>
          <p />
          <p>
            The Global Administrator, Exchange Administrator, and SharePoint
            Administrator roles provide the required permissions for any task in
            Exchange Online PowerShell. For example:
          </p>
          <ul>
            <li>Recipient management</li>
            <li>
              Security and protection features. (Anti-spam, anti-malware,
              anti-phishing, and the associated reports)
            </li>
          </ul>
          <p />
          <p>
            The Global Administrator role is required to grant the necessary
            permission scopes for Microsoft Graph and Microsoft Intune. These
            modules request administrator consent to the scopes each time the
            queries run. The Security Administrator role does not have the
            necessary permissions for those same tasks.
          </p>
          <p />
          <h5>Grant the Required Roles</h5>
          <p />
          <p>
            In order for the newly created application to leverage Exchange App
            Only Authentication functionality, Microsoft requires that the
            service principal be granted the Exchange Administrator role.
          </p>
          <ol>
            <li>
              Navigate to the Microsoft Entra/Azure Active Directory blade -{" "}
              <a
                href="https://entra.microsoft.com"
                target="_blank"
                rel="noreferrer"
              >
                https://entra.microsoft.com
              </a>
            </li>
            <li>
              Click <strong>“Azure Active Directory”</strong>
            </li>
            <li>
              Click <strong>“Show more”</strong>
            </li>
            <li>
              Navigate to <strong>"Roles & admins"</strong>
            </li>
            <li>
              Search for and select <strong>“Exchange Administrator”</strong>
            </li>
            <li>
              Click <strong>"+ Add assignments"</strong>
            </li>
            <li>
              Click <strong>“No member selected”</strong>
            </li>
            <li>
              Search for the application name{" "}
              <strong>“Soteria Inspect for Microsoft365”</strong> and select the
              service principal.
            </li>
            <li>
              Click <strong>Select</strong>
            </li>
            <li>
              Click <strong>Next</strong> (or <strong>Add</strong>)
            </li>
            <li>
              <p>
                Note: The following steps 11 and 12 will be necessary to
                complete if your license includes Privileged Identity
                Management.
              </p>
              <p>
                Ensure that the <strong>Assignment type</strong> is{" "}
                <strong>Active</strong> and ensure the{" "}
                <strong>Permanently assigned</strong> box remains checked.
              </p>
            </li>
            <li>
              Enter a justification and click <strong>Assign</strong>
            </li>
            <li>
              Repeat steps 4 through 12 for the Teams Administrator, Global
              Administrator, and SharePoint Administrator roles.
            </li>
          </ol>
          <p />
        </section>
      </OnboardingContent>
    </Onboarding>
  );
}
