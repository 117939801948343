import { IPauseInspectorsRequestParams } from "../scan/components/inspector-managment/useInspectorManagment";
import apiResponseFormatter from "../utils/object.utils";
import { apiRequest, useOptions } from "./api";
import API_METHODS from "./apiMethods";

export default function useInspectorsAPI() {
  const { getOptions, setMethod, setBody } = useOptions();
  const { setEndpoint, run } = apiRequest();
  const apiInspectorsUrl: string = process.env.REACT_APP_API_INSPECTORS!;
  const apiVersion = "";
  const apiPrefix = `${apiInspectorsUrl}${apiVersion}/inspectors`;
  const apiOrgInspectorUrl: string = process.env.REACT_APP_API_ORG!;
  const apiOrgPrefix = `${apiOrgInspectorUrl}/inspectors/organization`;

  const getInspectorById = async (inspectorId: string) => {
    setEndpoint(`${apiPrefix}/${inspectorId}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const inspector = await run(options);
    return apiResponseFormatter(inspector);
  };
  const getInspectorsByOrganizationIdAndActiveStatus = async (
    orgId: string,
    isActive = true
  ) => {
    setEndpoint(`${apiOrgPrefix}/${orgId}/active/${isActive}`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const inspector = await run(options);
    return apiResponseFormatter(inspector);
  };

  const getInspectorsByOrganizationId = async (
    orgId: string,
    inspectorId: string
  ) => {
    setEndpoint(`${apiOrgPrefix}/${orgId}/inspector/${inspectorId}/status`);
    setMethod(API_METHODS.GET);
    const options = getOptions();
    const inspector = await run(options);
    return apiResponseFormatter(inspector);
  };

  const pauseInspectorByOrganization = async (
    params: IPauseInspectorsRequestParams,
    orgId: number
  ) => {
    setEndpoint(`${apiOrgPrefix}/${orgId}`);
    setMethod(API_METHODS.PATCH);
    setBody(params);
    const options = getOptions();
    const inspector = await run(options);
    return apiResponseFormatter(inspector);
  };

  const resumeInspectorByOrganization = async (
    params: IPauseInspectorsRequestParams,
    orgId: number
  ) => {
    setEndpoint(`${apiOrgPrefix}/${orgId}`);
    setMethod(API_METHODS.PATCH);
    setBody(params);
    const options = getOptions();
    const inspector = await run(options);
    return apiResponseFormatter(inspector);
  };

  return {
    getInspectorById,
    getInspectorsByOrganizationIdAndActiveStatus,
    pauseInspectorByOrganization,
    resumeInspectorByOrganization,
    getInspectorsByOrganizationId
  };
}
