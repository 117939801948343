import React, { useCallback, useEffect, useRef, useState } from "react";
import LogRocket from "logrocket";
import "./onboardingWorflow.scss";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import OnboardingContent from "./onboardingContent";
import OnboardingStageResult from "./onboardingStageResult";
import Button from "../designSystem/button/button";
import Onboarding from "./onboarding";
import STATUS from "../designSystem/status";
import useOnboardingWorkflow from "./useOnboardingWorkflow";
import { URL_LOGOUT } from "../constants/appUrls";

export default function OnboardingWorkflow() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("Login in progress...");
  const [text, setText] = useState("");
  const [status, setStatus] = useState(STATUS.inProgress);
  const [showErrorSection, setShowErrorSection] = useState(false);
  const workflowRunning = useRef(false);
  const { runOnboardingWorkflow } = useOnboardingWorkflow();
  const email = useAppSelector((state) => state.session.email);

  const onClickLogout = () => navigate(URL_LOGOUT);

  const runWorkflow = useCallback(() => {
    workflowRunning.current = true;
    setTitle("Subscription status");
    setText("Validating your subscription...");
    console.log("Starting subscription workflow...");
    runOnboardingWorkflow(email)
      .then(() => console.log("Subscription workflow finished..."))
      .catch((error) => {
        console.error(`Error running onboarding workflow. ${error.message}`);
        setTitle("Error validating subscription");
        LogRocket.captureException(error as Error);
        setText(error.message);
        setStatus(STATUS.warning);
        setShowErrorSection(true);
      });
  }, [email, runOnboardingWorkflow]);

  useEffect(() => {
    if (!workflowRunning.current) {
      runWorkflow();
    }
  }, [runWorkflow]);

  return (
    <Onboarding>
      <OnboardingContent detailsCentered>
        <OnboardingStageResult title={title} status={status}>
          {text}
          {showErrorSection && (
            <div className="onboardingWorkflow">
              <p>
                Please <a href="mailto:inspect@soteria.io">contact us</a> to
                validate your subscription
              </p>
              <Button onClick={onClickLogout}>Log out</Button>
            </div>
          )}
        </OnboardingStageResult>
      </OnboardingContent>
    </Onboarding>
  );
}
