import React from "react";
import useGuard from "./useGuard";
import Guard from "./guards.types";

export default function ProtectedUrl({
  guards = [],
  children
}: {
  guards: Guard[];
  children: React.JSX.Element;
}) {
  let resultElement = children;
  resultElement = useGuard(guards, children);
  return resultElement;
}
