import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFindingModel from "./finding.model";
import {
  AffectedObject,
  IFinding,
  FindingDetailsUI,
  FindingHistory,
  FindingHistoryUI,
  FindingSummaryUI,
  FINDING_EVENTS,
  FINDING_SEVERITY,
  IInspector
} from "./finding.types";
import Breadcrumb from "../designSystem/breadcrumb/breadcrumb";
import {
  URL_INSPECTORS,
  URL_ORGANIZATIONS,
  URL_SCAN,
  URL_SCAN_MSP,
  URL_USERS,
  URL_USERS_MSP,
  URL_INSPECTORS_MSP
} from "../constants/appUrls";
import Icon from "../designSystem/icon/icon";
import { SCAN, WARNING_CIRCLE } from "../constants/images";
import formatDate from "../utils/formatDate";
import OrganizationsContext from "../contexts/organizations.context";
import { buildUrl } from "../utils/string.utils";
import SnackbarContext from "../contexts/snackbar.context";
import { MESSAGE } from "../designSystem/snackbar/snackbar.types";
import { useAppSelector } from "../redux/hooks";
import useInspectorManagement from "../scan/components/inspector-managment/useInspectorManagment";

function inspectorMapper(inspectorToMap: IInspector) {
  const {
    metadata: { inspectorId },
    inspectorName,
    impact,
    findingName
  } = inspectorToMap;
  return {
    findingName,
    impact,
    inspectorID: inspectorId,
    inspectorName
  };
}

function groupByFrameworkName(framework: any) {
  return framework.reduce((acc: any, curr: any) => {
    const { name, version } = curr;
    const key = `${name} ${version}`;
    const { controlDescription } = curr;
    if (controlDescription?.trim().length > 0) {
      acc[key] = acc[key] || {
        name,
        version,
        data: []
      };
      acc[key].data.push(curr);
    }
    return acc;
  }, {});
}

export default function useFindingViewModel() {
  const { getFinding, getInspector, getInspectorCurrentStatus } =
    useFindingModel();
  const [summary, setSummary] = useState<FindingSummaryUI>({
    dates: {
      firstSeen: "",
      lastSeen: ""
    },
    history: [],
    severity: FINDING_SEVERITY.Informational
  });
  const [details, setDetails] = useState<FindingDetailsUI>(
    {} as FindingDetailsUI
  );

  const { onPauseInspector, onResumeInspector } = useInspectorManagement();
  const [affectedObjects, setAffectedObjects] = useState<AffectedObject[]>([]);
  const [isLoadingFinding, setIsLoadingFinding] = useState(true);
  const loadingRef = useRef(false);
  const [inspector, setInspector] = useState<IInspector | null>(null);
  const [isInspectorPaused, setIsInspectorPaused] = useState<boolean>(false);

  const { organizationName, organizationId } = useAppSelector(
    (state: any) => state.session
  );

  const { organizationsList, loading, dataLoaded } =
    useContext(OrganizationsContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { orgId, scanId, findingId } = useParams();
  const navigate = useNavigate();

  const icon = useMemo(() => <Icon image={SCAN} alt="Finding" />, []);

  const breadcrumb = useMemo(() => {
    const scanUrl = orgId ? buildUrl(URL_SCAN_MSP, orgId) : URL_SCAN;
    const items = [
      {
        content: "Scan",
        link: scanUrl
      },
      {
        content: `${details?.findingName}`,
        menuItems: [
          {
            id: scanUrl,
            value: "Scan"
          },
          {
            id: orgId ? buildUrl(URL_USERS_MSP, orgId) : URL_USERS,
            value: "Users"
          },

          {
            id: orgId ? buildUrl(URL_INSPECTORS_MSP, orgId) : URL_INSPECTORS,
            value: "Inspectors"
          }
        ],
        isLoadingItem: isLoadingFinding
      }
    ];
    return (
      <Breadcrumb
        items={
          orgId
            ? [
                {
                  content: "Organizations",
                  link: URL_ORGANIZATIONS
                },
                {
                  content: `${organizationsList[orgId]?.name}`
                },
                ...items
              ]
            : items
        }
      />
    );
  }, [details?.findingName, isLoadingFinding, orgId, organizationsList]);

  const orgName = useMemo(
    () => (orgId ? organizationsList[orgId!]?.name : organizationName),
    [orgId, organizationName, organizationsList]
  );

  const updateHistoryDate = useCallback(
    (history: FindingHistory[]) =>
      history.map((item) => ({
        status: item.status,
        affectedObjects: item.affectedObjects ? item.affectedObjects : [],
        date: formatDate(item.date),
        active: false
      })),
    []
  );

  const getHistory = useCallback(
    (finding: IFinding) => {
      const currentStatus: FindingHistoryUI = {
        status: finding.status,
        date: formatDate(finding.summary.lastSeen),
        affectedObjects: finding.affectedObjects || [],
        active: true
      };
      const history: FindingHistoryUI[] = [currentStatus];
      return finding.history
        ? history.concat(updateHistoryDate(finding.history))
        : history;
    },
    [updateHistoryDate]
  );

  const loadAffectedObjectsFromHistory = useCallback(
    (historyIndex: number) => {
      setAffectedObjects(summary.history[historyIndex].affectedObjects);
      setDetails((prevState: FindingDetailsUI) => ({
        ...prevState,
        status: summary.history[historyIndex].status
      }));

      const newHistory = summary.history.map((item, index) => ({
        ...item,
        active: index === historyIndex
      }));

      setSummary((prevState: FindingSummaryUI) => ({
        ...prevState,
        history: newHistory
      }));
    },
    [summary.history]
  );

  const onStatusSelected = useCallback(
    (index: number) => {
      loadAffectedObjectsFromHistory(index);
      const statusEvent = new CustomEvent(FINDING_EVENTS.statusSelected);
      window.dispatchEvent(statusEvent);
    },
    [loadAffectedObjectsFromHistory]
  );
  const inspectorCurrentStatus = useCallback(
    async (currentOrgId: string, inspectorId: string) => {
      const isPaused = await getInspectorCurrentStatus(
        currentOrgId,
        inspectorId
      );
      setIsInspectorPaused(isPaused);
    },
    [getInspectorCurrentStatus]
  );

  const loadFinding = useCallback(async () => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    const findingResponse: IFinding | null = await getFinding(
      scanId!,
      findingId!
    );
    const inspectorResponse: IInspector | null = await getInspector(
      findingResponse?.inspectorID || ""
    );
    if (findingResponse && inspectorResponse) {
      await inspectorCurrentStatus(
        orgId || organizationId,
        inspectorResponse.metadata.inspectorId
      );
      setInspector(inspectorResponse);
      setDetails({
        findingName: findingResponse.findingName,
        status: findingResponse.status,
        affectedObjects: findingResponse.affectedObjects || [],
        description: inspectorResponse?.description,
        remediation: inspectorResponse?.remediation,
        references: inspectorResponse?.references,
        frameworks: groupByFrameworkName(inspectorResponse?.frameworks)
      });
      setSummary({
        severity: findingResponse.impact,
        history: getHistory(findingResponse),
        dates: {
          firstSeen: formatDate(findingResponse.summary.firstSeen),
          lastSeen: formatDate(findingResponse.summary.lastSeen)
        }
      });

      setAffectedObjects(findingResponse.affectedObjects || []);
    }
    loadingRef.current = false;

    setIsLoadingFinding(false);
  }, [
    findingId,
    getFinding,
    getHistory,
    getInspector,
    scanId,
    inspectorCurrentStatus,
    orgId,
    organizationId
  ]);

  useEffect(() => {
    loadFinding();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orgId && dataLoaded && !organizationsList[orgId!]) {
      showSnackbar({
        text: `Unexpected error`,
        type: MESSAGE.error,
        icon: WARNING_CIRCLE
      });
      navigate(URL_ORGANIZATIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  function onPauseInspectorFromFinding(inspectorToManage: IInspector) {
    onPauseInspector(inspectorMapper(inspectorToManage), (response) => {
      const { isPaused } = response;
      setIsInspectorPaused(isPaused);
    });
  }

  function onResumeInspectorFromFinding(inspectorToManage: IInspector) {
    onResumeInspector(inspectorMapper(inspectorToManage), (response) => {
      const { isPaused } = response;
      setIsInspectorPaused(isPaused);
    });
  }

  return {
    affectedObjects,
    details,
    summary,
    loading,
    isLoadingFinding,
    breadcrumb,
    icon,
    orgId,
    orgName,
    inspector,
    onPauseInspectorFromFinding,
    onResumeInspectorFromFinding,
    onStatusSelected,
    isInspectorPaused
  };
}
