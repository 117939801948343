import { FREQUENCY_ENUM, IscanFrecuencies } from "./scans-frequency.types";

export const SCAN_FREQUENCIES_CONSTANTS = {
  // scan frequency
  "scan scheduling": "Scan Scheduling",
  weekly: "Weekly",
  "twice a month": "Twice a Month",
  monthly: "Monthly",
  "scan schedule": "Scan Schedule",
  "last scan": "Last Scan",
  "next scan scheduled": "Next Scan Scheduled",
  "modify frequency": "Modify Frequency",
  "change scan frequency": "Change Scan Frequency",
  "current scan schedule is": "Current scan schedule is",
  "new scan schedule will be": "New scan schedule will be",
  "next scan will run on": "Next scan will run on",
  actions: "Actions",
  organization: "Organization",
  request: "Request",
  settings: "Settings",
  "request a scan": "Request a scan",
  "view your settings to request an scan":
    "View your Settings to request a scan"
};

export const SCAN_FREQUENCIES: IscanFrecuencies = [
  {
    label: SCAN_FREQUENCIES_CONSTANTS["monthly"],
    key: FREQUENCY_ENUM.MONTHLY
  },
  {
    label: SCAN_FREQUENCIES_CONSTANTS["twice a month"],
    key: FREQUENCY_ENUM.TWICE_A_MONTH
  },

  {
    label: SCAN_FREQUENCIES_CONSTANTS.weekly,
    key: FREQUENCY_ENUM.WEEKLY
  }
];
