import React, { createContext, useMemo } from "react";
import "./finding.scss";
import Header from "../designSystem/header/header";
import FindingSummary from "./components/findingSummary/findingSummary";
import FindingDetails from "./components/findingDetails/FindingDetails";
import useFindingViewModel from "./finding.view.model";
import { LOADING } from "../constants/images";
import { IInspector } from "./finding.types";

export interface IInspectorsFindingDetailsContext {
  onPauseInspectorFromFinding?: (inspector: IInspector) => void;
  onResumeInspectorFromFinding?: (inspector: IInspector) => void;
}

export const InspectorsTableContext =
  createContext<IInspectorsFindingDetailsContext>(
    {} as IInspectorsFindingDetailsContext
  );

export default function Finding() {
  const {
    details,
    summary,
    loading,
    isLoadingFinding,
    affectedObjects,
    breadcrumb,
    icon,
    orgId,
    orgName,
    onStatusSelected,
    inspector,
    onPauseInspectorFromFinding,
    onResumeInspectorFromFinding,
    isInspectorPaused
  } = useFindingViewModel();

  const contextValues: IInspectorsFindingDetailsContext = useMemo(
    () => ({
      onPauseInspectorFromFinding,
      onResumeInspectorFromFinding
    }),
    [onPauseInspectorFromFinding, onResumeInspectorFromFinding]
  );

  return (
    <div className="finding">
      <Header icon={icon} title={breadcrumb} isLoading={!!orgId && loading} />
      {isLoadingFinding ? (
        <div className="finding__loading">
          <img src={LOADING} alt="" />
        </div>
      ) : (
        <div className="finding__content">
          <FindingSummary
            summary={summary}
            totalAffectedObjects={affectedObjects.length}
            onStatusSelected={onStatusSelected}
            loadingOrganizations={loading}
            orgName={orgName}
          />
          <InspectorsTableContext.Provider value={contextValues}>
            <FindingDetails
              details={details}
              affectedObjects={affectedObjects}
              inspector={inspector}
              isPaused={isInspectorPaused}
            />
          </InspectorsTableContext.Provider>
        </div>
      )}
    </div>
  );
}
