import React from "react";
import "./header.scss";
import SkeletonLoader from "../skeleton/skeleton-loader";
import SKELETON_VARIANT from "../skeleton/skeleton.constants";

type IProps = {
  icon?: React.JSX.Element;
  subTitle?: string;
  title?: string | React.JSX.Element;
  isLoading?: boolean;
  isLoadingSubtitle?: boolean;
  children?: React.JSX.Element | boolean;
};

export default function Header({
  icon,
  subTitle,
  title,
  isLoading = false,
  isLoadingSubtitle = false,
  children
}: IProps) {
  return (
    <header className="headerContainer">
      <div className="headerContainer__icon">{icon}</div>
      <SkeletonLoader
        loadingVar={isLoading}
        variant={SKELETON_VARIANT.title}
        indent
      >
        <>
          <section className="headerContainer__text">
            {title && typeof title === "string" ? <h3>{title}</h3> : title}
            {(subTitle || isLoadingSubtitle) && (
              <SkeletonLoader
                loadingVar={isLoadingSubtitle}
                variant={SKELETON_VARIANT.shortText}
                indent
              >
                <h5 className="headerContainer__text__subTitle">{subTitle}</h5>
              </SkeletonLoader>
            )}
          </section>

          {children || null}
        </>
      </SkeletonLoader>
    </header>
  );
}
