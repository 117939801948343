import React from "react";
import { TableColumnProps } from "../../designSystem/table/tableTypes";
import SKELETON_VARIANT from "../../designSystem/skeleton/skeleton.constants";
import formatDate from "../../utils/formatDate";
import Chip from "../../designSystem/chip/chip";
import { SHAPES } from "../../designSystem/chip/chip.constants";
import Tooltip from "../../designSystem/tooltip/tooltip";
import { buildUrl } from "../../utils/string.utils";
import { URL_SCAN, URL_SCAN_MSP } from "../../constants/appUrls";
import "../organizations-list.scss";
import OrganizationListActionMenu from "./organization-list-action-menu";

export default class OrganizationsListTableFactory {
  public static onClickCell = (rowId: string, orgId: string) => {
    const sameOrg = rowId === orgId;
    const params = [];
    !sameOrg && params.push(rowId);
    return buildUrl(sameOrg ? URL_SCAN : URL_SCAN_MSP, ...params);
  };

  public static buildCell(
    value: any,
    { original: { id } }: any,
    organizationId: string
  ) {
    const url = this.onClickCell(id, organizationId);
    return (
      <a
        onClick={(e) => e.preventDefault()}
        target="_blank"
        href={url}
        rel="noreferrer"
        className="no-decoration"
      >
        {value}
      </a>
    );
  }

  public static getColumns(organizationId: string): TableColumnProps[] {
    return [
      {
        accessorKey: "id",
        enableGlobalFilter: false,
        hide: true
      },
      {
        accessorKey: "description",
        skeleton: SKELETON_VARIANT.text,
        filterFn: "equalsString",
        header: "Name",
        sortingFn: "text",
        enableGlobalFilter: true,
        sortDescFirst: false,
        cell: (data) =>
          this.buildCell(data.getValue(), data.row, organizationId)
      },
      {
        accessorKey: "lastScan.summary.findings",
        skeleton: SKELETON_VARIANT.shortText,
        enableSorting: true,
        header: "Total Findings",
        sortingFn: "text",
        cell: (data) =>
          this.buildCell(data.getValue(), data.row, organizationId)
      },
      {
        accessorKey: "lastScan.summary.severity.critical",
        skeleton: SKELETON_VARIANT.shortText,
        header: "Critical",
        sortingFn: "text",
        cell: (data) => (
          <a
            onClick={(e) => e.preventDefault()}
            target="_blank"
            href={this.onClickCell(data.row.original.id, organizationId)}
            rel="noreferrer"
            className="no-decoration has-tooltip"
          >
            <div
              className={`organizations-list__table__severity${
                !data.getValue() ? " opaque" : ""
              }`}
            >
              {data.getValue()}
              <Chip shape={SHAPES.RECTANGLE} status="critical" />
            </div>
            <Tooltip text="Severity critical" subtext={data.getValue()} />
          </a>
        )
      },
      {
        accessorKey: "lastScan.summary.severity.high",
        skeleton: SKELETON_VARIANT.shortText,
        header: "High",
        sortingFn: "text",
        cell: (data) => (
          <a
            onClick={(e) => e.preventDefault()}
            target="_blank"
            href={this.onClickCell(data.row.original.id, organizationId)}
            rel="noreferrer"
            className="no-decoration has-tooltip"
          >
            <div
              className={`has-tooltip organizations-list__table__severity${
                !data.getValue() ? " opaque" : ""
              }`}
            >
              {data.getValue()}
              <Chip shape={SHAPES.RECTANGLE} status="high" />
            </div>
            <Tooltip text="Severity high" subtext={data.getValue()} />
          </a>
        )
      },
      {
        accessorKey: "lastScan.summary.severity.medium",
        skeleton: SKELETON_VARIANT.shortText,
        header: "Medium",
        sortingFn: "text",
        cell: (data) => (
          <a
            onClick={(e) => e.preventDefault()}
            target="_blank"
            href={this.onClickCell(data.row.original.id, organizationId)}
            rel="noreferrer"
            className="no-decoration has-tooltip"
          >
            <div
              className={`has-tooltip organizations-list__table__severity${
                !data.getValue() ? " opaque" : ""
              }`}
            >
              {data.getValue()}
              <Chip shape={SHAPES.RECTANGLE} status="medium" />
            </div>
            <Tooltip text="Severity medium" subtext={data.getValue()} />
          </a>
        )
      },
      {
        accessorKey: "lastScan.summary.severity.low",
        skeleton: SKELETON_VARIANT.shortText,
        header: "Low",
        sortingFn: "text",
        cell: (data) => (
          <a
            onClick={(e) => e.preventDefault()}
            target="_blank"
            href={this.onClickCell(data.row.original.id, organizationId)}
            rel="noreferrer"
            className="no-decoration has-tooltip"
          >
            <div
              className={`has-tooltip organizations-list__table__severity${
                !data.getValue() ? " opaque" : ""
              }`}
            >
              {data.getValue()}
              <Chip shape={SHAPES.RECTANGLE} status="low" />
            </div>
            <Tooltip text="Severity low" subtext={data.getValue()} />
          </a>
        )
      },
      {
        accessorKey: "lastScan.summary.severity.informational",
        skeleton: SKELETON_VARIANT.shortText,
        header: "Informational",
        sortingFn: "text",
        cell: (data) => (
          <a
            onClick={(e) => e.preventDefault()}
            target="_blank"
            href={this.onClickCell(data.row.original.id, organizationId)}
            rel="noreferrer"
            className="no-decoration has-tooltip"
          >
            <div
              className={`has-tooltip organizations-list__table__severity${
                !data.getValue() ? " opaque" : ""
              }`}
            >
              {data.getValue()}
              <Chip shape={SHAPES.RECTANGLE} status="informational" />
            </div>
            <Tooltip text="Severity informational" subtext={data.getValue()} />
          </a>
        )
      },
      {
        accessorKey: "lastScan.summary.status.new",
        skeleton: SKELETON_VARIANT.chip,
        header: "New",
        sortingFn: "text",
        cell: (data) => (
          <a
            onClick={(e) => e.preventDefault()}
            target="_blank"
            href={this.onClickCell(data.row.original.id, organizationId)}
            rel="noreferrer"
            className="no-decoration has-tooltip"
          >
            <Chip
              label={data.getValue()}
              status="new"
              disabled={!data.getValue()}
            />
            <Tooltip text="Status new" subtext={data.getValue()} />
          </a>
        )
      },
      {
        accessorKey: "lastScan.summary.status.unchanged",
        skeleton: SKELETON_VARIANT.chip,
        header: "Unchanged",
        sortingFn: "text",
        cell: (data) => (
          <a
            onClick={(e) => e.preventDefault()}
            target="_blank"
            href={this.onClickCell(data.row.original.id, organizationId)}
            rel="noreferrer"
            className="no-decoration has-tooltip"
          >
            <Chip
              label={data.getValue()}
              status="unchanged"
              disabled={!data.getValue()}
            />
            <Tooltip text="Status unchanged" subtext={data.getValue()} />
          </a>
        )
      },
      {
        accessorKey: "lastScan.summary.status.modified",
        skeleton: SKELETON_VARIANT.chip,
        header: "Modified",
        sortingFn: "text",
        cell: (data) => (
          <a
            onClick={(e) => e.preventDefault()}
            target="_blank"
            href={this.onClickCell(data.row.original.id, organizationId)}
            rel="noreferrer"
            className="no-decoration has-tooltip"
          >
            <Chip
              label={data.getValue()}
              status="modified"
              disabled={!data.getValue()}
            />
            <Tooltip text="Status modified" subtext={data.getValue()} />
          </a>
        )
      },
      {
        accessorKey: "lastScan.summary.status.remediated",
        skeleton: SKELETON_VARIANT.chip,
        header: "Remediated",
        sortingFn: "text",
        cell: (data) => (
          <a
            onClick={(e) => e.preventDefault()}
            target="_blank"
            href={this.onClickCell(data.row.original.id, organizationId)}
            rel="noreferrer"
            className="no-decoration has-tooltip"
          >
            <Chip
              label={data.getValue()}
              status="remediated"
              disabled={!data.getValue()}
            />
            <Tooltip text="Status remediated" subtext={data.getValue()} />
          </a>
        )
      },
      {
        accessorKey: "lastScan.createdAt",
        skeleton: SKELETON_VARIANT.text,
        enableSorting: true,
        header: "Last scan",
        sortingFn: "text",
        cell: (data) =>
          this.buildCell(
            data.getValue() ? formatDate(data.getValue()) : "",
            data.row,
            organizationId
          )
      },
      {
        accessorKey: "actions",
        skeleton: SKELETON_VARIANT.action,
        header: "",
        cell: ({ row }) => (
          <OrganizationListActionMenu
            row={row?.original}
            orgId={organizationId}
          />
        )
      }
    ];
  }
}
