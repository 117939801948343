import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./input.scss";
import SkeletonLoader from "../skeleton/skeleton-loader";
import SKELETON_VARIANT from "../skeleton/skeleton.constants";
import STATUS from "../status";

const REGEX = /^[\d]{4}-[\d]{2}-[\d]{2}$/;

type IProps = {
  label?: string;
  icon?: string;
  alt?: string;
  value?: string | number;
  onChange?: any;
  onKeyDown?: any;
  min?: string | number;
  max?: string | number;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  name?: string;
  readOnly?: boolean;
  isLoading?: boolean;
  error?: string;
  touched?: boolean;
  field?: any;
  form?: any;
};

export default function Input(props: IProps) {
  const {
    label,
    icon,
    alt,
    value,
    onChange,
    onKeyDown,
    min,
    max,
    disabled,
    placeholder,
    type = "text",
    name,
    readOnly,
    isLoading = false,
    error = "",
    touched = false,
    field,
    form
  } = props;

  const inputContainerClassRoot = "inputContainer";

  const inputClassRoot = "inputContainer__input";

  const inputTextClassRoot = `${inputClassRoot}__text`;
  const [inputTextClassName, setInputTextClassName] =
    useState(inputTextClassRoot);

  const [dateInputText, setDateInputText] = useState<string>(`${value}`);
  const [dateError, setDateError] = useState<string>("");

  const hasError = useMemo(
    () => touched && (!!error || !!dateError),
    [dateError, error, touched]
  );

  const onChangeDateInput = useCallback(
    ({ target }: any) => {
      let string = target.value;
      if (string.length === 4 && dateInputText[4] !== "-") string += "-";
      if (string.length === 7 && dateInputText[7] !== "-") string += "-";
      setDateInputText(string);
    },
    [dateInputText]
  );

  const onBlurDateInput = useCallback(
    ({ target }: any) => {
      const date = target.value;
      const minDate = new Date(min || "");
      const maxDate = new Date(max || "");
      const newDate = new Date(date);
      const isError =
        !newDate.getTime() ||
        !REGEX.test(date) ||
        newDate > maxDate ||
        newDate < minDate;
      if (isError) setDateError("Invalid Date");
      else {
        setDateError("");
        onChange({ target });
      }
    },
    [max, min, onChange]
  );

  const onKeyDownDateInput = useCallback(
    (e: any) => e.key === "Enter" && e.target.blur(),
    []
  );

  useEffect(() => {
    setDateInputText(`${value}`);
  }, [value]);

  useEffect(() => {
    setInputTextClassName(
      hasError
        ? `${inputTextClassRoot} ${inputTextClassRoot}--${STATUS.error}`
        : inputTextClassRoot
    );
  }, [inputTextClassRoot, hasError]);

  // TODO: instead of inputClassRoot, a unique id has to be built
  const id = label ? inputClassRoot : undefined;

  return (
    <div className={inputContainerClassRoot}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <SkeletonLoader loadingVar={isLoading} variant={SKELETON_VARIANT.input}>
        {readOnly ? (
          <p className="inputContainer__readonly-value">{value}</p>
        ) : (
          <div className={inputClassRoot}>
            {icon && (
              <div className="inputContainer__input__icon">
                <img src={icon} alt={alt} />
              </div>
            )}

            {type === "date" && (
              <input
                className={`${inputTextClassName} date-input`}
                id={id}
                type="text"
                value={dateInputText}
                name={name || field?.name}
                disabled={disabled}
                onChange={(e) => onChangeDateInput(e)}
                onBlur={(e) => onBlurDateInput(e)}
                onKeyDown={(e) => onKeyDownDateInput(e)}
                maxLength={10}
              />
            )}
            <input
              className={inputTextClassName}
              id={id}
              type={type}
              value={value}
              placeholder={placeholder}
              name={type === "date" ? undefined : name || field?.name}
              disabled={disabled}
              onChange={(e) => onChange(e)}
              onBlur={() => !!form && form.setFieldTouched(field?.name, true)}
              onMouseDown={(e) => type === "date" && e.preventDefault()}
              onKeyDown={onKeyDown}
              min={type !== "date" && min ? min : "no-min"}
              max={max || "no-max"}
            />
          </div>
        )}
      </SkeletonLoader>
      {hasError && <p className={`${inputClassRoot}__error`}>{error}</p>}
    </div>
  );
}
