import React from "react";
import "./App.scss";
import LogRocket from "logrocket";
import Auth0ProviderWithHistory from "./login/auth0ProviderWithHistory";
import { useAppSelector } from "./redux/hooks";
import CommonProvider from "./contexts/common.provider";
import Modal from "./designSystem/modal/modal";
import InspectRouting from "./guards/inspect.routes";
import SnackbarList from "./designSystem/snackbar/snackbar";

export default function App() {
  const email = useAppSelector((state: any) => state.session.email);

  if (email.length) {
    LogRocket.identify(email);
  }

  return (
    <Auth0ProviderWithHistory>
      <CommonProvider>
        <SnackbarList />
        <Modal />
        <InspectRouting />
      </CommonProvider>
    </Auth0ProviderWithHistory>
  );
}
