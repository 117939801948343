import React, { useEffect, useRef, useState } from "react";
import "./textArea.scss";
import STATUS from "../status";
import { WIDTH } from "../../constants/dimensions";

type TextAreaProps = {
  disabled?: boolean;
  focus?: boolean;
  height?: string;
  isValid?: boolean;
  label?: boolean;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  value: string;
  width?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
};
export default function TextArea(props: TextAreaProps) {
  const {
    height,
    width,
    rows,
    label,
    isValid,
    focus,
    placeholder,
    value,
    required,
    disabled,
    onChange,
    onKeyDown
  } = props;

  const { DEFAULT, EXACT } = WIDTH;

  const widthModifier =
    !width || width === EXACT ? `width${DEFAULT}` : `width${width}`;

  const heightModifier = height ? `height${height}` : "";

  const containerClassRoot = "textAreaContainer";
  let containerClassName = `${containerClassRoot} ${containerClassRoot}--${widthModifier}`;

  containerClassName += ` ${containerClassRoot}--${heightModifier}`;

  const textClassRoot = "textAreaContainer__text";
  let textClassName = textClassRoot;

  textClassName += ` ${textClassRoot}--${widthModifier}`;
  textClassName += rows
    ? ""
    : heightModifier
    ? ` ${textClassRoot}--${heightModifier}`
    : ` ${textClassRoot}--heightDefault`;

  const id = label ? crypto.randomUUID() : undefined;

  const [textAreaClass, setTextAreaClass] = useState(textClassName);

  useEffect(() => {
    setTextAreaClass(
      isValid
        ? textClassName
        : `${textClassName} ${textClassRoot}--${STATUS.error}`
    );
  }, [isValid, textClassName]);

  const textRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!textRef.current) {
      return;
    }
    if (focus) {
      textRef.current.focus();
    } else {
      textRef.current.blur();
    }
  }, [focus]);

  return (
    <div className={containerClassName}>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        className={textAreaClass}
        id={id}
        ref={textRef}
        placeholder={placeholder}
        rows={rows}
        value={value}
        required={required}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}
