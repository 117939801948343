import React, { useContext } from "react";
import "./organizations-list.scss";
import Header from "../designSystem/header/header";
import Icon from "../designSystem/icon/icon";
import { ORGANIZATIONS, SEARCH } from "../constants/images";
import Table from "../designSystem/table/table";
import ActionBar from "../designSystem/actionBar/actionBar";
import Input from "../designSystem/input/input";
import OrganizationsContext from "../contexts/organizations.context";
import useOrganizationsListViewModel from "./organizations-list.view.model";

export default function OrganizationList() {
  const icon = <Icon image={ORGANIZATIONS} alt="Findings" />;
  const { loading, organizationsList } = useContext(OrganizationsContext);

  const {
    columns,
    searchText,
    columnFilters,
    organizationListPersistedSorting,
    handleSearchChange,
    onClickOrganization,
    setOrganizationListPersistedSorting
  } = useOrganizationsListViewModel();

  return (
    <section className="organizations-list">
      <Header icon={icon} title="Organizations" />
      <div className="organizations-list__container">
        <div className="organizations-list__actions">
          <ActionBar>
            <Input
              alt="Search"
              icon={SEARCH}
              placeholder="Search"
              value={searchText}
              onChange={({ target: { value } }: any) =>
                handleSearchChange(value)
              }
            />
          </ActionBar>
        </div>
        <div className="organizations-list__table">
          <Table
            isLoading={loading}
            columnFilters={columnFilters}
            columns={columns}
            rows={Object.values(organizationsList)}
            globalFilter={searchText}
            onClickCell={onClickOrganization}
            persistedSorting={organizationListPersistedSorting}
            setPersistedSorting={setOrganizationListPersistedSorting}
          />
        </div>
      </div>
    </section>
  );
}
