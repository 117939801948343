import React, { useRef } from "react";
import "./tableHeader.scss";
import { flexRender, HeaderGroup, RowData } from "@tanstack/react-table";

type TableHeaderProps = {
  headerGroups: HeaderGroup<RowData>[];
};
export default function TableHeader({ headerGroups }: TableHeaderProps) {
  const toggleSortingRef = useRef(true);
  const columnSorted = useRef("");

  const onClickHeader = (e: any, header: any) => {
    if (header.column.columnDef.enableSorting) {
      const toggleSorting = header.column.getToggleSortingHandler();

      if (toggleSorting) {
        toggleSorting(e);
      } else {
        console.error(
          `Sorting handler not enabled for column ${header.column.id}`
        );
        header.column.toggleSorting(toggleSortingRef.current);
      }

      columnSorted.current = header.column.id;
      toggleSortingRef.current = !toggleSortingRef.current;
    }
  };

  return (
    <thead>
      {headerGroups.map((headerGroup: HeaderGroup<RowData>) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            let sortIconClassName = "tableHeader__sortIcon";
            let showSortIcon = false;
            if (header.column.id === columnSorted.current) {
              sortIconClassName += ` ${
                toggleSortingRef.current ? "" : "tableHeader__sortIcon--down"
              }`;
              showSortIcon = true;
            }
            return (
              <th
                className="tableHeader"
                key={header.id}
                onClick={(e) => onClickHeader(e, header)}
              >
                <div className="tableHeader__content">
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  {showSortIcon && <div className={sortIconClassName} />}
                </div>
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}
