import { useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { IState } from "../../constants/common.types";

export const useProfileGuard = () => {
  const currentUserId = useAppSelector((state: IState) => state.session.userId);
  const { userId } = useParams();
  return userId !== currentUserId;
};

export default useProfileGuard;
