import React from "react";
import Chip from "../../../designSystem/chip/chip";
import { SHAPES } from "../../../designSystem/chip/chip.constants";
import SKELETON_VARIANT from "../../../designSystem/skeleton/skeleton.constants";
import { TableColumnProps } from "../../../designSystem/table/tableTypes";
import Icon from "../../../designSystem/icon/icon";
import { RESUME } from "../../../constants/images";
import INSPECTORS_CONSTANTS from "../../inspector.constants";
import formatDate from "../../../utils/formatDate";
import { FINDING_SEVERITY } from "../../../finding/finding.types";
import Tooltip from "../../../designSystem/tooltip/tooltip";

function dateCell(props: any) {
  const dateValue = props.getValue();
  return dateValue ? (
    <span className="tableCell-date">{formatDate(dateValue)}</span>
  ) : (
    <span className="tableCell-date-not-available">
      {INSPECTORS_CONSTANTS["default date"]}
    </span>
  );
}
function severityCell(props: any) {
  const label = String(props.getValue() || "");
  return (
    <div className="scan__table__severity">
      <Chip shape={SHAPES.RECTANGLE} status={label.toLocaleLowerCase()} />
      {label}
    </div>
  );
}

export function pausedInspectorActionCell() {
  return (
    <div className="pause-inspectors-eye-icon--paused">
      <Icon image={RESUME} />
    </div>
  );
}

function PausedInspectorsWrapper({ children, ...props }: any) {
  return (
    <td className="tableCell" key={props?.key} onClick={props?.onClick}>
      {props?.cell?.row && (
        <Tooltip
          top
          right
          text={INSPECTORS_CONSTANTS["click to resume inspector"]}
          additionalClassName="wrapped"
        />
      )}
      {children}
    </td>
  );
}

const InspectorsColumns: TableColumnProps[] = [
  {
    accessorKey: "id",
    enableGlobalFilter: false,
    hide: true
  },
  {
    accessorKey: "impact",
    skeleton: SKELETON_VARIANT.shortText,
    enableColumnFilter: true,
    enableSorting: true,
    filterFn: "equalsString",
    header: INSPECTORS_CONSTANTS["severity"],
    sortDescFirst: true,
    sortingFn: (firstRow: any, secondRow: any, columnId: any): number => {
      const firstSeverity = parseInt(
        FINDING_SEVERITY[firstRow.getValue(columnId)],
        10
      );
      const secondSeverity = parseInt(
        FINDING_SEVERITY[secondRow.getValue(columnId)],
        10
      );
      return firstSeverity - secondSeverity;
    },
    cell: severityCell,
    CellWrapper: PausedInspectorsWrapper
  },
  {
    accessorKey: "findingName",
    skeleton: SKELETON_VARIANT.text,
    enableSorting: true,
    header: INSPECTORS_CONSTANTS["name"],
    sortDescFirst: false,
    sortingFn: "text"
  },
  {
    accessorKey: "activity",
    skeleton: SKELETON_VARIANT.text,
    enableSorting: true,
    header: INSPECTORS_CONSTANTS["notes"],
    sortingFn: "basic",
    cell: (props: any) => {
      const activityArray = props.getValue();
      const { note = "" } = activityArray[activityArray.length - 1];
      return <p className="notes-cell">{note}</p>;
    }
  },
  {
    accessorKey: "pausedAt",
    skeleton: SKELETON_VARIANT.shortText,
    enableSorting: true,
    header: INSPECTORS_CONSTANTS["paused"],
    sortingFn: "basic",
    cell: dateCell,
    className: "date-cell"
  },
  {
    accessorKey: "startedAt",
    skeleton: SKELETON_VARIANT.shortText,
    enableSorting: true,
    header: INSPECTORS_CONSTANTS["resumes"],
    sortingFn: "basic",
    cell: dateCell,
    className: "date-cell"
  },
  {
    accessorKey: "isActive",
    enableSorting: false,
    skeleton: SKELETON_VARIANT.avatar,
    header: "",
    cell: pausedInspectorActionCell
  }
];

export default InspectorsColumns;
