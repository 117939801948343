import React from "react";
import Onboarding from "../onboarding";
import OnboardingFooter from "../onboardingFooter";
import OnboardingContent from "../onboardingContent";
import STATUS from "../../designSystem/status";
import OnboardingStageResult from "../onboardingStageResult";

export default function ActivationPending() {
  return (
    <Onboarding>
      <OnboardingContent detailsCentered footer={<OnboardingFooter />}>
        <OnboardingStageResult
          step="Step 2 pending"
          title="Activate account"
          status={STATUS.warning}
        >
          <p>
            Please continue the activation by following the instructions sent to
            your email
          </p>
        </OnboardingStageResult>
      </OnboardingContent>
    </Onboarding>
  );
}
