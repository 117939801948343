import React from "react";
import useAuth0Authentication from "../authentication/useAuth0Authentication";
import Button from "../designSystem/button/button";
import STATUS from "../designSystem/status";
import Onboarding from "../onboarding/onboarding";
import OnboardingContent from "../onboarding/onboardingContent";
import OnboardingStageResult from "../onboarding/onboardingStageResult";

function UnauthorizedDetails() {
  const { login } = useAuth0Authentication();

  const onClickLogIn = () => login();

  return (
    <OnboardingStageResult title="Invalid session" status={STATUS.warning}>
      <p>A new login is required</p>
      <br />
      <Button onClick={onClickLogIn}>Log in</Button>
    </OnboardingStageResult>
  );
}

export default function Unauthorized() {
  return (
    <Onboarding>
      <OnboardingContent detailsCentered>
        <UnauthorizedDetails />
      </OnboardingContent>
    </Onboarding>
  );
}
