import { ROLES } from "../constants/roles";
import { useAppSelector } from "../redux/hooks";
import PERMISSIONS_LIST, { permissions } from "./permissions";

export default function usePermissions() {
  const role = useAppSelector(({ session }) => session.role) as ROLES;
  const organizationType = useAppSelector(
    ({ session }) => session.organizationType
  );
  const canI = (action: PERMISSIONS_LIST) => {
    const permission = permissions[action];
    return (
      permission.roles.includes(ROLES[role]) &&
      permission.orgTypes.includes(organizationType)
    );
  };

  return canI;
}
