import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnFiltersState } from "@tanstack/react-table";
import OrganizationsListTableFactory from "./components/organizations-list.tableFactory";
import { URL_SCAN, URL_SCAN_MSP } from "../constants/appUrls";
import { buildUrl } from "../utils/string.utils";
import OrganizationsContext from "../contexts/organizations.context";
import { MESSAGE } from "../designSystem/snackbar/snackbar.types";
import { INFORMATION } from "../constants/images";
import SnackbarContext from "../contexts/snackbar.context";
import { useAppSelector } from "../redux/hooks";

export default function useOrganizationsListViewModel() {
  const [searchText, setSearchText] = useState("");
  const [columnFilters] = useState<ColumnFiltersState>([]);

  const { organizationId } = useAppSelector((state: any) => state.session);

  const {
    organizationsList,
    organizationListPersistedSorting,
    setOrganizationListPersistedSorting
  } = useContext(OrganizationsContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const navigate = useNavigate();

  const columns = useMemo(
    () => OrganizationsListTableFactory.getColumns(organizationId),
    [organizationId]
  );

  const onClickOrganization = useCallback(
    (rowIndex: string) => {
      const sameOrg = rowIndex === organizationId;
      const params = sameOrg ? [] : [organizationsList[rowIndex].id];
      return organizationsList[rowIndex]?.lastScan?.id
        ? navigate(buildUrl(sameOrg ? URL_SCAN : URL_SCAN_MSP, ...params))
        : showSnackbar({
            text: `No inspection data is available for this client organization`,
            type: MESSAGE.info,
            icon: INFORMATION
          });
    },
    [navigate, organizationId, organizationsList, showSnackbar]
  );

  const handleSearchChange = (text: string) => setSearchText(text);

  return {
    columns,
    searchText,
    columnFilters,
    organizationListPersistedSorting,
    handleSearchChange,
    onClickOrganization,
    setOrganizationListPersistedSorting
  };
}
