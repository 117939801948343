import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import useAuth0Authentication from "../authentication/useAuth0Authentication";
import OnboardingContent from "../onboarding/onboardingContent";
import OnboardingStageResult from "../onboarding/onboardingStageResult";
import STATUS from "../designSystem/status";
import { STORAGE_KEY } from "../redux/store";
import Onboarding from "../onboarding/onboarding";
import { useAppSelector } from "../redux/hooks";
import useMSAuthentication from "../authentication/useMSAuthentication";
import SERVICE_PROVIDERS from "../login/serviceProviders";

export default function Logout() {
  const { AUTH0, AZURE, NONE } = SERVICE_PROVIDERS;
  const { logOut: logOutAuth0 } = useAuth0Authentication();
  const { logOut: logOutMs } = useMSAuthentication();
  const location = useLocation();
  const redirectURL = location.state ? location.state.redirectURL : null;
  const serviceProvider = useAppSelector(
    (state: any) => state.session.serviceProvider
  );
  const logoutInProgress = useRef(false);

  useEffect(() => {
    localStorage.removeItem(STORAGE_KEY);
    const serializedState = localStorage.getItem(STORAGE_KEY);
    if (serializedState) {
      console.info("State could not be removed from storage!");
    }
    if (logoutInProgress.current) {
      return;
    }
    logoutInProgress.current = true;
    if (serviceProvider === AUTH0) {
      logOutAuth0(redirectURL);
    }
    if (serviceProvider === AZURE) {
      logOutMs();
    }
    if (serviceProvider === NONE) {
      console.log(
        `Logout requested with unknown service provider ${serviceProvider}`
      );
      logOutAuth0(redirectURL);
    }
  }, [
    AUTH0,
    AZURE,
    logOutAuth0,
    logOutMs,
    logoutInProgress,
    NONE,
    redirectURL,
    serviceProvider
  ]);

  return (
    <Onboarding>
      <OnboardingContent detailsCentered>
        <OnboardingStageResult title="Logging out" status={STATUS.inProgress} />
      </OnboardingContent>
    </Onboarding>
  );
}
