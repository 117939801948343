// eslint-disable-next-line no-shadow
export enum FREQUENCY_ENUM {
  WEEKLY = "weekly",
  TWICE_A_MONTH = "twiceAMonth",
  MONTHLY = "monthly"
}
export type IscanFrecuencies = {
  label: string;
  key: FREQUENCY_ENUM;
  selected?: boolean;
}[];
