import React from "react";
import "./login.scss";
import OnboardingStageResult from "../onboarding/onboardingStageResult";
import Onboarding from "../onboarding/onboarding";
import OnboardingContent from "../onboarding/onboardingContent";
import Button from "../designSystem/button/button";
import useLogin from "./useLogin";

export default function Login() {
  const { text, title, status, showErrorSection, handleLogout } = useLogin();
  const onClickLogout = () => handleLogout();

  return (
    <Onboarding>
      <OnboardingContent detailsCentered>
        <OnboardingStageResult title={title} status={status}>
          {text}
          {showErrorSection && (
            <div className="login">
              <p>
                Please <a href="mailto:inspect@soteria.io">contact us</a> to
                validate your subscription
              </p>
              <Button onClick={onClickLogout}>Log out</Button>
            </div>
          )}
        </OnboardingStageResult>
      </OnboardingContent>
    </Onboarding>
  );
}
