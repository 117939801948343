import { MSAL_APP_KEYS } from "./msalAppKeys";

export const graphScopes = ["https://graph.microsoft.com/.default"];

export const loginRequest = {
  scopes: [...graphScopes],
  prompt: "consent"
};

export const logoutRequest = {
  postLogoutRedirectUri: MSAL_APP_KEYS.CALLBACK_URL
};
