import React from "react";
import { TableColumnProps } from "../../designSystem/table/tableTypes";
import Chip from "../../designSystem/chip/chip";
import { SHAPES } from "../../designSystem/chip/chip.constants";
import SKELETON_VARIANT from "../../designSystem/skeleton/skeleton.constants";
import { FINDING_SEVERITY } from "../../finding/finding.types";

const findingsTableFactoryColumns: TableColumnProps[] = [
  {
    accessorKey: "id",
    enableGlobalFilter: false,
    hide: true
  },
  {
    accessorKey: "severity",
    skeleton: SKELETON_VARIANT.text,
    enableColumnFilter: true,
    enableSorting: true,
    filterFn: "equalsString",
    header: "Severity",
    sortDescFirst: true,
    sortingFn: (firstRow: any, secondRow: any, columnId: any): number => {
      const firstSeverity = parseInt(
        FINDING_SEVERITY[firstRow.getValue(columnId)],
        10
      );
      const secondSeverity = parseInt(
        FINDING_SEVERITY[secondRow.getValue(columnId)],
        10
      );

      return firstSeverity - secondSeverity;
    },
    cell: (props: any) => {
      const label = props.getValue();
      return (
        <div className="scan__table__severity">
          <Chip shape={SHAPES.RECTANGLE} status={label.toLowerCase()} />
          {label}
        </div>
      );
    }
  },
  {
    accessorKey: "name",
    skeleton: SKELETON_VARIANT.text,
    enableSorting: true,
    header: "Finding",
    sortDescFirst: false,
    sortingFn: "text"
  },
  {
    accessorKey: "affected",
    skeleton: SKELETON_VARIANT.shortText,
    enableSorting: true,
    header: "Affected",
    sortingFn: "basic"
  },
  {
    accessorKey: "firstSeen",
    skeleton: SKELETON_VARIANT.text,
    enableSorting: true,
    header: "First seen",
    sortingFn: "basic"
  },
  {
    accessorKey: "status",
    skeleton: SKELETON_VARIANT.chip,
    header: "Status",
    cell: (props: any) => {
      const label = props.getValue();
      return (
        <Chip label={label} shape={SHAPES.OVAL} status={label.toLowerCase()} />
      );
    }
  }
];

export default findingsTableFactoryColumns;
