// eslint-disable-next-line no-shadow
const enum ONBOARDING_STATUS {
  NEW = "onboarding_new", // handled internally by the UI to recognize when the onboarding has not started
  SUBSCRIPTION = "onboarding_subscription",
  ACTIVATION = "onboarding_activation",
  ACTIVATED = "onboarding_activated", // handled internally by the UI to differentiate when the activation was completed
  // within the activation workflow from when the activation workflow was started
  // with an onboarding already completed
  COMPLETED = "onboarding_completed",
  UNKNOWN = "onboarding_unknown" // used when there is an error on login before getting the stage.
  // E.g login with an account that requires admin approval and consent is not granted. Check INE-263
}

export default ONBOARDING_STATUS;
