import React, { ReactNode } from "react";
import "./onboarding.scss";
import Logo from "../logo/logo";

type OnboardingProps = {
  children?: ReactNode;
};

export default function Onboarding({ children }: OnboardingProps) {
  return (
    <div className="onboarding">
      <div className="onboarding__sidePanel">
        <Logo />
      </div>
      <div className="onboarding__separator" />
      <div className="onboarding__content">{children}</div>
    </div>
  );
}
