import React from "react";
import {
  SCAN_FREQUENCIES,
  SCAN_FREQUENCIES_CONSTANTS
} from "./scans-frequency.constants";
import "./scan-frequency.scss";

export default function ChangeScanFrequencyModalInfo({
  frequencyOption,
  orgName
}: {
  frequencyOption: string;
  orgName: string;
}) {
  const newScheduleOption = SCAN_FREQUENCIES.filter((current: any) => {
    const { key } = current;
    return frequencyOption === key && current.label;
  })[0];
  return (
    <div className="scan-frecuency__modal-info">
      <p>
        {SCAN_FREQUENCIES_CONSTANTS["organization"]} :{" "}
        <strong>{orgName}</strong>
      </p>
      <p>
        {SCAN_FREQUENCIES_CONSTANTS["current scan schedule is"]}:
        <strong> {SCAN_FREQUENCIES_CONSTANTS["weekly"]}</strong>
      </p>
      <p>
        {SCAN_FREQUENCIES_CONSTANTS["new scan schedule will be"]}:
        <strong> {newScheduleOption?.label}</strong>
      </p>
      <p>
        {SCAN_FREQUENCIES_CONSTANTS["next scan will run on"]}:{" "}
        <strong> 24/20/2024</strong>
      </p>
    </div>
  );
}
