import formatDate from "../utils/formatDate";
import { FindingUI, IFinding, IFindingsList } from "./finding.types";

export const findingMapper = (finding: IFinding): FindingUI => {
  const {
    id,
    impact,
    findingName,
    summary,
    status,
    affectedObjects,
    isPaused,
    scanId
  } = finding;
  return {
    id,
    severity: impact,
    name: findingName,
    affected: affectedObjects?.length || 0,
    firstSeen: formatDate(summary.firstSeen),
    status,
    isPaused,
    finding,
    scanId
  };
};

export const findingListMapper = (
  findings?: IFinding[],
  scanId?: string
): IFindingsList => {
  const result: IFindingsList = {};
  findings &&
    findings.forEach((finding: IFinding) => {
      result[finding.id] = findingMapper({ ...finding, scanId });
    });
  return result;
};
