export const HEIGHT = Object.freeze({
  DEFAULT: "Default",
  MAX: "Max"
});

export const WIDTH = Object.freeze({
  DEFAULT: "Default",
  EXACT: "Exact",
  MAX: "Max"
});
