import React from "react";
import "./subscriptionConfirmation.scss";
import Onboarding from "../onboarding";
import OnboardingContent from "../onboardingContent";
import OnboardingFooter from "../onboardingFooter";
import useUrlQuery from "../../query/urlQuery";
import useAuth0Authentication from "../../authentication/useAuth0Authentication";
import OnboardingStageResult from "../onboardingStageResult";
import STATUS from "../../designSystem/status";
import Button from "../../designSystem/button/button";

export default function SubscriptionConfirmation() {
  const { getParameter } = useUrlQuery();
  const email = getParameter("email");

  const { login } = useAuth0Authentication();

  const onClickLogin = () => login();

  return (
    <Onboarding>
      <OnboardingContent detailsCentered footer={<OnboardingFooter />}>
        <OnboardingStageResult
          title="Subscription confirmed"
          status={STATUS.success}
          step="Step 1 completed"
        >
          <h5>Check your email to proceed with the setup</h5>
          <p>
            An enrollment was sent to{" "}
            <span className="noDecoration">{email}</span>
          </p>
          <div className="subscriptionConfirmation">
            <Button onClick={onClickLogin}>Log in</Button>
          </div>
        </OnboardingStageResult>
      </OnboardingContent>
    </Onboarding>
  );
}
